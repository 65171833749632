import axios from 'axios';
import API from '../config/API';

export const venuesService = {
    getVenueDetails,
    getAllVenues,
    getPlaceById,
    getNearBySearch,
    addVenues,
    editVenues,
    deleteVenues,
    changeVenueStatus

}

const headersApplicationJson = {
    'Content-Type': 'application/json',
}
const headersMultipart = {
    "Content-type": "multipart/form-data",
}

function getAllVenues(params) {
    return axios.get(`${API.GET_VENUES}/${params}`);
}

function getPlaceById(params) {
    return axios.get(`${API.GET_PLACE_DETAIL}/${params}`);
}

function getNearBySearch(params) {
    return axios.get(`${API.GET_NEARBY_PLACES}/${params}`);
}

function getVenueDetails(params) {
    return axios.get(`${API.GET_VENUE_DETAILS}/${params}`);
}
function addVenues(params) {
    return axios.post(API.ADD_VENUES,params);
}
function editVenues(params) {
    return axios.post(API.EDIT_VENUES,params);
}
function changeVenueStatus(params) {
    return axios.post(API.CHANGE_VENUE_STATUS,params);
}
function deleteVenues(params) {
    // return axios.delete(API.DELETE_VENUES,params);
    return axios.delete(`${API.DELETE_VENUES}/${params}`);

}




