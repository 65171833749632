import { combineReducers } from 'redux';
import { login, user } from './users.reducer';
import {report } from './reports.reducer';
import {event } from './events.reducer';
import {venue } from './venues.reducer';
const rootReducer = combineReducers({

  login,
  user,
  report,
  event,
  venue,

});

export default rootReducer;
