import axios from 'axios';
import API from '../config/API';

export const eventsService = {
    getEventDetails,
    getAllEvents,
    addEvents,
}

const headersApplicationJson = {
    'Content-Type': 'application/json',
}
const headersMultipart = {
    "Content-type": "multipart/form-data",
}

function getAllEvents(params) {
    return axios.get(`${API.GET_EVENTS}/${params}`);
}

function getEventDetails(params) {
    return axios.get(`${API.GET_EVENT_DETAILS}/${params}`);
}

function addEvents(params) {
    return axios.post(API.ADD_EVENTS,params);
}




