import promise from 'bluebird';
import {usersConstants} from '../_constants';
import {eventsService, usersService,venuesService} from "../_services";
import {ResponseFilter} from "../config/response-handler";

export const venuesActions = {
    getAllVenues,
    getPlaceById,
    getNearBySearch,
    getVenueDetails,
    addVenues,
    editVenues,
    deleteVenues,
    // changeVenueStatus
};

export const venuesConstants = {

    GET_VENUES_REQUEST: 'GET_VENUES_REQUEST',
    GET_VENUES_SUCCESS: 'GET_VENUES_SUCCESS',
    GET_VENUES_ERROR: 'GET_VENUES_ERROR',

    GET_VENUE_DETAILS_REQUEST: 'GET_VENUE_DETAILS_REQUEST',
    GET_VENUE_DETAILS_SUCCESS: 'GET_VENUE_DETAILS_SUCCESS',
    GET_VENUE_DETAILS_ERROR: 'GET_VENUE_DETAILS_ERROR',

    GET_PLACE_DETAIL_REQUEST: 'GET_PLACE_DETAIL_REQUEST',
    GET_PLACE_DETAIL_SUCCESS: 'GET_PLACE_DETAIL_SUCCESS',
    GET_PLACE_DETAIL_ERROR: 'GET_PLACE_DETAIL_ERROR',

    GET_NEARBY_SEARCH_REQUEST: 'GET_NEARBY_SEARCH_REQUEST',
    GET_NEARBY_SEARCH_SUCCESS: 'GET_NEARBY_SEARCH_SUCCESS',
    GET_NEARBY_SEARCH_ERROR: 'GET_NEARBY_SEARCH_ERROR',

    ADD_VENUE_REQUEST: 'ADD_VENUE_REQUEST',
    ADD_VENUE_SUCCESS: 'ADD_VENUE_SUCCESS',
    ADD_VENUE_ERROR: 'ADD_VENUE_ERROR',

    CHANGE_VENUE_STATUS_REQUEST: 'CHANGE_VENUE_STATUS_REQUEST',
    CHANGE_VENUE_STATUS_SUCCESS: 'CHANGE_VENUE_STATUS_SUCCESS',
    CHANGE_VENUE_STATUS_ERROR: 'CHANGE_VENUE_STATUS_ERROR',

    EDIT_VENUE_REQUEST: 'EDIT_VENUE_REQUEST',
    EDIT_VENUE_SUCCESS: 'EDIT_VENUE_SUCCESS',
    EDIT_VENUE_ERROR: 'EDIT_VENUE_ERROR',

    DELETE_VENUE_REQUEST: 'DELETE_VENUE_REQUEST',
    DELETE_VENUE_SUCCESS: 'DELETE_VENUE_SUCCESS',
    DELETE_VENUE_ERROR: 'DELETE_VENUE_ERROR',


}


function getAllVenues(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            venuesService.getAllVenues(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getVenuesRequest) {
        return {type: venuesConstants.GET_VENUES_REQUEST, getVenuesRequest}
    }

    function success(getVenuesSuccess) {
        return {type: venuesConstants.GET_VENUES_SUCCESS, getVenuesSuccess}
    }

    function failure(getVenuesFail) {
        return {type: venuesConstants.GET_VENUES_ERROR, getVenuesFail}
    }
}
function getVenueDetails(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            venuesService.getAllVenues(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getVenueDetailsRequest) {
        return {type: venuesConstants.GET_VENUE_DETAILS_REQUEST, getVenueDetailsRequest}
    }

    function success(getVenueDetailsSuccess) {
        return {type: venuesConstants.GET_VENUE_DETAILS_SUCCESS, getVenueDetailsSuccess}
    }

    function failure(getVenueDetailsFail) {
        return {type: venuesConstants.GET_VENUE_DETAILS_ERROR, getVenueDetailsFail}
    }
}
function getPlaceById(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            venuesService.getPlaceById(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getPlaceByIdRequest) {
        return {type: venuesConstants.GET_PLACE_DETAIL_REQUEST, getPlaceByIdRequest}
    }

    function success(getPlaceByIdSuccess) {
        return {type: venuesConstants.GET_PLACE_DETAIL_SUCCESS, getPlaceByIdSuccess}
    }

    function failure(getPlaceByIdFail) {
        return {type: venuesConstants.GET_PLACE_DETAIL_ERROR, getPlaceByIdFail}
    }
}
function getNearBySearch(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            venuesService.getNearBySearch(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getNearBySearchRequest) {
        return {type: venuesConstants.GET_NEARBY_SEARCH_REQUEST, getNearBySearchRequest}
    }

    function success(getNearBySearchSuccess) {
        return {type: venuesConstants.GET_NEARBY_SEARCH_SUCCESS, getNearBySearchSuccess}
    }

    function failure(getNearBySearchFail) {
        return {type: venuesConstants.GET_NEARBY_SEARCH_ERROR, getNearBySearchFail}
    }
}
function addVenues(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            venuesService.addVenues(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(addVenueRequest) {
        return {type: venuesConstants.ADD_VENUE_REQUEST, addVenueRequest}
    }

    function success(addVenueSuccess) {
        return {type: venuesConstants.ADD_VENUE_SUCCESS, addVenueSuccess}
    }

    function failure(addVenueFail) {
        return {type: venuesConstants.ADD_VENUE_ERROR, addVenueFail}
    }
}
function editVenues(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            venuesService.editVenues(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(editVenueRequest) {
        return {type: venuesConstants.EDIT_VENUE_REQUEST, editVenueRequest}
    }

    function success(editVenueSuccess) {
        return {type: venuesConstants.EDIT_VENUE_SUCCESS, editVenueSuccess}
    }

    function failure(editVenueFail) {
        return {type: venuesConstants.EDIT_VENUE_ERROR, editVenueFail}
    }
}
function deleteVenues(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            venuesService.deleteVenues(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(deleteVenueRequest) {
        return {type: venuesConstants.DELETE_VENUE_REQUEST, deleteVenueRequest}
    }

    function success(deleteVenueSuccess) {
        return {type: venuesConstants.DELETE_VENUE_SUCCESS, deleteVenueSuccess}
    }

    function failure(deleteVenueFail) {
        return {type: venuesConstants.DELETE_VENUE_ERROR, deleteVenueFail}
    }
}
// function changeVenueStatus(params) {
//     return function (dispatch) {
//         return new promise(function (resolve, reject) {
//             dispatch(request(params));
//             venuesService.changeVenueStatus(params)
//                 .then(response => {
//                     let {serverResponseData} = ResponseFilter(response);
//                     resolve(serverResponseData);
//                     dispatch(success(serverResponseData));
//                 })
//                 .catch((err) => {
//                     reject(err);
//                     dispatch(failure(err));
//                 })
//         })
//     };
//
//     function request(changeVenueStatusRequest) {
//         return {type: venuesConstants.CHANGE_VENUE_STATUS_REQUEST, changeVenueStatusRequest}
//     }
//
//     function success(changeVenueStatusSuccess) {
//         return {type: venuesConstants.CHANGE_VENUE_STATUS_SUCCESS, changeVenueStatusSuccess}
//     }
//
//     function failure(changeVenueStatusFail) {
//         return {type: venuesConstants.CHANGE_VENUE_STATUS_ERROR, changeVenueStatusFail}
//     }
// }

