import promise from 'bluebird';
import {usersConstants} from '../_constants';
import {reportsService, usersService} from "../_services";
import {ResponseFilter} from "../config/response-handler";

export const reportsActions = {
    getReasons,
    getReports,
    getSurveys,
    getAddress,
};

export const reportsConstants = {

    GET_REASONS_REQUEST: 'GET_REASONS_REQUEST',
    GET_REASONS_SUCCESS: 'GET_REASONS_SUCCESS',
    GET_REASONS_ERROR: 'GET_REASONS_ERROR',

    GET_REPORTS_REQUEST: 'GET_REPORTS_REQUEST',
    GET_REPORTS_SUCCESS: 'GET_REPORTS_SUCCESS',
    GET_REPORTS_ERROR: 'GET_REPORTS_ERROR',

    GET_SURVEYS_REQUEST: 'GET_SURVEYS_REQUEST',
    GET_SURVEYS_SUCCESS: 'GET_SURVEYS_SUCCESS',
    GET_SURVEYS_ERROR: 'GET_SURVEYS_ERROR',

    GET_ADDRESS_REQUEST: 'GET_ADDRESS_REQUEST',
    GET_ADDRESS_SUCCESS: 'GET_ADDRESS_SUCCESS',
    GET_ADDRESS_ERROR: 'GET_ADDRESS_ERROR',

}


function getAddress(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            reportsService.getAddress(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getAddressRequest) {
        return {type: reportsConstants.GET_ADDRESS_REQUEST, getAddressRequest}
    }

    function success(getAddressSuccess) {
        return {type: reportsConstants.GET_ADDRESS_SUCCESS, getAddressSuccess}
    }

    function failure(getAddressFail) {
        return {type: reportsConstants.GET_ADDRESS_ERROR, getAddressFail}
    }
}

function getReasons(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            reportsService.getReasons(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getReasonsRequest) {
        return {type: reportsConstants.GET_REASONS_REQUEST, getReasonsRequest}
    }

    function success(getReasonsSuccess) {
        return {type: reportsConstants.GET_REASONS_SUCCESS, getReasonsSuccess}
    }

    function failure(getReasonsFail) {
        return {type: reportsConstants.GET_REASONS_ERROR, getReasonsFail}
    }
}
function getReports(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            reportsService.getReports(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getReportsRequest) {
        return {type: reportsConstants.GET_REPORTS_REQUEST, getReportsRequest}
    }

    function success(getReportsSuccess) {
        return {type: reportsConstants.GET_REPORTS_SUCCESS, getReportsSuccess}
    }

    function failure(getReportsFail) {
        return {type: reportsConstants.GET_REPORTS_ERROR, getReportsFail}
    }
}
function getSurveys(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            reportsService.getSurveys(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getSurveysRequest) {
        return {type: reportsConstants.GET_SURVEYS_REQUEST, getSurveysRequest}
    }

    function success(getSurveysSuccess) {
        return {type: reportsConstants.GET_SURVEYS_SUCCESS, getSurveysSuccess}
    }

    function failure(getSurveysFail) {
        return {type: reportsConstants.GET_SURVEYS_ERROR, getSurveysFail}
    }
}

