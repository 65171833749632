import {eventsConstants} from '../_actions/events.actions';

const initialState = {};


export function event(state = initialState, action) {
    switch (action.type) {
        case eventsConstants.GET_EVENTS_SUCCESS:
            let getEventsSuccessInfo = action.getEventsSuccess;
            return getEventsSuccessInfo

        case eventsConstants.GET_EVENT_DETAILS_SUCCESS:
            let getEventDetailsSuccessInfo = action.getEventDetailsSuccess;
            return getEventDetailsSuccessInfo
        case eventsConstants.ADD_EVENT_SUCCESS:
            let addEventSuccessInfo = action.addEventSuccess;
            return addEventSuccessInfo



        default:
            return {...state}
    }
}
