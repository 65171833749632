import _ from "lodash";

import DevSetting from './development';
import ProdSetting from './production';
import StagSetting from './staging';
import localhostSetting from './localhost';

let defaultSetting = {


  api: {
    url:'https://api.dingapp.net/ding/api/v1/',
    // url:'https://6ba9-2405-201-401b-5083-c468-c2d4-f09f-210/ding/api/v1/',
    // url: 'http://localhost:5001/ding/api/v1/',
    mode: 'cors'
  },

}

let siteSetting = defaultSetting;

switch (process.env.REACT_APP_ENV) {
  case "prod":
  case "production":
    siteSetting = _.extend(defaultSetting, ProdSetting);
    break;
  case "stag":
  case "staging":
    siteSetting = _.extend(defaultSetting, StagSetting);
    break;
  case "dev":
  case "development":
    siteSetting = _.extend(defaultSetting, DevSetting);
    break;
  case "local":
  case "localhost":
    siteSetting = _.extend(defaultSetting, localhostSetting);
    break;
  default:
    siteSetting = _.extend(defaultSetting, localhostSetting);
}

export default siteSetting;
