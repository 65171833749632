import promise from 'bluebird';
import {usersConstants} from '../_constants';
import {eventsService, usersService} from "../_services";
import {ResponseFilter} from "../config/response-handler";

export const eventsActions = {
    getEventDetails,
    getAllEvents,
    addEvents
};

export const eventsConstants = {

    GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
    GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
    GET_EVENTS_ERROR: 'GET_EVENTS_ERROR',

    GET_EVENT_DETAILS_REQUEST: 'GET_EVENT_DETAILS_REQUEST',
    GET_EVENT_DETAILS_SUCCESS: 'GET_EVENT_DETAILS_SUCCESS',
    GET_EVENT_DETAILS_ERROR: 'GET_EVENT_DETAILS_ERROR',

    ADD_EVENT_REQUEST: 'ADD_EVENT_REQUEST',
    ADD_EVENT_SUCCESS: 'ADD_EVENT_SUCCESS',
    ADD_EVENT_ERROR: 'ADD_EVENT_ERROR',

}


function getAllEvents(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            eventsService.getAllEvents(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getEventsRequest) {
        return {type: eventsConstants.GET_EVENTS_REQUEST, getEventsRequest}
    }

    function success(getEventsSuccess) {
        return {type: eventsConstants.GET_EVENTS_SUCCESS, getEventsSuccess}
    }

    function failure(getEventsFail) {
        return {type: eventsConstants.GET_EVENTS_SUCCESS, getEventsFail}
    }
}
function getEventDetails(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            eventsService.getAllEvents(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getEventDetailsRequest) {
        return {type: eventsConstants.GET_EVENT_DETAILS_REQUEST, getEventDetailsRequest}
    }

    function success(getEventDetailsSuccess) {
        return {type: eventsConstants.GET_EVENT_DETAILS_SUCCESS, getEventDetailsSuccess}
    }

    function failure(getEventDetailsFail) {
        return {type: eventsConstants.GET_EVENT_DETAILS_ERROR, getEventDetailsFail}
    }
}
function addEvents(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            eventsService.addEvents(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(addEventRequest) {
        return {type: eventsConstants.ADD_EVENT_REQUEST, addEventRequest}
    }

    function success(addEventSuccess) {
        return {type: eventsConstants.ADD_EVENT_SUCCESS, addEventSuccess}
    }

    function failure(addEventFail) {
        return {type: eventsConstants.ADD_EVENT_ERROR, addEventFail}
    }
}

