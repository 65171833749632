import React, {Component} from 'react'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import Sidebar from "../common/sidebar";
import {usersActions} from "../../_actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import _ from 'lodash';
import moment from "moment-timezone";
import {UserIcon} from "../Svg";
import Pagination from "react-js-pagination";
import {DatePicker, Select} from "antd";


class TicketsPurchases extends Component {

    constructor(props) {
        super(props);


        this.state = {

            errors: '',
            success: '',
            totalCount: 0,
            limit: 15,
            page: 0,
            eventList: [],
            isEdit: false,

            userId: localStorage.getItem('userId'),
userProfile: [],

            valiDate: true, loader: false,


        }

    }

    componentDidMount() {
        this.getAllTicketPurchases()
        // this.getUserDetail()

    }


    getAllTicketPurchases = () => {
        let {userId, farmLogo, farmImage, farmVideo, farmLogoPreview, farmVideoPreview, farmName, farmStatus, infoAboutFarm, infoAboutFarmer, description} = this.state;
        this.setState({errors: ''});

        let self = this
        this.setState({loader: true});
// let userId=
        console.log(this.props.match.params.id, "iuytr")
        let params = `?sortField=${"created"}&sortType=${this.state.sortType}&limit=${this.state.limit}&page=${this.state.page}`

        let {dispatch} = this.props;
        dispatch(usersActions.getAllTicketPurchases(params)).then(data => {
            if (!_.isEmpty(data)) {
                // console.log("jkhgjhfgdxf",self.props.farm)
                let response = data.responseData.response
                self.setState({loader: false})

                if (data.statusCode == 1) {
                    console.log("muuuuuuuuuuuuuuuuuuuuuu", response)
                    // console.log(ticketList,"dddddddddddd")

                    self.setState({
                        ticketList: response.result,
                        totalCount: response.totalCount,


                    })
                } else {
                    self.setState({errors: data.error.responseMessage})
                }
            }

        });

    }
   getUserDetail= () => {
        let {userId, eview, farmLogo, farmImage, farmVideo, farmLogoPreview, farmVideoPrfarmName, farmStatus, infoAboutFarm, infoAboutFarmer, description} = this.state;
        this.setState({errors: ''});

        let self = this
        this.setState({loader: true});
// let userId=
        console.log(this.props.match.params.id, "iuytr")
       let params = `?sortField=${"created"}&sortType=${this.state.sortType}`

        let {dispatch} = this.props;
        dispatch(usersActions.getUserDetail(params)).then(data => {
            if (!_.isEmpty(data)) {
                // console.log("jkhgjhfgdxf",self.props.farm)
                let userProfile = data.responseData.userProfile
                self.setState({loader: false})

                if (data.statusCode == 1) {
                    console.log("muuuuuuuuuuuuuuuuuuuuuu", userProfile)

                    self.setState({
                        userProfile:  userProfile


                    })
                } else {
                    self.setState({errors: data.error.responseMessage})
                }
            }

        });

    }

    onKeyPress(e) {
        e = e || window.event;
        var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        var charStr = String.fromCharCode(charCode);

        if (!charStr.match(/^[0-9]+$/))
            e.preventDefault();
    }

    ConvertDob = (dob) => {
        // let data =        Date(dob)
        let data = moment(dob).format("MM/DD/YYYY")
        return data
    }
    ConvertDate = (created) => {
        let data = moment(created).format("MM/DD/YYYY")
        return data
    }
    handleSort = sortField => this.setState({
        loader: true,
        sortField,
        sortType: this.state.sortType === 1 ? -1 : 1
    }, () => this.getAllTicketPurchases())

    handlePageChange = (pageNo) => {

        var self = this;
        this.setState({loader: false, page: pageNo-1, limit: 15}, () => {
            self.getAllTicketPurchases();
        });
    }
    render() {
        let {
            shortInfo, userProfile, media, farmMediaErrorMsg, farmImage1Preview,
            dishedYou, youDished, farmLogoPreview, farmLogoErrorMsg, farmNameErrorMsg, infoAboutFarmErrorMsg, infoAboutFarmerErrorMsg, addressLine1ErrorMsg
            ,farmName, infoAboutFarm, infoAboutFarmer,ticketList,
            totalCount,
            limit,
            page,
        } = this.state;
        console.log(ticketList,"dddddddddddd")
        return (
            <>
                <Sidebar/>


                <div className="body_container">
                    <div className=" ">
                        <div className="row mb-3">
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="count-list">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h6 className="mb-2">Total Purchases</h6>
                                                    <h6 className="mb-0">{totalCount}</h6>
                                                </div>
                                                <div>
                                                        <span className="user-icon icon-blue">
                                                            <UserIcon/>
                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div className="body_inner_wrapper">
                            {this.state.loader ? <div className="loader_wrapper">
                                    <div className="spinner-border text-info" role="status"></div>
                                </div> :
                                (<>
                                    <header className="header_content">
                                        <p>Purchased Tickets</p>


                                        {/*<div className="d-flex text-nowrap header_btn">*/}
                                        {/*    <Button onClick={this.addPopupModal}*/}
                                        {/*            variant="warning"*/}
                                        {/*            className="changepassword">*/}
                                        {/*        Add Events*/}
                                        {/*    </Button>*/}

                                        {/*</div>*/}

                                    </header>


                                    <div className="main_content">
                                        <div className="table-responsive">
                                            <table className="table cm_bottom_space">
                                                <thead>
                                                <tr className="broder_top_0">
                                                    <th scope="col" className="ws-nowrap">S. No.</th>
                                                    <th scope="col" onClick={() => this.handleSort('created')}>Created
                                                        On<i
                                                            className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                    </th>
                                                    <th scope="col" >Event
                                                        Name
                                                    </th>



                                                    <th scope="col" > User Name<i
                                                        className=" text_dark ml-2 d-inline-block"></i></th>
                                                    <th scope="col" className="ws-nowrap"> Ticket Type</th>
                                                    <th scope="col" className="ws-nowrap"> Ticket Amount</th>
                                                    <th scope="col" className="ws-nowrap"> Total Amount(inc. Tax)</th>
                                                    <th scope="col" className="ws-nowrap"> Ticket Quantity</th>
                                                    {/*<th scope="col" className="ws-nowrap"> country</th>*/}
                                                    {/*<th scope="col" className="ws-nowrap"> zipCode</th>*/}
                                                    {/*<th scope="col" className="ws-nowrap"> Status</th>*/}


                                                </tr>
                                                </thead>
                                                <tbody>

                                                {!_.isEmpty(ticketList) && ticketList.map((item, i) => (
                                                    <tr key={item._id}>
                                                        <td>{((limit * (page )) + (i + 1))}</td>
                                                        <td>{this.ConvertDate(item.createdDate)}</td>
                                                        <td>{item.eventsData && item.eventsData.eventName}</td>
                                                        <td>
                                                            {/*{item.businessData?item.businessData.firstName:''}*/}
                                                            <Link
                                                                to={`user-info/${item.usersData._id}`}>{item.usersData.firstName} {item.usersData.lastName}</Link>
                                                        </td>
                                                        {/*<td>{item.venueData ? item.venueData.businessName : ''}</td>*/}

                                                        <td>{item.ticketType?"Paid":"Free"}</td>
                                                        <td>{item.ticketAmount}</td>
                                                        <td>{item.totalAmount}</td>

                                                        <td>{item.quantity}</td>
                                                        {/*<td>{item.status==1?"Active":"Inactive"}*/}
                                                            {/*<select value={item.userStatus} disabled={true}*/}
                                                            {/*        name="userStatus"*/}
                                                            {/*        // onChange={(e)=>this.handleDelete(e,item)}*/}
                                                            {/*        className="form-control-sm">*/}
                                                            {/*    /!*<option value=''>Select</option>*!/*/}
                                                            {/*    <option value='1'>Active</option>*/}
                                                            {/*    <option value='2'>Inactive</option>*/}

                                                            {/*</select>*/}
                                                            {/*<span> <i className="fas fa-pen"*/}
                                                            {/*          onClick={() => this.editPopupModal(item)}*/}
                                                            {/*></i></span>*/}
                                                            {/*<span> <i className="fas fa-trash text-danger"*/}
                                                            {/*          onClick={() => this.deletePopupModal(item)}></i></span>*/}
                                                        {/*</td>*/}
                                                    </tr>))
                                                }


                                                </tbody>


                                            </table>
                                            {ticketList && ticketList.length ?
                                                <div
                                                    className="d-flex justify-content-end cm-pagination">
                                                    <Pagination

                                                        itemsCountPerPage={limit}
                                                        itemClassPrev={'prevClass'}
                                                        itemClassNext={'nextClass'}
                                                        activePage={page+1}
                                                        totalItemsCount={totalCount}
                                                        pageRangeDisplayed={3}
                                                        onChange={this.handlePageChange}
                                                    />
                                                </div> : ''}
                                            {_.isEmpty(this.state.ticketList) ?
                                                <h3 className="text-center">No data found</h3> : ''}
                                            {/*{reasonErMsg ?*/}
                                            {/*    <div className="text-center error ">{reasonErMsg}</div> : ''}*/}
                                        </div>


                                    </div>


                                </>)}


                        </div>


                    </div>
{/*
                    <Modal aria-labelledby="example-modal-sizes-title-sm "
                           show={this.state.addEventPopup}
                           onHide={this.handleClose}
                           className="cm_order_view order-detailsmodal">
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm"><h6>Add Event</h6></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <form onSubmit={this.addEvents}>
                                    <Row>

                                        <Col md="2">
                                            <p>Banner Image:</p>

                                        </Col>

                                        <Col md="6">
                                            <div className="u_img mb-5">
                                                {profileCoverPhotosPreview ?
                                                    <img
                                                        className="rounded-circle1"
                                                        src={profileCoverPhotosPreview}
                                                        className="logoroundimg"/> :
                                                    <span
                                                        className="profile_icon_Admin ">
                                                                                            <img
                                                                                                src={"https://ding-dev.s3.amazonaws.com/badgeActive_1634720520734_placeholder.png"}
                                                                                                className="logoroundimg"/></span>}
                                                <span className="img_icon">
                                                                                        <span className="file_input">
                                                                                            <input type="file"
                                                                                                   className="cm_hide_input"
                                                                                                   accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                                                                                   onChange={this.handlePicUpload}/>
                                                                                        </span>
                                                                                        <img
                                                                                            src={require('../../assets/img/imgicon.png')}/>
                                                                                    </span>
                                            </div>
                                            {profileCoverPhotosErrorMsg ?
                                                <div
                                                    className="error">{profileCoverPhotosErrorMsg}</div> : null}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="2">
                                            <p>Event Name:</p>

                                        </Col>
                                        <Col md="4">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="eventName"
                                                          value={eventName}
                                                          placeholder="Event Name"/>
                                            {eventNameErrorMsg ?
                                                <div
                                                    className="error">{eventNameErrorMsg}</div> : null}
                                        </Col>


                                        <Col md="2">
                                            <p>Description:</p>

                                        </Col>
                                        <Col md="4">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="description"
                                                          value={description}
                                                          placeholder="Description"/>
                                            {descriptionErrorMsg ?
                                                <div
                                                    className="error">{descriptionErrorMsg}</div> : null}
                                        </Col>



                                    </Row>


                                    <Row className="mb-sm-3">

                                        <Row>

                                            <Col md="2">
                                                <p>Venue Type
                                                </p>

                                            </Col>
                                            <Col md="4">

                                                <Select
                                                    mode={"multiple"}
                                                    // style={{ width: '100%' }}
                                                    // inputMode={"multiple"}
                                                    name="venueId"
                                                    onDeselect={this.deSelectVenues}
                                                    onSelect={this.handleChangedVenues}
                                                    className="">


                                                    <Option key="Food">Food</Option>
                                                    <Option key="Drinks">Drinks</Option>
                                                    <Option key="Games">Games</Option>
                                                    <Option key="Music">Music</Option>
                                                    <Option key="Hookah">Hookah</Option>
                                                    <Option key="Indoor">Indoor</Option>
                                                    <Option key="Outdoor">Outdoor</Option>
                                                    <Option key="Booths/Sections">Booths/Sections</Option>


                                                </Select>
                                                {venueIdErrorMsg ?
                                                    <div
                                                        className="error">{venueIdErrorMsg}</div> : null}
                                            </Col>


                                        </Row>
                                        <Col lg="2" md="3">
                                            <p>Pick Up / Delivery:</p>
                                            <p> Event Type:</p>

                                        </Col><>
                                        <Col lg="2" md="3">
                                            <label className="pt-3"><input type="radio" id="selectFreeRadio"
                                                                           value={selectFreeRadio}
                                                                           onChange={this.handleRadio}
                                                                           name="eventType"/>


                                                Free:</label>
                                        </Col>

                                        <Col lg="2" md="3">
                                            <label className="pt-3">
                                                <input type="radio" id="selectPaidRadio" name="eventType"
                                                       value={selectPaidRadio} onChange={this.handleRadio}/>

                                                Paid:</label>
                                        </Col>
                                        {eventTypeErrorMsg ?
                                            <div
                                                className="error errType">{eventTypeErrorMsg}</div> : null}
                                    </>
                                    </Row>
                                    <Row>


                                        <Col md="2">
                                            <p>Start date:</p>

                                        </Col>
                                        <Col md="4">
                                            <DatePicker
                                                allowClear={false}
                                                value={startDate ? moment(startDate) : ''}
                                                defaultValue={moment(startDate) ? moment(startDate) : 'Select start date'}
                                                className="form-control"
                                                onChange={this.handleDateChange}
                                                // format={dateFormat}

                                            />
                                            {startDateErrorMsg ?
                                                <div
                                                    className="error">{startDateErrorMsg}</div> : null}
                                        </Col>
                                        <Col md="2">
                                            <p>Start Time:</p>

                                        </Col>
                                        <Col md="4">
                                            <DatePicker
                                                allowClear={false}
                                                value={startTime ? moment(startTime) : ''}
                                                defaultValue={moment(startTime) ? moment(startTime) : 'Select start time'}
                                                className="form-control"
                                                onChange={this.handleDateChange}
                                                // format={dateFormat}

                                            />
                                            {starTimeErrorMsg ?
                                                <div
                                                    className="error">{starTimeErrorMsg}</div> : null}
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col md="2">
                                            <p>

                                                End date:</p>

                                        </Col>


                                        <Col md="4">
                                            <DatePicker
                                                allowClear={false}
                                                value={endDate ? moment(endDate) : ''}
                                                defaultValue={moment(endDate) ? moment(endDate) : 'Select end date'}
                                                className="form-control"
                                                onChange={this.handleDateChange}
                                                // format={dateFormat}

                                            />
                                            {endDateErrorMsg ? <div className="error">{endDateErrorMsg}</div> : null}
                                        </Col>
                                        <Col md="2">
                                            <p>

                                                End Time:</p>

                                        </Col>


                                        <Col md="4">
                                            <DatePicker
                                                allowClear={false}
                                                value={endTime ? moment(endTime) : ''}
                                                defaultValue={moment(endTime) ? moment(endTime) : 'Select end time'}
                                                className="form-control"
                                                onChange={this.handleDateChange}
                                                // format={dateFormat}

                                            />
                                            {endTimeErrorMsg ? <div className="error">{endTimeErrorMsg}</div> : null}
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <p>Ticket Capacity
                                            </p>

                                        </Col>
                                        <Col md="4">
                                            <Form.Control type="Number"
                                                          onChange={this.handleChanged}
                                                          name="ticketCapacity"
                                                          value={ticketCapacity}
                                                          placeholder="Ticket capacity"/>
                                            {ticketCapacityErrorMsg ?
                                                <div
                                                    className="error">{ticketCapacityErrorMsg}</div> : null}

                                        </Col>

                                        <Col md="2">
                                            <p>Ticket Admission Amount
                                            </p>

                                        </Col>
                                        <Col md="4">
                                            <Form.Control type="Number"
                                                          onChange={this.handleChanged}
                                                          name="ticketAdmissionAmount"
                                                          value={ticketAdmissionAmount}
                                                          placeholder="Ticket admission amount"/>
                                            {ticketAdmissionAmountErrorMsg ?
                                                <div
                                                    className="error">{ticketAdmissionAmountErrorMsg}</div> : null}

                                        </Col>


                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <p>Early Bird Amount
                                            </p>

                                        </Col>
                                        <Col md="4">
                                            <Form.Control type="Number"
                                                          onChange={this.handleChanged}
                                                          name="earlyBirdAmount"
                                                          value={earlyBirdAmount}
                                                          placeholder="Early bird amount"/>
                                            {earlyBirdAmountErrorMsg ?
                                                <div
                                                    className="error">{earlyBirdAmountErrorMsg}</div> : null}

                                        </Col>

                                        <Col md="2">
                                            <p>Skip Line Amount
                                            </p>

                                        </Col>
                                        <Col md="4">
                                            <Form.Control type="Number"
                                                          onChange={this.handleChanged}
                                                          name="skipLineAmount"
                                                          value={skipLineAmount}
                                                          placeholder="Skip line amount"/>
                                            {skipLineAmountErrorMsg ?
                                                <div
                                                    className="error">{skipLineAmountErrorMsg}</div> : null}

                                        </Col>


                                    </Row>
                                    <Row>


                                        <Col md="2">
                                            <p>address
                                            </p>

                                        </Col>
                                        <Col md="6">
                                            <Form.Control type="textarea"
                                                          onChange={this.handleChangedAddress}
                                                          name="address"
                                                          value={address}

                                                          placeholder="address"/>
                                            {addressErrorMsg ? <div className="error">{addressErrorMsg}</div> : null}
                                        </Col>


                                    </Row>


                                    {errors ? errors : ''}
                                    {errors ? <div className="error">{errors}</div> : null}

                                    <Row>
                                        <Col md="10" className="text-sm-right">
                                            <Button type="submit"
                                                    variant="warning"
                                                    className="mr-2">Save</Button>
                                            &nbsp;

                                            <Button onClick={this.handleClose}
                                                    variant="dark">Cancel</Button>


                                        </Col>
                                    </Row>


                                </form>

                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal aria-labelledby="example-modal-sizes-title-sm "
                           show={this.state.editPopup}
                           onHide={this.handleClose}
                           className="cm_order_view order-detailsmodal">
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm"><h6>Edit Badge</h6></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <form onSubmit={this.editBadges}>

                                    <Row>


                                        <Col md="9">
                                            <div className="u_img mb-5">
                                                {profilePicPreview ?
                                                    <img
                                                        className="rounded-circle1"
                                                        src={profilePicPreview}
                                                        className="logoroundimg"/> :
                                                    <span
                                                        className="profile_icon_Admin ">
                                                                                            <img
                                                                                                src={profilePicPreview}
                                                                                                className="logoroundimg"/></span>}
                                                <span className="img_icon">
                                                                                        <span className="file_input">
                                                                                            <input type="file"
                                                                                                   className="cm_hide_input"
                                                                                                   accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                                                                                   onChange={this.handlePicUpload}/>
                                                                                        </span>
                                                                                        <img
                                                                                            src={require('../../assets/img/imgicon.png')}/>
                                                                                    </span>
                                            </div>
                                            {profilePicPreview ?
                                                <div
                                                    className="error">{profilePicPreview}</div> : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="10" className="text-sm-right">
                                            <Button type="submit"
                                                    variant="warning"
                                                    className="mr-2">Save</Button>
                                            &nbsp;

                                            <Button onClick={this.handleClose}
                                                    variant="dark">Cancel</Button>


                                        </Col>
                                    </Row>


                                </form>

                            </div>
                        </Modal.Body>

                    </Modal>

                    <Modal show={deletePopup} onHide={this.handleClose} className="cm_order_view dlt_item">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src={require('../../assets/img/Delete-Email.png')}/>
                            <br/>
                            <br/>
                            <p>Are you sure you want to delete?</p>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="footer_btn_wrapper">
                                <Button variant="warning" onClick={(e) => this.handleDelete()}>
                                    Delete
                                </Button>
                                <span><i onClick={(e)=>this.handleDelete(e,item)} class="fas fa-trash-alt"></i></span>

                                <Button variant="dark" onClick={this.handleClose}>
                                    Cancel
                                </Button>
                            </div>

                        </Modal.Footer>
                    </Modal>
*/}

                </div>
            </>)
    }

}

function mapStateToProps(state) {
    const {props, login, user, mapFarmReducer, farm} = state;
    console.log("saeeeeeeeeeeeeeeemapFarmReducer", mapFarmReducer)

    return {
        props,
        login,
        user, mapFarmReducer,
        farm
    }
}

export default connect(mapStateToProps)(TicketsPurchases);
