
export default {
  api: {
    // url:'https://6ba9-2405-201-401b-5083-c468-c2d4-f09f-210/ding/api/v1/',

    url:'https://devapi.dingapp.net/ding/api/v1/',
    mode: 'cors'
  },

  config: {

  }

}
