import React, {Component} from 'react';
import {Button, Col, Form, Modal, Nav, Row, Tab} from 'react-bootstrap'
import {connect} from "react-redux";
import Sidebar from '../../components/common/sidebar'
import Loader from '../../components/common/Loader'
import {notify} from '../../components/common/Toster'
import {usersActions, venuesActions} from "../../_actions";
import {UserIcon} from "../Svg";
import _ from "lodash";
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";
import {eventsActions} from "../../_actions/events.actions";
import PhoneInput from "react-phone-input-2";
import {Select} from "antd";
import {DatePicker, Modal as AntModal} from 'antd';
import moment from 'moment';
// import { Select } from 'antd';

const {Option} = Select;
// const dateFormat = 'DD/MM/YYYY';
class Events extends Component {

    constructor(props) {
        super(props);


        this.state = {

            email: '',
            phone: '',
            changePic: false,
            name: '',
            emailErrorMsg: '',
            errors: '',
            success: '',
            venueList: [],
            isEdit: false,
            deletePopup: false,
            editPopup: false,
            addPopup: false,
            badgeActivePreview: '', badgeActive: '', badgeActiveErrorMsg: '',
            badgeInActivePreview: '', badgeInActive: '', badgeInActiveErrorMsg: '',
            userId: localStorage.getItem('userId'),
            address: '',
            firstNameErrorMsg: '', badgeName: '',
            loader: false,
            isChangePass: false,
            oldPassword: '',
            oldPasswordErrorMsg: '',
            newPassword: '',
            newPasswordErrorMsg: '',
            confirmPassword: '',
            confirmPasswordErrorMsg: '',
            search: '',
            page: 1,
            limit: 10,
            sortType: '',
            sortField: '',
            eventNameErrorMsg: '', venueIdErrorMsg: '', starTimeErrorMsg: '', endTimeErrorMsg: '',
            ticketCapacityErrorMsg: '', endDateErrorMsg: '', startDateErrorMsg: '', descriptionErrorMsg: '',
            skipLineAmountErrorMsg: '', earlyBirdAmountErrorMsg: '', ticketAdmissionAmountErrorMsg: '',

        }

    }

    componentDidMount() {
        this.getEventInfo()

    }

    getEventInfo = () => {
        this.setState({errors: ''});

        let self = this
        let {search, radius, page, limit, sortType, sortField} = this.state;

        let {dispatch} = this.props;
        let params = `?search=${search}&sortField=${sortField}&page=${page-1}&limit=${limit}&sortType=${sortType}`

        // let params = {search,radius,page,limit,sortType,sortField}
        dispatch(eventsActions.getAllEvents(params)).then(data => {
            this.setState({loader: false})
            if (data.statusCode == 1) {
                console.log(data.responseData.response, "fffffffffffffffffffff")
                let eventList = data.responseData.response.events
                let totalCount = data.responseData.response.totalCount
                this.setState({
                    eventList: eventList,
                    totalCount: totalCount,

                })
            } else {
                this.setState({errors: data.error.responseMessage})
            }
        });
    }


    handleProfileValidation = () => {
        let validate = true;
        let {email, profilePic, addressLine1, city, postalCode, state, name, phone, password, userType} = this.state;

        if (profilePic) {


            if (profilePic.size > 5000000) {
                validate = false;
                this.setState({
                    profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                })


            } else {
                this.setState({
                    profilePicErrorMsg: ''
                })
            }
        }
        if (name === '' || name === undefined) {
            validate = false;

            this.setState({
                firstNameErrorMsg: 'Please enter first name.'
            })
        } else {
            this.setState({
                firstNameErrorMsg: ''
            })
        }
        if (phone) {
            if (phone === '' || phone === undefined) {
                validate = false;

                this.setState({
                    phoneErrorMsg: 'Please enter phone number.'
                })
            } else if (phone.length > 3 && phone.length < 10) {
                validate = false;
                this.setState({phoneErrorMsg: 'Please enter a valid mobile number.'})
            } else {
                this.setState({
                    phoneErrorMsg: ''
                })
            }
        }

        return validate
    }


    handleChanged = (e) => {
        this.setState({
            errors: '',
            oldPasswordErrorMsg: '',
            newPasswordErrorMsg: '',
            confirmPasswordErrorMsg: '',

            [e.target.name]: e.target.value,

        });
    }


    handleEditSubmit = (e) => {


        let {
            radius,
            profilePic,
            messagePopup,
            alertPopup,
            emailPopup,
            email,
            name,
            lastName,
            address,
            phone,
            minimumOrderValue,
            addressId,
            userId
        } = this.state;
        this.setState({errors: ''});
        e.preventDefault()

        let self = this
        if (this.handleProfileValidation()) {

            let obj = new FormData()
            obj.append('profileCover', profilePic);
            obj.append('name', name);
            obj.append('phone', phone);
            self.setState({loader: true})
            let {dispatch} = this.props;

            dispatch(usersActions.editProfile(obj))

                .then((data) => {
                    if (data.statusCode == 1) {
                        notify('success', data.responseData.responseMessage)
                        self.setState({
                            loader: false,
                            isEdit: false,
                            changePic: true,
                            profileImage: data.responseData.userProfile.profileImage
                        })
                        self.getAdminInfo()
                    } else {
                        self.setState({loader: false,})
                        self.setState({errors: data.data.error.responseMessage})
                    }

                }).catch(err => {

                self.setState({loader: false,})
                this.setState({isloader: false})
                notify('error', err.data.error.responseMessage)
            })
        }
    }


    handlePicUpload = (event) => {


        let reader = new FileReader();
        let file = event.target.files[0];

        let {valiDate} = this.state;

        reader.addEventListener("load", () => {
            this.setState({badgeActivePreview: reader.result}, function () {
            })
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {


            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {

                this.setState({
                    badgeActive: event.target.files[0],
                    badgeActiveErrorMsg: ''

                });

            } else {
                valiDate = false;
                this.setState({badgeActiveErrorMsg: 'File type not supported.'})

            }
        }

    }
    handleInPicUpload = (event) => {


        let reader = new FileReader();
        let file = event.target.files[0];

        let {valiDate} = this.state;

        reader.addEventListener("load", () => {
            this.setState({badgeInActivePreview: reader.result}, function () {
            })
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {


            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {

                this.setState({
                    badgeInActive: event.target.files[0],
                    badgeInActiveErrorMsg: ''

                });

            } else {
                valiDate = false;
                this.setState({badgeInActiveErrorMsg: 'File type not supported.'})

            }
        }

    }
    handleChangedVenues = (id) => {
        let {venues} = this.state;
        console.log(venues, "011111111", id)

        venues.push(id.toString())
        console.log(venues, "I11111111")
        venues = venues.filter(function (item, index, inputArray) {
            console.log(item, "item", index, "index", inputArray, "arinp", "2211111111")
            console.log(inputArray.indexOf(item), 'ssssssssss11111111')
            return inputArray.indexOf(item) == index;
        });
        venues = [...new Set(venues)]
        this.setState({venues: venues})
    }
    deSelectVenues = (e) => {

        let {venues} = this.state;
        console.log(e, "eeeeeeeeeeeee11111", venues)
        // ExamIds = ExamIds.filter(itm => (itm.toString() !==e.toString()))

        venues = venues.filter(function (item) {

            console.log("eeeeeeeeeeeee11111111", item, "eeeeeeeeeeeee", item !== e, "eeeeeeeeeeeee", item, "eeeeeeeeeeeee", e)
            return item !== e
        })
        this.setState({venues: venues})
        console.log(e, "eeeeeeeeeeeee2111111111", venues)

    }

    handleDateChange = (date, dateString) => {

        console.log(date, moment(date), "dateeeeeeeeeeeeeeeeeeeeeee", moment(date).toDate().setTime(moment(date).toDate().getTime()))
        let dates = new Date()


        // console.log(,"dateeeeeeeeeeeeeeeeeeeeeeeas")

        let interval1 = moment(date).toDate().setTime(moment(date).toDate().getTime())
        // // let interval = date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
        interval1 = Math.floor(interval1 / 1000)
        //
        console.log(interval1, "dateeeeeeeeeeeeeeeeeeeeeeeas")
        this.setState({examDate: moment(date).toDate().getTime(), examDateErrorMsg: '',})
    }

    handleValidation = () => {
        let validate = true;
        let {
            venueId, eventName, hostName, description, eventType,
            startDate, endDate, startTime, endTime, lat,
            long, address, city, state, country, zipCode,
            ticketCapacity, ticketAdmissionAmount, earlyBirdAmount,
            skipLineAmount,
            hostImage
        } = this.state;
        console.log(address,"Sdddddddddddddddddxz")

        if (venueId === '' || venueId === undefined) {
            validate = false;
            this.setState({
                venueIdErrorMsg: 'Venue is required.'
            })
        } else {
            this.setState({
                venueIdErrorMsg: ''
            })
        }
        if (eventName === '' || eventName === undefined) {
            validate = false;
            this.setState({
                eventNameErrorMsg: 'Event name is required.'
            })
        } else {
            this.setState({
                eventNameErrorMsg: ''
            })
        }
        if (description === '' || description === undefined) {
            validate = false;
            this.setState({
                descriptionErrorMsg: 'Description  is required.'
            })
        } else {
            this.setState({
                descriptionErrorMsg: ''
            })
        }
        if (startDate === '' || startDate === undefined) {
            validate = false;
            this.setState({
                startDateErrorMsg: ' Start date is required.'
            })
        } else {
            this.setState({
                startDateErrorMsg: ''
            })
        }
        if (endDate === '' || endDate === undefined) {
            validate = false;
            this.setState({
                endDateErrorMsg: ' End date is required.'
            })
        } else {
            this.setState({
                endDateErrorMsg: ''
            })
        }
        if (startTime === '' || startTime === undefined) {
            validate = false;
            this.setState({
                starTimeErrorMsg: ' Start Time is required.'
            })
        } else {
            this.setState({
                starTimeErrorMsg: ''
            })
        }
        if (endTime === '' || endTime === undefined) {
            validate = false;
            this.setState({
                endTimeErrorMsg: ' End time is required.'
            })
        } else {
            this.setState({
                endTimeErrorMsg: ''
            })
        }
        if (address === '' || address === undefined) {
            validate = false;
            this.setState({
                addressErrorMsg: 'Address is required.'
            })
        } else {
            this.setState({
                addressErrorMsg: ''
            })
        }
        if (eventType === '' || eventType === undefined) {
            validate = false;
            this.setState({
                eventTypeErrorMsg: 'Event type is required.'
            })
        } else {
            this.setState({
                eventTypeErrorMsg: ''
            })
        }
        if (ticketCapacity === '' || ticketCapacity === undefined) {
            validate = false;
            this.setState({
                ticketCapacityErrorMsg: 'Ticket capacity is required.'
            })
        } else {
            this.setState({
                ticketCapacityErrorMsg: ''
            })
        }
        if (ticketAdmissionAmount === '' || ticketAdmissionAmount === undefined) {
            validate = false;
            this.setState({
                ticketAdmissionAmountErrorMsg: 'Ticket Admission amount is required.'
            })
        } else {
            this.setState({
                ticketAdmissionAmountErrorMsg: ''
            })
        }
        if (earlyBirdAmount === '' || earlyBirdAmount === undefined) {
            validate = false;
            this.setState({
                earlyBirdAmountErrorMsg: 'Early bird amount is required.'
            })
        } else {
            this.setState({
                earlyBirdAmountErrorMsg: ''
            })
        }
        if (skipLineAmount === '' || skipLineAmount === undefined) {
            validate = false;
            this.setState({
                skipLineAmountErrorMsg: 'Skip line amount is required.'
            })
        } else {
            this.setState({
                skipLineAmountErrorMsg: ''
            })
        }
        return validate
    }


    addEvents = event => {
        event.preventDefault();
        var self = this

        if (this.handleValidation()) {

            let {
                venueId,
                eventName,
                hostName,
                description,
                eventType,
                startDate,
                endDate,
                startTime,
                endTime,
                lat,
                long,
                address,
                city,
                state,
                country,
                zipCode,
                ticketCapacity,
                ticketAdmissionAmount,
                earlyBirdAmount,
                skipLineAmount,
                hostImage

            } = self.state

            let obj = new FormData()
            obj.append('venueId', venueId);
            obj.append('eventName', eventName);
            obj.append('description', description);
            obj.append('hostName', hostName);
            obj.append('hostImage', hostImage);
            obj.append('skipLineAmount', skipLineAmount);
            obj.append('earlyBirdAmount', earlyBirdAmount);
            obj.append('ticketAdmissionAmount', ticketAdmissionAmount);
            obj.append('ticketCapacity', ticketCapacity);
            obj.append('address', address);
            obj.append('city', city);
            obj.append('state', state);
            obj.append('country', country);
            obj.append('zipCode', zipCode);
            obj.append('startDate', startDate);
            obj.append('endDate', endDate);
            obj.append('startTime', startTime);
            obj.append('endTime', endTime);
            obj.append('eventType', eventType);
            obj.append('lat', lat);
            obj.append('long', long);
            self.setState({loader: true})

            this.setState({loader: true, errors: '', success: ''})


            let {dispatch} = this.props;
            dispatch(venuesActions.addVenues(obj)).then(data => {
                self.setState({loader: false})
                if (data.statusCode == 1) {
                    self.setState({
                        loader: false,
                        addVenuePopup: false,
                        isEdit: false,
                        success: data.responseData.message,
                        newPassword: '',
                        oldPassword: '',
                        confirmPassword: ''
                    })
                    this.getAdminInfo()
                } else {
                    self.setState({errors: data.error.responseMessage})
                }
            })


        }
    }
    editBadges = event => {
        event.preventDefault();
        var self = this

        // if (this.handleValidation()) {

        let {badgeName, badgeActive, badgeInActive, editId} = self.state

        let obj = new FormData()
        obj.append('badgeId', editId);
        obj.append('badgeName', badgeName);
        obj.append('badgeActive', badgeActive);
        obj.append('badgeInActive', badgeInActive);
        self.setState({loader: true})

        this.setState({loader: true, errors: '', success: ''})


        let {dispatch} = this.props;
        dispatch(usersActions.editBadges(obj)).then(data => {
            self.setState({loader: false})
            if (data.statusCode == 1) {
                self.setState({
                    loader: false,
                    editPopup: false,
                    isEdit: false,
                    success: data.responseData.message,
                    newPassword: '',
                    oldPassword: '',
                    confirmPassword: ''
                })
                this.getAdminInfo()
            } else {
                self.setState({errors: data.error.responseMessage})
            }
        })


    }
    handleDelete = () => {
        var self = this


        let {badgeName, badgeActive, badgeInActive, editId} = self.state

        let obj = {}
        obj = `?badgeId=${editId}`

        this.setState({loader: true, errors: '', success: ''})


        let {dispatch} = this.props;
        dispatch(usersActions.deleteBadges(obj)).then(data => {
            self.setState({loader: false})
            if (data.statusCode == 1) {
                self.setState({
                    loader: false,
                    editPopup: false,
                    deletePopup: false,
                    isEdit: false,
                    success: data.responseData.message,
                    newPassword: '',
                    oldPassword: '',
                    confirmPassword: ''
                })
                this.getAdminInfo()
            } else {
                self.setState({errors: data.error.responseMessage})
            }
        })

    }

    addPopupModal = () => {
        this.setState({addEventPopup: true,})
    }
    editPopupModal = (item) => {
        this.setState({
            editPopup: true, editId: item._id,

            badgeName: item.badgeName,
            badgeActive: item.badgeActive,
            badgeInActive: item.badgeInActive
        })
    }
    deletePopupModal = (item) => {
        this.setState({
            deletePopup: true, editId: item._id,
        })
    }
    sendToParent = (e) => {
        console.log(e, "xxxxxxxxxxxxxxxxx3")
        this.setState({loader: true})
        this.getEventInfo()
    }
    changeName = (event) => {
        const self = this;
        // console.log(event.target.value, "xxxxxxxxxxxxxxxxx")
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: event.target.value,
            typing: false,
            pageNo: 1,

            typingTimeout: setTimeout(function () {
                self.sendToParent(self.state.name);
            }, 1000)
        })


    }
    handleClose = () => {
        this.setState({addEventPopup: false, editPopup: false, deletePopup: false,})
    }
    handlePageChange = (pageNo) => {

        var self = this;
        this.setState({loader: false, page: pageNo, limit: 15}, () => {
            self.getEventInfo();
        });
    }

    ConvertDate = (created) => {
        let data = moment(created).format("MM/DD/YYYY")
        return data
    }
    handleSort = sortField => this.setState({
        loader: true,
        sortField,
        sortType: this.state.sortType === 1 ? -1 : 1
    }, () => this.getEventInfo())

    render() {
        let {
            eventList, deletePopup, inactive, active, errors,
            eventNameErrorMsg, venueIdErrorMsg, starTimeErrorMsg, endTimeErrorMsg,
            ticketCapacityErrorMsg, endDateErrorMsg, startDateErrorMsg, descriptionErrorMsg,
            skipLineAmountErrorMsg, earlyBirdAmountErrorMsg, ticketAdmissionAmountErrorMsg,
            eventTypeErrorMsg,addressErrorMsg,profileCoverPhotosPreview,profileCoverPhotosErrorMsg,
            venueId, eventName, hostName, description, eventType, startDate, endDate,
            startTime, endTime, lat, long, address, city, state, country, zipCode, ticketCapacity,
            ticketAdmissionAmount, earlyBirdAmount, skipLineAmount, hostImage,
            selectFreeRadio, selectPaidRadio, limit, page, totalCount, dataCount, profilePicPreview
        } = this.state;

        return (
            <>
                <Sidebar/>
                <div className="body_container">
                    <div className=" ">
                        <div className="row mb-3">
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="count-list">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h6 className="mb-2">Total Events</h6>
                                                    <h6 className="mb-0">{totalCount}</h6>
                                                </div>
                                                <div>
                                                        <span className="user-icon icon-blue">
                                                            <UserIcon/>
                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 offset-3 header_search header_search_custom1">
                                <div className="form-group has-search ">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    {/*<Form.Control type="Search" placeholder="Search..." name="search" value={"search"}></Form.Control>*/}
                                    <Form.Control type="text" placeholder="Search" name={"search"}
                                                  onChange={this.changeName}
                                        // onKey/Down={this.handleKeyDown}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="body_inner_wrapper">
                            {this.state.loader ? <div className="loader_wrapper">
                                    <div className="spinner-border text-info" role="status"></div>
                                </div> :
                                (<>
                                    <header className="header_content">
                                        <p>Events</p>


                                        {/*<div className="d-flex text-nowrap header_btn">*/}
                                        {/*    <Button onClick={this.addPopupModal}*/}
                                        {/*            variant="warning"*/}
                                        {/*            className="changepassword">*/}
                                        {/*        Add Events*/}
                                        {/*    </Button>*/}

                                        {/*</div>*/}

                                    </header>


                                    <div className="main_content">
                                        <div className="table-responsive">
                                            <table className="table cm_bottom_space">
                                                <thead>
                                                <tr className="broder_top_0">
                                                    <th scope="col" className="ws-nowrap">S. No.</th>
                                                    <th scope="col" onClick={() => this.handleSort('createdDate')}>Created
                                                        On<i
                                                            className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                    </th>
                                                    <th scope="col" onClick={() => this.handleSort('eventName')}>Event
                                                        Name<i
                                                            className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                    </th>


                                                    <th scope="col" > Business
                                                        User I<i
                                                            className=" text_dark ml-2 d-inline-block"></i>
                                                    </th>
                                                    <th scope="col" > Venue Data<i
                                                        className=" text_dark ml-2 d-inline-block"></i></th>
                                                    <th scope="col" className="ws-nowrap"> Description</th>
                                                    <th scope="col" className="ws-nowrap"> Event Image</th>
                                                    <th scope="col" className="ws-nowrap"> Ticket Admission Amount</th>
                                                    <th scope="col" className="ws-nowrap"> Ticket Capacity</th>
                                                    <th scope="col" className="ws-nowrap"> Address</th>
                                                    <th scope="col" className="ws-nowrap"> City</th>
                                                    <th scope="col" className="ws-nowrap"> State</th>
                                                    {/*<th scope="col" className="ws-nowrap"> country</th>*/}
                                                    {/*<th scope="col" className="ws-nowrap"> zipCode</th>*/}
                                                    <th scope="col" className="ws-nowrap"> Status</th>


                                                </tr>
                                                </thead>
                                                <tbody>

                                                {!_.isEmpty(eventList) && eventList.map((item, i) => (
                                                    <tr key={item._id}>
                                                        <td>{((limit * (page - 1)) + (i + 1))}</td>
                                                        <td>{this.ConvertDate(item.createdDate)}</td>
                                                        <td>{item.eventName}</td>
                                                        <td>
                                                            {/*{item.businessData?item.businessData.firstName:''}*/}
                                                            <Link
                                                                to={`business-info/${item.businessData._id}`}>{item.businessData.firstName} {item.businessData.lastName}</Link>
                                                        </td>
                                                        <td>{item.venueData ? item.venueData.businessName : ''}</td>
                                                        <td>{item.description}</td>

                                                        <td>{item.eventImage ? <img height="40" width="40"
                                                                                    src={item.eventImage}/> : ''}</td>
                                                        <td>{item.ticketAdmissionAmount}</td>
                                                        <td>{item.ticketCapacity}</td>
                                                        <td className="tdRow">{item.address}</td>
                                                        <td>{item.city ? item.city : ''}</td>
                                                        <td>{item.state ? item.state : ''}</td>

                                                        <td>{item.status==1?"Active":"Inactive"}
                                                            {/*<select value={item.userStatus} disabled={true}*/}
                                                            {/*        name="userStatus"*/}
                                                            {/*        // onChange={(e)=>this.handleDelete(e,item)}*/}
                                                            {/*        className="form-control-sm">*/}
                                                            {/*    /!*<option value=''>Select</option>*!/*/}
                                                            {/*    <option value='1'>Active</option>*/}
                                                            {/*    <option value='2'>Inactive</option>*/}

                                                            {/*</select>*/}
                                                            {/*<span> <i className="fas fa-pen"*/}
                                                            {/*          onClick={() => this.editPopupModal(item)}*/}
                                                            {/*></i></span>*/}
                                                            {/*<span> <i className="fas fa-trash text-danger"*/}
                                                            {/*          onClick={() => this.deletePopupModal(item)}></i></span>*/}
                                                        </td>
                                                    </tr>))
                                                }


                                                </tbody>


                                            </table>

                                            {eventList && eventList.length ?
                                                <div
                                                    className="d-flex justify-content-end cm-pagination">
                                                    <Pagination
                                                        itemClassPrev={'prevClass'}
                                                        itemClassNext={'nextClass'}
                                                        activePage={this.state.page}
                                                        itemsCountPerPage={this.state.limit}
                                                        totalItemsCount={this.state.totalCount}
                                                        pageRangeDisplayed={3}
                                                        onChange={this.handlePageChange}
                                                    />
                                                </div> : ''}
                                            {_.isEmpty(this.state.eventList) ?
                                                <h3 className="text-center">No data found</h3> : ''}
                                            {/*{reasonErMsg ?*/}
                                            {/*    <div className="text-center error ">{reasonErMsg}</div> : ''}*/}
                                        </div>


                                    </div>


                                </>)}


                        </div>


                    </div>
                    <Modal aria-labelledby="example-modal-sizes-title-sm "
                           show={this.state.addEventPopup}
                           onHide={this.handleClose}
                           className="cm_order_view order-detailsmodal">
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm"><h6>Add Event</h6></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <form onSubmit={this.addEvents}>
                                    <Row>

                                        <Col md="2">
                                            <p>Banner Image:</p>

                                        </Col>

                                        <Col md="6">
                                            <div className="u_img mb-5">
                                                {profileCoverPhotosPreview ?
                                                    <img
                                                        className="rounded-circle1"
                                                        src={profileCoverPhotosPreview}
                                                        className="logoroundimg"/> :
                                                    <span
                                                        className="profile_icon_Admin ">
                                                                                            <img
                                                                                                src={"https://ding-dev.s3.amazonaws.com/badgeActive_1634720520734_placeholder.png"}
                                                                                                className="logoroundimg"/></span>}
                                                <span className="img_icon">
                                                                                        <span className="file_input">
                                                                                            <input type="file"
                                                                                                   className="cm_hide_input"
                                                                                                   accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                                                                                   onChange={this.handlePicUpload}/>
                                                                                        </span>
                                                                                        <img
                                                                                            src={require('../../assets/img/imgicon.png')}/>
                                                                                    </span>
                                            </div>
                                            {profileCoverPhotosErrorMsg ?
                                                <div
                                                    className="error">{profileCoverPhotosErrorMsg}</div> : null}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="2">
                                            <p>Event Name:</p>

                                        </Col>
                                        <Col md="4">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="eventName"
                                                          value={eventName}
                                                          placeholder="Event Name"/>
                                            {eventNameErrorMsg ?
                                                <div
                                                    className="error">{eventNameErrorMsg}</div> : null}
                                        </Col>


                                        <Col md="2">
                                            <p>Description:</p>

                                        </Col>
                                        <Col md="4">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="description"
                                                          value={description}
                                                          placeholder="Description"/>
                                            {descriptionErrorMsg ?
                                                <div
                                                    className="error">{descriptionErrorMsg}</div> : null}
                                        </Col>



                                    </Row>


                                    <Row className="mb-sm-3">

                                        <Row>

                                            <Col md="2">
                                                <p>Venue Type
                                                </p>

                                            </Col>
                                            <Col md="4">

                                                <Select
                                                    mode={"multiple"}
                                                    // style={{ width: '100%' }}
                                                    // inputMode={"multiple"}
                                                    name="venueId"
                                                    onDeselect={this.deSelectVenues}
                                                    onSelect={this.handleChangedVenues}
                                                    className="">


                                                    <Option key="Food">Food</Option>
                                                    <Option key="Drinks">Drinks</Option>
                                                    <Option key="Games">Games</Option>
                                                    <Option key="Music">Music</Option>
                                                    <Option key="Hookah">Hookah</Option>
                                                    <Option key="Indoor">Indoor</Option>
                                                    <Option key="Outdoor">Outdoor</Option>
                                                    <Option key="Booths/Sections">Booths/Sections</Option>


                                                </Select>
                                                {venueIdErrorMsg ?
                                                    <div
                                                        className="error">{venueIdErrorMsg}</div> : null}
                                            </Col>


                                        </Row>
                                        <Col lg="2" md="3">
                                            {/*<p>Pick Up / Delivery:</p>*/}
                                            <p> Event Type:</p>

                                        </Col><>
                                        <Col lg="2" md="3">
                                            <label className="pt-3"><input type="radio" id="selectFreeRadio"
                                                                           value={selectFreeRadio}
                                                                           onChange={this.handleRadio}
                                                                           name="eventType"/>


                                                Free:</label>
                                        </Col>

                                        <Col lg="2" md="3">
                                            <label className="pt-3">
                                                <input type="radio" id="selectPaidRadio" name="eventType"
                                                       value={selectPaidRadio} onChange={this.handleRadio}/>

                                                Paid:</label>
                                        </Col>
                                        {eventTypeErrorMsg ?
                                            <div
                                                className="error errType">{eventTypeErrorMsg}</div> : null}
                                    </>
                                    </Row>
                                    <Row>


                                        <Col md="2">
                                            <p>Start date:</p>

                                        </Col>
                                        <Col md="4">
                                            <DatePicker
                                                allowClear={false}
                                                value={startDate ? moment(startDate) : ''}
                                                defaultValue={moment(startDate) ? moment(startDate) : 'Select start date'}
                                                className="form-control"
                                                onChange={this.handleDateChange}
                                                // format={dateFormat}

                                            />
                                            {startDateErrorMsg ?
                                                <div
                                                    className="error">{startDateErrorMsg}</div> : null}
                                        </Col>
                                        <Col md="2">
                                            <p>Start Time:</p>

                                        </Col>
                                        <Col md="4">
                                            <DatePicker
                                                allowClear={false}
                                                value={startTime ? moment(startTime) : ''}
                                                defaultValue={moment(startTime) ? moment(startTime) : 'Select start time'}
                                                className="form-control"
                                                onChange={this.handleDateChange}
                                                // format={dateFormat}

                                            />
                                            {starTimeErrorMsg ?
                                                <div
                                                    className="error">{starTimeErrorMsg}</div> : null}
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col md="2">
                                            <p>

                                                End date:</p>

                                        </Col>


                                        <Col md="4">
                                            <DatePicker
                                                allowClear={false}
                                                value={endDate ? moment(endDate) : ''}
                                                defaultValue={moment(endDate) ? moment(endDate) : 'Select end date'}
                                                className="form-control"
                                                onChange={this.handleDateChange}
                                                // format={dateFormat}

                                            />
                                            {endDateErrorMsg ? <div className="error">{endDateErrorMsg}</div> : null}
                                        </Col>
                                        <Col md="2">
                                            <p>

                                                End Time:</p>

                                        </Col>


                                        <Col md="4">
                                            <DatePicker
                                                allowClear={false}
                                                value={endTime ? moment(endTime) : ''}
                                                defaultValue={moment(endTime) ? moment(endTime) : 'Select end time'}
                                                className="form-control"
                                                onChange={this.handleDateChange}
                                                // format={dateFormat}

                                            />
                                            {endTimeErrorMsg ? <div className="error">{endTimeErrorMsg}</div> : null}
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <p>Ticket Capacity
                                            </p>

                                        </Col>
                                        <Col md="4">
                                                <Form.Control type="Number"
                                                              onChange={this.handleChanged}
                                                              name="ticketCapacity"
                                                              value={ticketCapacity}
                                                              placeholder="Ticket capacity"/>
                                                {ticketCapacityErrorMsg ?
                                                    <div
                                                        className="error">{ticketCapacityErrorMsg}</div> : null}

                                        </Col>

                                        <Col md="2">
                                            <p>Ticket Admission Amount
                                            </p>

                                        </Col>
                                            <Col md="4">
                                                <Form.Control type="Number"
                                                              onChange={this.handleChanged}
                                                              name="ticketAdmissionAmount"
                                                              value={ticketAdmissionAmount}
                                                              placeholder="Ticket admission amount"/>
                                                {ticketAdmissionAmountErrorMsg ?
                                                    <div
                                                        className="error">{ticketAdmissionAmountErrorMsg}</div> : null}

                                        </Col>


                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <p>Early Bird Amount
                                            </p>

                                        </Col>
                                        <Col md="4">
                                                <Form.Control type="Number"
                                                              onChange={this.handleChanged}
                                                              name="earlyBirdAmount"
                                                              value={earlyBirdAmount}
                                                              placeholder="Early bird amount"/>
                                                {earlyBirdAmountErrorMsg ?
                                                    <div
                                                        className="error">{earlyBirdAmountErrorMsg}</div> : null}

                                        </Col>

                                        <Col md="2">
                                            <p>Skip Line Amount
                                            </p>

                                        </Col>
                                        <Col md="4">
                                                <Form.Control type="Number"
                                                              onChange={this.handleChanged}
                                                              name="skipLineAmount"
                                                              value={skipLineAmount}
                                                              placeholder="Skip line amount"/>
                                                {skipLineAmountErrorMsg ?
                                                    <div
                                                        className="error">{skipLineAmountErrorMsg}</div> : null}

                                        </Col>


                                    </Row>
                                    <Row>


                                        <Col md="2">
                                            <p>address
                                            </p>

                                        </Col>
                                        <Col md="6">
                                            <Form.Control type="textarea"
                                                          onChange={this.handleChangedAddress}
                                                          name="address"
                                                          value={address}

                                                          placeholder="address"/>
                                            {addressErrorMsg ? <div className="error">{addressErrorMsg}</div> : null}
                                        </Col>


                                    </Row>


                                    {/*{errors ? errors : ''}*/}
                                    {errors ? <div className="error">{errors}</div> : null}

                                    <Row>
                                        <Col md="10" className="text-sm-right">
                                            <Button type="submit"
                                                    variant="warning"
                                                    className="mr-2">Save</Button>
                                            &nbsp;

                                            <Button onClick={this.handleClose}
                                                    variant="dark">Cancel</Button>


                                        </Col>
                                    </Row>


                                </form>

                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal aria-labelledby="example-modal-sizes-title-sm "
                           show={this.state.editPopup}
                           onHide={this.handleClose}
                           className="cm_order_view order-detailsmodal">
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm"><h6>Edit Badge</h6></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <form onSubmit={this.editBadges}>

                                    <Row>


                                        <Col md="9">
                                            <div className="u_img mb-5">
                                                {profilePicPreview ?
                                                    <img
                                                        className="rounded-circle1"
                                                        src={profilePicPreview}
                                                        className="logoroundimg"/> :
                                                    <span
                                                        className="profile_icon_Admin ">
                                                                                            <img
                                                                                                src={profilePicPreview}
                                                                                                className="logoroundimg"/></span>}
                                                <span className="img_icon">
                                                                                        <span className="file_input">
                                                                                            <input type="file"
                                                                                                   className="cm_hide_input"
                                                                                                   accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                                                                                   onChange={this.handlePicUpload}/>
                                                                                        </span>
                                                                                        <img
                                                                                            src={require('../../assets/img/imgicon.png')}/>
                                                                                    </span>
                                            </div>
                                            {profilePicPreview ?
                                                <div
                                                    className="error">{profilePicPreview}</div> : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="10" className="text-sm-right">
                                            <Button type="submit"
                                                    variant="warning"
                                                    className="mr-2">Save</Button>
                                            &nbsp;

                                            <Button onClick={this.handleClose}
                                                    variant="dark">Cancel</Button>


                                        </Col>
                                    </Row>


                                </form>

                            </div>
                        </Modal.Body>

                    </Modal>

                    <Modal show={deletePopup} onHide={this.handleClose} className="cm_order_view dlt_item">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src={require('../../assets/img/Delete-Email.png')}/>
                            <br/>
                            <br/>
                            <p>Are you sure you want to delete?</p>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="footer_btn_wrapper">
                                <Button variant="warning" onClick={(e) => this.handleDelete()}>
                                    Delete
                                </Button>
                                {/*<span><i onClick={(e)=>this.handleDelete(e,item)} class="fas fa-trash-alt"></i></span>*/}

                                <Button variant="dark" onClick={this.handleClose}>
                                    Cancel
                                </Button>
                            </div>

                        </Modal.Footer>
                    </Modal>

                </div>

            </>
        )
    }

}


function mapStateToProps(state) {
    const {props, login, user, mapReducer} = state;
    return {
        props,
        login,
        user,
        mapReducer
    }
}

export default connect(mapStateToProps)(Events);
// export default Settings
