import React, {Component} from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import Sidebar from "../common/sidebar";
import {usersActions} from "../../_actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import _ from 'lodash';
import moment from "moment-timezone";
function toFeet(n) {
    console.log(n,"nnnnnnnnnnnnnnnnfeet")
   let   index = n.slice(2,4)
    console.log(index,"qqqnnnnnnnnnnnnnnnnfeet")

    var realFeet = ((n*0.393700) / 12);
    // var feet = Math.floor(n);
    // var inches = n - Math.floor(n)
    var feet  = Math.floor(n); // Change to positive
    var inches = n -(feet)
    console.log(inches,"jhgfcxSsssss")
    console.log(Math.round(inches),"Ssssss")
     inches = inches.toFixed(2)
     inches = (inches*10)
    // var inches = Math.round((realFeet - feet) * 12);

    console.log(feet,"nnnnnnnnnnnnnnnnfeet1111111",)
    console.log(feet,"nnnnnnnnnnnnnnnnfeet1111111",inches)

    return feet + " feet " + (index) + ' inches ';
}


class UserDetails extends Component {

    constructor(props) {
        super(props);


        this.state = {

            errors: '',
            success: '',
            userInfo: [],
            isEdit: false,
            farmImagePreview: '', farmImageErrorMsg: '', farmMediaErrorMsg: '', farmImage: [],
            farmLogoPreview: '', farmLogo: '', farmLogoErrorMsg: '',
            farmVideoPreview: '', farmVideo: '', farmVideoErrorMsg: '',
            userId: localStorage.getItem('userId'),
            farmName: '', farmStatus: '', shortInfo: '', infoAboutFarm: '', infoAboutFarmer: '', description: '',
            farmId: '',
            userProfile: [],
            farmMedia: [],
            farmImage1Preview: [],
            media: [],
            valiDate: true, loader: false,

            address: '',
            addressLine1: '',
            addressLine1ErrorMsg: '',
            addressLine2: '',
            state: '',
            postalCode: '',
            city: '',
            lat: 0,
            lng: 0

        }

    }

    componentDidMount() {
        this.getUserDetails()

    }


    ConvertDob = (dob) => {
        // let data =        Date(dob)

        var data = new Date(dob );
        // let year = data.getFullYear(); // 2020
        console.log(dob, "ssssssssssssssccccccccccccc")


        let data2 = moment(data).format("MM/DD/YYYY")
        return data2
    }
    checkInterest = (entertainments) => {
        console.log(entertainments, "saasssssssssssssccccccccccccc")

        // let data =        Date(dob)
        let a = ''
        !_.isEmpty(entertainments) && entertainments.map((val, i = 0) => {
            console.log(val)
            console.log(val, "saasssssssssssssccccccccccccc")
            return a = a + val + ','
        })

        return a
    }


    getUserDetails = () => {
        let {userId, farmLogo, farmImage, farmVideo, farmLogoPreview, farmVideoPreview, farmName, farmStatus, infoAboutFarm, infoAboutFarmer, description} = this.state;
        this.setState({errors: ''});

        let self = this
        this.setState({loader: true});
// let userId=
        console.log(this.props, "iuytrssssssssss")
        let userType=1
        localStorage.setItem("userType",2)

        if(this.props.match.path==="/business-info/:id"){
            userType=2
            localStorage.setItem("userType",2)
        }else {
            localStorage.setItem("userType",1)

        }
        let params = `?userId=${this.props.match.params.id}&userType=${userType}`

        let {dispatch} = this.props;
        dispatch(usersActions.getUserDetail(params)).then(data => {
            if (!_.isEmpty(data)) {
                // console.log("jkhgjhfgdxf",self.props.farm)
                let userProfile = data.responseData.userProfile
                self.setState({loader: false})

                if (data.statusCode == 1) {
                    console.log("muuuuuuuuuuuuuuuuuuuuuu", userProfile)

                    self.setState({
                        userProfile: userProfile,

                    })
                } else {
                    self.setState({errors: data.error.responseMessage})
                }
            }

        });

    }

    onKeyPress(e) {
        e = e || window.event;
        var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        var charStr = String.fromCharCode(charCode);

        if (!charStr.match(/^[0-9]+$/))
            e.preventDefault();
    }
    checkVenues = (interest) => {
        console.log(interest, "saasssssssssssssccccccccccccc")

        // let data =        Date(dob)
        let a = ''
        !_.isEmpty(interest) && interest.map((val, i = 0) => {
            console.log(val)
            console.log(val, "saasssssssssssssccccccccccccc")
            return a = a + val + ','
        })

        return a
    }

    render() {
        let {
            shortInfo, userProfile, media, farmMediaErrorMsg, farmImage1Preview,
            farmLogoPreview, farmLogoErrorMsg, farmNameErrorMsg, infoAboutFarmErrorMsg, infoAboutFarmerErrorMsg, addressLine1ErrorMsg
            , farmName, infoAboutFarm, infoAboutFarmer


        } = this.state;

        return (
            <>
                <Sidebar/>


                <div>



                    <div className="body_container">
                        <nav aria-label="breadcrumb">
                            {localStorage.getItem("userType")  ==2?<ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/users">Business Users</a></li>
                                <li className="breadcrumb-item active"aria-current="page">Business User detail</li>
                                {/*<li className="breadcrumb-item active" aria-current="page">Data</li>*/}
                            </ol>:<ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/users">Users</a></li>
                                <li className="breadcrumb-item active"aria-current="page">User detail</li>
                                {/*<li className="breadcrumb-item active" aria-current="page">Data</li>*/}
                            </ol>}
                        </nav>
                        <div className="body_inner_wrapper">

                            {this.state.loader ? <div className="loader_wrapper">
                                    <div class="spinner-border text-info" role="status"></div>
                                </div> :
                                (<>

                                    <header className="header_content">
                                        <p>User Details</p>
                                    </header>

                                    <div className="main_content">
                                        {!_.isEmpty(userProfile.profileCoverPhotos) ?
                                            <Row>
                                                <Col md="1">
                                                    <p>Profile pic:</p>
                                                </Col>
                                                <Col md="">
                                                    <div className="  file_uploader bg_size "
                                                         style={{backgroundImage: `url(${userProfile.profileCoverPhotos.length ? userProfile.profileCoverPhotos[0].url : ''})`}}>


                                                    </div>


                                                </Col>

                                            </Row>
                                            : ''}
                                        <br/>

                                        <Row>

                                            <Col className="farm_page ">

                                                {userProfile.firstName?  <Row>
                                                    <Col lg="2" md="3">

                                                        <p> Name:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled type="text"
                                                                      name="farmName"
                                                                      value={userProfile ? (userProfile.firstName + ' ' + userProfile.lastName) : ''}
                                                        />

                                                    </Col>
                                                </Row>:''}
                                                {userProfile.email?  <Row>
                                                    <Col lg="2" md="3">
                                                        <p> Email:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled type="text"
                                                                      name="farmName"
                                                                      value={userProfile ? userProfile.email : ''}
                                                        />

                                                    </Col>
                                                </Row>:''}
                                                {   userProfile.mobile?  <Row>
                                                <Col lg="2" md="3">
                                                    <p> Phone:</p>
                                                </Col>
                                                <Col md="7">{console.log(userProfile,"mnbvcxxxxxxxxxxxx")}
                                                    {/*<div className="form-control">{userProfile.countryCode} {userProfile.phone}</div>*/}
                                                    <Form.Control disabled type="text"
                                                                  name="farmName"
                                                                  value={userProfile.mobile ?("+"+ userProfile.countryCode  + ' ' +userProfile.mobile ) : ''}
                                                    />

                                                </Col>
                                            </Row>:''}

                                                {userProfile &&userProfile.businessName?      <Row>


                                                    <Col lg="2" md="3">
                                                        <p>Business Name:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="infoAboutFarm"
                                                                      value={userProfile ? (userProfile.businessName ) : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>:''}

                                                {userProfile && userProfile.gender?  <Row>

                                                    <Col lg="2" md="3">
                                                        <p>Gender:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled type="text"
                                                                      name="shortInfo"
                                                                      value={userProfile && userProfile.gender ? (userProfile.gender == 1 ? "Male" : (userProfile.gender == 2 ? "Female" : (userProfile.gender == 3 ? "Others" : ""))) : ''}
                                                        />
                                                    </Col>
                                                </Row>:''}
                                                {userProfile && userProfile.dob?  <Row>


                                                    <Col lg="2" md="3">
                                                        <p>DOB:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="infoAboutFarm"
                                                                      value={userProfile ? this.ConvertDob(userProfile.dob) : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>


                                                </Row>:''}

                                                {userProfile && userProfile.publicBusinessName?      <Row>


                                                    <Col lg="2" md="3">
                                                        <p>Public Business Name:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="infoAboutFarm"
                                                                      value={userProfile ? (userProfile.publicBusinessName ) : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>:''}

                                                {userProfile && userProfile.height? <Row>
                                                    <Col lg="2" md="3">

                                                        <p>Height:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="infoAboutFarmer"
                                                                      value={userProfile.height  ? toFeet(userProfile.height ): ''}
                                                                      className="form-control"></Form.Control>
                                                    </Col>
                                                </Row>:''}
                                                {userProfile && userProfile.ethnicity? <Row>


                                                    <Col lg="2" md="3">
                                                        <p>ethnicity:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="infoAboutFarm"
                                                                      value={userProfile ? (userProfile.ethnicity ) : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>:''}

                                            </Col>

                                            <Col className="farm_page ">



                                                {userProfile.city ?   <Row>


                                                    <Col lg="2" md="3">
                                                        <p>City:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="smoking"
                                                                      value={userProfile ? userProfile.city : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>:''}

                                                {userProfile.state?    <Row>


                                                    <Col lg="2" md="3">
                                                        <p>State:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="smoking"
                                                                      value={userProfile ? userProfile.state : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>:''}

                                                {userProfile.country?   <Row>


                                                    <Col lg="2" md="3">
                                                        <p>Country:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="smoking"
                                                                      value={userProfile ? userProfile.country : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>:''}
                                                {userProfile.zipCode?  <Row>


                                                    <Col lg="2" md="3">
                                                        <p>zipCode:</p>
                                                    </Col>
                                                    <Col md="7">


                                                        <Form.Control disabled
                                                                      name="smoking"
                                                                      value={userProfile && userProfile.zipCode ? userProfile.zipCode : ''}

                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                        {/*<textarea disabled type="textarea"  rows="4" cols="28"*/}
                                                        {/*              name="infoAboutFarm"*/}
                                                        {/*              value={userProfile ? (this.checkInterest(userProfile.entertainments)) : ''}*/}
                                                        {/*              className="form-control"*/}
                                                        {/*>*/}
                                                        {/*</textarea>*/}
                                                    </Col>
                                                </Row>:''}


                                                {userProfile.address?<Row>

                                                    <Col lg="2" md="3">
                                                        <p>Address:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <textarea className="form-control" disabled type="textarea"
                                                                  name="shortInfo"
                                                                  value={userProfile ?userProfile.address  : ''}
                                                        />
                                                    </Col>
                                                </Row>:''}


                                            </Col>

                                        </Row>


                                        {userProfile.profileCoverPhotos && userProfile.profileCoverPhotos.length > 1 ?
                                            <Row>

                                                <Col md="1">
                                                    <p>Profile cover images:</p>
                                                </Col>

                                                <Col md="10">
                                                    <div
                                                        className="fileupload_wraper"
                                                    >
                                                        {userProfile.profileCoverPhotos && userProfile.profileCoverPhotos.map((value, i) => (


                                                            <div className="file_uploader bg_size"
                                                                 style={{backgroundImage: `url(${value.url})`}}>
                                                            </div>


                                                        ))}
                                                    </div>
                                                </Col>
                                            </Row>
                                            : ''}


                                        <Row>
                                            <Col md="8">
                                                <div className="text-right">
                                                    <Link className="btn btn-warning text-light"
                                                          variant="warning"
                                                          to={`/users`}>Back</Link>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>

                                </>)
                            }
                        </div>
                    </div>

                </div>
            </>)
    }

}

function mapStateToProps(state) {
    const {props, login, user, mapFarmReducer, farm} = state;
    console.log("saeeeeeeeeeeeeeeemapFarmReducer", mapFarmReducer)

    return {
        props,
        login,
        user, mapFarmReducer,
        farm
    }
}

export default connect(mapStateToProps)(UserDetails);
