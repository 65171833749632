import React, {Component} from 'react'
import {Button, Modal, Nav} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
// import { _Api } from "../../services/Api";
// import Url from "../../services/BaseUrl";


class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logout: false,
            showmobile: false
        };

        console.log(props, "Ssssssssssssssss")
    }

    // UNSAFE_componentWillReceiveProps(props) {
    //     let self = this;
    //     if (props !== undefined) {
    //         if (!_.isEmpty(props)) {
    //             console.log(props,"hfdjsdddddddddddd")
    //             if (props.profilePicPreview) {
    //
    //                 self.setState({
    //                     profilePicPreview: props.profilePicPreview? props.profilePicPreview:this.state.profilePicPreview,
    //
    //
    //                 })
    //             }
    //         }
    //     }
    // }


    menushow = () => {
        this.setState({
            showmobile: !this.state.showmobile
        })
    }


    componentDidMount() {

        console.log(this.props, "Sssssssssssssssssd")
        // this.getUserInfo()

    }


    // getUserInfo = () => {
    //     this.setState({ errors: '' });
    //     const { onRefreshEditProfile} = this.props
    //     let self = this
    //     this.setState({ redirect: false, loader: true });
    //
    //
    //     _Api(Url.SUPER_ADMIN_VIEW_PROFILE.method, Url.SUPER_ADMIN_VIEW_PROFILE.url, '',)
    //         .then((data) => {
    //             this.setState({ loader: false })
    //             if (data.statusCode == 1) {
    //                 let userInfo = data.responseData.adminProfile
    //                 this.setState({
    //                     userInfo: userInfo,
    //
    //                     name: userInfo.name,
    //                     phone: userInfo.phone,
    //                     email: userInfo.email,
    //                     address: userInfo.address,
    //                     profilePic: userInfo.profileImage,
    //                     profilePicPreview: userInfo.profileImage,
    //                 })
    //             } else {
    //                 this.setState({ errors: data.error.responseMessage })
    //             }
    //         });
    // }


    Closelogout = () => {
        this.setState({logout: false})
    }
    handleLogoutAdmin = () => {

        this.setState({logoutdeletePopup: true})
        localStorage.removeItem('accessToken');
        window.location.href = '/';

    }

    openPopup = () => {
        this.setState({logout: true})
    }

    render() {
        let {logout, profilePicPreview, showmobile} = this.state
        return (
            <>
                <div className="mobile-trigger-wrapper" onClick={this.menushow}>
                    <span className="mobile-trigger"><i class="fas fa-bars"></i></span>
                </div>
                <div className={showmobile ? 'sidebar open' : 'sidebar'}>
                    <div className="farmerlogowrapper">

                        {/*{profilePicPreview ? <img className="rounded-circle1" src={require('../../assets/img/ding logo .png')} />:*/}
                        <img className="rounded-circle1" src={require('../../assets/img/ding.png')}/>
                        {/*// }*/}
                        <h6>YOVI</h6>
                    </div>

                    <div>
                        <div className="sidebar_menu">
                            <Nav.Item>

                                <NavLink to="/users">
                                    <div className="sidebar-item-icon">
                                        <i className="fa fa-users" aria-hidden="true"></i>
                                    </div>
                                    Users</NavLink>
                            </Nav.Item>
                            <Nav.Item>

                                <NavLink to="/business-user">
                                    <div className="sidebar-item-icon">
                                        <i className="fa fa-address-book" aria-hidden="true"></i>
                                    </div>
                                    Business Users</NavLink>
                            </Nav.Item>
                            {/*<Nav.Item>

                                <NavLink to="/reasons">
                                    <div className="sidebar-item-icon">
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                    </div>
                                    Reasons</NavLink>
                            </Nav.Item>*/}
                            <Nav.Item>

                            <NavLink to="/reports">
                                <div className="sidebar-item-icon">
                                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                </div>
                                Reports</NavLink>
                                <NavLink to="/surveys">
                                    <div className="sidebar-item-icon">
                                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                    </div>
                                    Surveys</NavLink>
                        </Nav.Item>

{/**/}
                            <Nav.Item>

                                <NavLink to="/events">
                                    <div className="sidebar-item-icon">
                                        <i className="fa fa-list" aria-hidden="true"></i>

                                    </div>
                                    Events</NavLink>
                            </Nav.Item>
                            <Nav.Item>

                                <NavLink to="/venues">
                                    <div className="sidebar-item-icon">
                                        <i className="fa fa-city" aria-hidden="true"></i>

                                    </div>
                                    Venues</NavLink>
                            </Nav.Item>

                            <Nav.Item>

                                <NavLink to="/tickets-purchased">
                                    <div className="sidebar-item-icon">
                                        <i className="fa  fa-sticky-note
" aria-hidden="true"></i>

                                    </div>

                                    Tickets</NavLink>
                            </Nav.Item>  <Nav.Item>

                            <NavLink to="/subscription-purchased">
                                <div className="sidebar-item-icon">
                                    <i className="fa fa-clipboard" aria-hidden="true"></i>

                                </div>

                                Subscriptions</NavLink>
                        </Nav.Item>
 <Nav.Item>

                                <NavLink to="/settings">
                                    <div className="sidebar-item-icon">
                                        <i className="fa fa-cog" aria-hidden="true"></i>

                                    </div>
                                    Settings</NavLink>
                            </Nav.Item>


                        </div>
                        <div className="sidebar_button">
                            <Button variant="info" onClick={this.openPopup}>Logout</Button>
                        </div>
                    </div>


                </div>

                <Modal show={logout} onHide={this.Closelogout} className="cm_order_view dlt_item">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to logout?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="footer_btn_wrapper button_space">
                            <Button variant="warning" onClick={this.handleLogoutAdmin}>
                                Yes
                            </Button>
                            <Button variant="dark" onClick={this.Closelogout}>
                                No
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>

        );
    }
}

export default Sidebar;
