import siteSetting from "./env";

export default {
    'AUTH': 'Basic ZGluZzpkaW5nQDEyMw==',

    'LOGIN': siteSetting.api.url + "admin/login",
    'FORGOT': siteSetting.api.url + "admin/forgotPassword",
    'RESET': siteSetting.api.url + "admin/resetPassword",
    'GET_USER': siteSetting.api.url + "admin/getUserList",
    'GET_REASONS': siteSetting.api.url + "reason/getReason",
    'GET_ADDRESS': siteSetting.api.url + "venue/getAllVenueForAdmin",
    'GET_ADMIN_DETAIL': siteSetting.api.url + "admin/getAdminInfo",
    'GET_BADGES': siteSetting.api.url + "badge/getAllBadges",
    'CHANGE_PASSWORD': siteSetting.api.url + "admin/changePassword",
    'ADD_BADGES': siteSetting.api.url + "badge/addBadges",
    'EDIT_BADGES': siteSetting.api.url + "badge/editBadges",
    'DELETE_BADGES': siteSetting.api.url + "badge/deleteBadges",
    'EDIT_PROFILE': siteSetting.api.url + "admin/editProfile",
    'DELETE_USER': siteSetting.api.url + "admin/blockUser",
    'GET_USER_DETAIL': siteSetting.api.url + "admin/getUserDetailForAdmin",
    'GET_TICKET_PURCHASES': siteSetting.api.url + "payment/getAdminUserTicketInfo",
    'GET_SUSCRIPTION_PURCHASES': siteSetting.api.url + "subscription/getAdminUserSubscriptionPurchases",



    'GET_REPORTS': siteSetting.api.url + "report/getReports",
    'GET_SURVEYS': siteSetting.api.url + "survey/getSurveyListByTypeForAdmin",

    'GET_EVENTS': siteSetting.api.url + "admin/getAllEventsForAdmin",
    'GET_EVENT_DETAILS': siteSetting.api.url + "event/getEventDetails",

    'GET_VENUES': siteSetting.api.url + "admin/getAllFeedsForAdmin",
    'GET_PLACE_DETAIL': siteSetting.api.url + "venue/getPlaceById",
    'GET_NEARBY_PLACES': siteSetting.api.url + "venue/getNearBySearch",
    'GET_VENUE_DETAILS': siteSetting.api.url + "venue/getVenueDetails",
    'ADD_VENUES': siteSetting.api.url + "user/addBusinessVenueByAdmin",
    'CHANGE_VENUE_STATUS': siteSetting.api.url + "venue/changeVenueStatus",
    'DELETE_VENUES': siteSetting.api.url + "venue/deleteVenue",
    'EDIT_VENUES': siteSetting.api.url + "user/editBusinessVenueByAdmin",
    'ADD_SETTING': siteSetting.api.url + "user/editOperation",
    'ADD_EVENTS': siteSetting.api.url + "event/addEventByAdmin",

}
