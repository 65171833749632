import React, {Component} from 'react';
import {Button, Col, Form, Modal, Nav, Row, Tab} from 'react-bootstrap'
import {connect} from "react-redux";
import Sidebar from '../../components/common/sidebar'
// import {grey} from '../../assets/img/grey.png'
import Loader from '../../components/common/Loader'
import {notify} from '../../components/common/Toster'
import {usersActions} from "../../_actions";
import {UserIcon} from "../Svg";
import _ from "lodash";
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";
import {venuesActions} from "../../_actions/venues.actions";
import {Select} from 'antd'
import PlacesAutocomplete, {geocodeByAddress, geocodeByPlaceId, getLatLng} from "react-places-autocomplete";


import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'antd/dist/antd.css';

import Geocode from "react-geocode";
import moment from "moment-timezone";
// import MapView from "./geoLocation";

Geocode.setApiKey("AIzaSyCHDg7kA1feyT5YGL0xHkNfrWDbiujL1BQ");
Geocode.enableDebug();
Geocode.setLanguage("en");
const {Option} = Select;
const openingHoursDefault = [
    {check: false, "day": 1, startTime: 0, endTime: 0},
    {check: false, "day": 2, startTime: 0, endTime: 0},
    {check: false, "day": 3, startTime: 0, endTime: 0},
    {check: false, "day": 4, startTime: 0, endTime: 0},
    {check: false, "day": 5, startTime: 0, endTime: 0},
    {check: false, "day": 6, startTime: 0, endTime: 0},
    {check: false, "day": 7, startTime: 0, endTime: 0}]
console.log(process.env.map_key, "hbgvfcdxcvbnm")
const days = (val) => {
    switch (val) {
        case 1:
            return "Sunday";
            break;
        case 2:
            return "Monday";
            break;
        case 3:
            return "Tuesday";
            break;
        case 4:
            return "Wednesday";
            break;
        case 5:
            return "Thursday";
            break;
        case 6:
            return "Friday";
            break;
        case 7:
            return "Saturday";

    }

}

class Venues extends Component {

    constructor(props) {
        super(props);

        this.state = {
            opn: openingHoursDefault,
            changePic: false,
            emailErrorMsg: '',
            selectDaysRadio: '',
            errors: '',
            venueId: '',
            success: '',
            userInfo: [],
            isEdit: false,
            deletePopup: false,
            profileCoverPhotosPreview1: '',
            editPopup: false,
            addPopup: false,
            userId: localStorage.getItem('userId'),
            firstNameErrorMsg: '',
            loader: false,
            isChangePass: false, search: '',
            page: 1,
            limit: 350,
            sortField: '',
            firstName: '',
            email: '',
            lastName: '',
            countryCode: '',
            mobile: '',

            address: '',
            city: '',
            state: '',
            country: '',
            zipCode: '',

            sortType: -1,
            totalCount: 0,
            publicBusinessName: '',
            businessName: '',
            description: '',
            vat: '',
            openingHours: [],
            searchList: [],
            lat: '',
            long: '',
            currentLat: '',
            currentLong: '',
            entertainments: [],
            socialType: '',
            venues: [],
            socialTypeErrorMsg: '',
            selectMobileRadio: false,
            selectEmailRadio: false,
            created: 1
        }

    }

    componentDidMount() {
        this.getVenueInfo()

    }

    getVenueInfo = () => {
        this.setState({errors: ''});

        let self = this
        let {search, radius, page, limit, sortType, sortField} = this.state;

        let {dispatch} = this.props;
        console.log(page, limit, "dxsssssssssss")
        let params = `?search=${search}&sortField=${sortField}&page=${page - 1}&limit=${limit}&sortType=${sortType}`

        // let params = {search,radius,page,limit,sortType,sortField}
        dispatch(venuesActions.getAllVenues(params)).then(data => {
            this.setState({
                loader: false
            })
            if (data.statusCode == 1) {


                console.log(data.responseData.response, "fjjjjjjjjjffffffffffffffffffff")
                let venueList = data.responseData.response.venues
                let totalCount = data.responseData.response.totalCount
                this.setState({
                    venueList: venueList,
                    totalCount: totalCount,

                })
            } else {
                // this.setState({errors: data.error.responseMessage})
            }
        });
    }


    handleChanged = (e) => {
        this.setState({
            errors: '',
            oldPasswordErrorMsg: '',
            newPasswordErrorMsg: '',
            confirmPasswordErrorMsg: '',
            entertainmentsErrorMsg: '',
            vatErrorMsg: '',
            addressErrorMsg: '',
            businessNameErrorMsg: '',
            firstNameErrorMsg: '',
            publicBusinessNameErrorMsg:'',
            venuesErrorMsg: '',

                [e.target.name]: e.target.value,

        });
    }

    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition);
        } else {
            // x.innerHTML = "Geolocation is not supported by this browser.";
        }
    }

    showPosition = (position) => {
        let lat = position.coords.latitude
        let long = position.coords.longitude;
        this.setState({currentLat: lat, currentLong: long})
        console.log(lat, long, "aaaaaaaaaaa")


        let {dispatch} = this.props;
        let self = this;
        let params = `?name=${this.state.businessName}&lat=${lat}&lat=${long}`
        dispatch(venuesActions.getNearBySearch(params)).then(data => {
            console.log(data, "hgggggggggggggggg")
            let searchList = data.responseData.results
            self.setState({searchList: searchList})

        })
    }
    nearBySeach = (e) => {
        console.log(e.target.value, ">>>>>>>>>>>>>")
        this.setState({
            businessName: e.target.value,
            businessNameErrorMsg: '',
        }, () => {
            this.getLocation();
        });


    }
    handleChangedAddress = (e) => {
        console.log("onchange names", e.target.value)
        let self = this;
        let {address} = this.state
        // if(e.target.name === "addressLine1"){
        //     address1=e.target.value
        // }
        // if(e.target.name === "addressLine2"){
        //     address2=e.target.value
        // }
        //
        // if(e.target.name === "city"){
        //     city1=e.target.value
        // }
        // if(e.target.name === "state"){
        //     state1=e.target.value
        // }
        // if(e.target.name === "postalCode"){
        //     postalCode1=e.target.value
        // }
        // let addr=address1+' '+address1+' '+city1+' '+state1+' '+postalCode1

        if (e.target.name === "address") {
            console.log("onchange namesggggggggggg", address, "jhgf", e.target.value)
            Geocode.fromAddress(e.target.value).then(
                response => {
                    console.log("Geocode", "onchange namesgggggg")

                    const {lat, lng} = response.results[0].geometry.location;

                    self.setState({lat: lat, long: lng})
                    console.log(lat, lng, "onchange nameskjhgfdsasdfghjkjhgfdslkjhgfdfghjklkjhgfdxsdfghjklkjhgf");
                },
                error => {
                    console.error(error);
                }
            );

        }

        this.setState({
            [e.target.name]: e.target.value,
            infoAboutFarmErrorMsg: '',
            infoAboutFarmerErrorMsg: '',
            addressLine1ErrorMsg: '',
            farmNameErrorMsg: ''

        });


    }


    handlePicUpload = (event) => {


        let reader = new FileReader();
        let file = event.target.files[0];

        let {valiDate} = this.state;

        reader.addEventListener("load", () => {
            this.setState({profileCoverPhotosPreview1: reader.result}, function () {
            })
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {


            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {

                this.setState({
                    profileCoverPhotos: event.target.files[0],
                    profileCoverPhotosErrorMsg: ''

                });

            } else {
                valiDate = false;
                this.setState({profileCoverPhotosErrorMsg: 'File type not supported.'})

            }
        }

    }
    handleValidation = () => {
        let validate = true;
        let {
            firstName,
            email,
            mobile,
            socialType,
            profileCoverPhotos,
            address,
            city,
            state,
            country,
            zipCode,
            publicBusinessName,
            businessName, vat, openingHours, entertainments, venues,
            selectEmailRadio,
            selectMobileRadio,
        } = this.state;
        // if (profileCoverPhotos === '' || profileCoverPhotos === undefined) {
        //     validate = false;
        //     this.setState({
        //         profileCoverPhotosErrorMsg: 'Business logo is required.'
        //     })
        // } else {
        //     this.setState({
        //         profileCoverPhotosErrorMsg: ''
        //     })
        // }
        if (firstName === '' || firstName === undefined) {
            validate = false;
            this.setState({
                firstNameErrorMsg: 'First name is required.'
            })
        } else {
            this.setState({
                firstNameErrorMsg: ''

            })
        }
        if (businessName === '' || businessName === undefined) {
            validate = false;
            this.setState({
                businessNameErrorMsg: 'Business name is required.'
            })
        } else {
            this.setState({
                businessNameErrorMsg: ''

            })
        }
        if (publicBusinessName === '' || publicBusinessName === undefined) {
            validate = false;
            this.setState({
                publicBusinessNameErrorMsg: 'Public business name is required.'
            })
        } else {
            this.setState({


            })
        }

        if (address === '' || address === undefined) {
            validate = false;
            this.setState({
                addressErrorMsg: 'Address is required.'
            })
        } else {
            this.setState({
                addressErrorMsg: ''

            })
        }
        // if (vat === '' || vat === undefined) {
        //     validate = false;
        //     this.setState({
        //         vatErrorMsg: 'VAT is required.'
        //     })
        // } else {
        //     this.setState({
        //         vatErrorMsg: ''
        //
        //     })
        // }
        if (_.isEmpty(entertainments)) {
            validate = false;
            this.setState({
                entertainmentsErrorMsg: 'Entertainment is required.'
            })
        } else {
            this.setState({
                entertainmentsErrorMsg: ''
            })
        }
        if (_.isEmpty(venues)) {
            validate = false;
            this.setState({
                venuesErrorMsg: 'Venue is required.'
            })
        } else {
            this.setState({
                venuesErrorMsg: ''
            })
        }



        return validate
    }

    clearSearchList = () => {
        this.setState({searchList: []})
    }
    addVenue = event => {
        event.preventDefault();
        var self = this

        if (this.handleValidation()) {

            let {
                firstName,
                email,
                lastName,
                countryCode,
                mobile,
                socialType,
                profileCoverPhotos,
                stateCodeText,
                countryCodeText,
                address,
                city,
                state,
                country,
                zipCode,
                publicBusinessName, description,
                businessName, vat, openingHours, lat, long, entertainments, venues
            } = self.state

            let obj = new FormData()

            obj.append('firstName', firstName);
            obj.append('lastName', lastName);
            // obj.append('socialType', email ? 1 : 4);
            // obj.append('email', email);
            // obj.append('mobile', mobile);
            obj.append('profileCoverPhotos', profileCoverPhotos ? profileCoverPhotos : "");
            obj.append('publicBusinessName', publicBusinessName);
            obj.append('businessName', businessName);
            obj.append('vat', vat);
            let jhg=[]
            for (var i = 0; i < openingHours.length; i++) {
                obj.append("openingHours[" + i + "].day", openingHours[i].day);
                obj.append("openingHours[" + i + "].startTime", openingHours[i].startTime);
                obj.append("openingHours[" + i + "].endTime", openingHours[i].endTime);
            }
            obj.append('address', address);
            obj.append('description', description);
            obj.append('city', city);
            obj.append('stateCodeText', stateCodeText);
            obj.append('countryCodeText', countryCodeText);
            obj.append('state', state);
            obj.append('country', country);
            obj.append('zipCode', zipCode);
            obj.append('lat', lat);
            obj.append('long', long);
            obj.append('entertainments', entertainments);
            obj.append('venues', venues);

            this.setState({loader: true, errors: '', success: ''})


            let {dispatch} = this.props;
            dispatch(venuesActions.addVenues(obj)).then(data => {
                console.log(data,openingHours,"sssssssjjjjjjjjjjjjjjjjjjjjs")
                // self.setState({loader: false})
                if (data.statusCode == 1) {
                    // openingHours=openingHours.filter(val=>val.check==true)

                    console.log(openingHours,"zxxxxxxxxxsssssssjjjjjjjjjjjjjjjjjjjjs")

                    let obja = {}
                    obja={userId:data.responseData.userProfile._id
                        ,openingHours: openingHours}
                    if(!_.isEmpty(obja)) {
                        dispatch(usersActions.addSetting(obja)).then(data => {

                            self.setState({
                                loader: false,
                                addVenuePopup: false,
                                isEdit: false,
                                success: data.responseData.message,
                                newPassword: '',
                                oldPassword: '',
                                confirmPassword: ''
                            }, () => {
                                this.getVenueInfo()

                            })

                        })
                    }else {
                        self.setState({
                            loader: false,
                            addVenuePopup: false,
                            isEdit: false,
                            success: data.responseData.message,
                            newPassword: '',
                            oldPassword: '',
                            confirmPassword: ''
                        },()=>{
                            this.getVenueInfo()

                        })
                    }

                    }
                else
                    {
                        self.setState({errors: data.error.responseMessage})
                    }
                }
            )


            }
        }
        editVenue = event => {
            event.preventDefault();
            var self = this

            if (this.handleValidation()) {

                let {
                    firstName,
                    email,
                    lastName,
                    countryCode,
                    mobile,
                    socialType,
                    profileCoverPhotos,
                    address,
                    city,
                    stateCodeText,
                    countryCodeText,
                    state,
                    country,
                    zipCode,
                    publicBusinessName,
                    description,
                    businessName, vat, openingHours, lat, long, entertainments, venues, editId
                } = self.state

                let obj = new FormData()
                console.log(openingHours, "kjhgvfcdcfvgbhnjm")
                obj.append('firstName', firstName);
                obj.append('lastName', lastName);
                obj.append('description', description);
                // obj.append('socialType', email ? 1 : 4);
                // obj.append('email', email);
                // obj.append('mobile', mobile);
                obj.append('profileCoverPhotos', profileCoverPhotos ? profileCoverPhotos : "");
                obj.append('publicBusinessName', publicBusinessName);
                obj.append('businessName', businessName);
                obj.append('countryCodeText', countryCodeText);
                obj.append('stateCodeText', stateCodeText);
                obj.append('vat', vat);
                obj.append('userId', editId);
                // obj.append('userId', editId);
                // obj.append('openingHours', JSON.stringify(openingHours));

                // for (var i = 0; i < openingHours.length; i++) {
                //     obj.append("openingHours[" + i + "].day", openingHours[i].day);
                //     obj.append("openingHours[" + i + "].startDate", openingHours[i].startTime);
                //     obj.append("openingHours[" + i + "].endDate", openingHours[i].endTime);
                // }
                obj.append('address', address);
                obj.append('city', city);
                obj.append('state', state);
                obj.append('country', country);
                obj.append('zipCode', zipCode);
                obj.append('lat', lat);
                obj.append('long', long);
                obj.append('entertainments', entertainments);
                obj.append('venues', venues);

                this.setState({loader: true, errors: '', success: ''})


                let {dispatch} = this.props;
                dispatch(venuesActions.editVenues(obj)).then(data => {
                    self.setState({loader: false})
                    if (data.statusCode == 1) {
                        // openingHours=openingHours.filter(val=>val.check==true)
                        console.log(openingHours,"zxxxxxxxxxsssssssjjjjjjjjjjjjjjjjjjjjs")

                        let obja = {}
                        obja={userId:data.responseData.userProfile._id
                            ,openingHours: openingHours}
                        dispatch(usersActions.addSetting(obja)).then(data => {})

                        self.setState({
                            loader: false,
                            editPopup: false,
                            isEdit: false,
                            success: data.responseData.message,
                            newPassword: '',
                            oldPassword: '',
                            confirmPassword: ''
                        },()=>{
                            this.getVenueInfo()

                        })
                    } else {
                        self.setState({errors: data.error.responseMessage})
                    }
                })


            }
        }
        handleDelete = () => {
            var self = this

            let {editId} = self.state

            let obj = {}
            obj = `?venueId=${editId}`

            this.setState({loader: true, errors: '', success: ''})


            let {dispatch} = this.props;
            dispatch(venuesActions.deleteVenues(obj)).then(data => {
                self.setState({loader: false})
                if (data.statusCode == 1) {
                    self.setState({
                        loader: false,
                        editPopup: false,
                        deletePopup: false,
                        isEdit: false,
                        success: data.responseData.message,
                        newPassword: '',
                        oldPassword: '',
                        confirmPassword: ''
                    })
                    this.getVenueInfo()
                } else {
                    self.setState({errors: data.error.responseMessage})
                }
            })

        }

        addPopupModal = () => {
            this.setState({addVenuePopup: true,})
        }
        editPopupModal = (item) => {

            console.log(item, "Sadddddddxz")
            if(item.openingHours.length) {
                item.openingHours.map(val => {
if(val.startTime ==0 &&val.endTime ==0 ) {
    val.check = false
}               else{
    val.check = true

}
                })
            }

            let { opn} = this.state;
            console.log(opn,item.openingHours,"xzzzzzzzzzzzz")
            let OpeningHours=[]
            for(let a=0;a<7;a++){
                console.log(item.openingHours,"jhgfddfghjzzzzzzzzzzjhgfddfghj",a)
               if(item.openingHours.length<1) {
                   // const found = item.openingHours.some(el => el.day == a); //today
                   // const found = item.openingHours.some(el => (el.startTime == 0 && el.endTime == 0));
                   // console.log("jhgfddfghjzzzzzzzzzz", !found,a)
                   //
                   // if (!found) {
                   //         console.log(opn[a],"jhgfddfghjzzzzzzzzzzsssssssssszxzxzx")
                   //     OpeningHours.push({
                   //         day: opn[a].day,
                   //         startTime: opn[a].startTime,
                   //         endTime: opn[a].endTime,
                   //         check: false,
                   //     })
                   // }
                   // item.openingHours=OpeningHours

               }else {
                   // const found = item.openingHours.some(el => el.day == a+1);
                   // const found = item.openingHours.some(el => (el.startTime == 0 && el.endTime == 0));
                   //
                   // console.log("jhgfddfghjzzzzzzzzzzbgvfcxzxcvb", found,a+1)
                   //
                   // if (!found) {
                   //     console.log(opn,"jhbgfgfddfghjzzzzzzzzzzsssssssssszxzxzx")
                   //     item.openingHours.push({
                   //         day: opn[a].day,
                   //         startTime: opn[a].startTime,
                   //         endTime: opn[a].endTime,
                   //         check: false,
                   //     })
                   // }
               }


            }
            console.log("jhgfddfghjsssssssssssssssssssssszxs",item.entertainments)

            item.entertainments=[...new Set(item.entertainments)]
            console.log("jhgfddfghjsssssssssssssssssssssszxs",item.entertainments)

            // item.entertainments
            console.log(OpeningHours, "Sadddddddxzaaaaaa")

            this.setState({
                editPopup: true, editId: item.businessUser._id,
                city: item.city,
                country: item.country,
                state: item.state,
                venueId: item.businessUser ? item.userId : '',
                firstName: item.businessUser ? item.businessUser.firstName : '',
                lastName: item.businessUser ? item.businessUser.lastName ? item.businessUser.lastName : '' : '',                profileCoverPhotos: item.profileCoverPhotos.length ? item.profileCoverPhotos[0].url : 'https://dingstag.s3.amazonaws.com/1648810989444.jpeg',
                profileCoverPhotosPreview: item.profileCoverPhotos.length ? item.profileCoverPhotos[0].url : 'https://dingstag.s3.amazonaws.com/1648810989444.jpeg',
                profileCoverPhotosPreview1: item.profileCoverPhotos.length ? item.profileCoverPhotos[0].url : 'https://dingstag.s3.amazonaws.com/1648810989444.jpeg',
                address: item.address,
                publicBusinessName: item.publicBusinessName,
                description: item.description,
                businessName: item.businessName,
                vat: item.vat,
                zipCode: item.zipCode,
                openingHours: item.openingHours,
                lat: item.geoLocation && item.geoLocation.length ? item.geoLocation[1] : '',
                long: item.geoLocation && item.geoLocation.length ? item.geoLocation[0] : '',
                entertainments: item.entertainments,
                venues: item.venues,

            })
        }
        deletePopupModal = (item) => {
            this.setState({
                deletePopup: true, editId: item._id,
            })
        }
        handleClose = () => {
            this.setState({

                vatErrorMsg: '',

                addressErrorMsg: '',

                businessNameErrorMsg: '',
                firstNameErrorMsg: '',
                profileCoverPhotosErrorMsg: '',
                profileCoverPhotosPreview1: '',
                publicBusinessNameErrorMsg: '',
                venuesErrorMsg: '',
                entertainmentsErrorMsg: '',
                addVenuePopup: false, editPopup: false, deletePopup: false,
                firstName: '',
                email: '',
                lastName: '',
                countryCode: '',
                mobile: '',
                description: '',
                socialType: '',
                placeDetail: '',
                profileCoverPhotos: '',
                address: '',
                city: '',
                state: '',
                country: '',
                zipCode: '',
                publicBusinessName: '',
                businessName: '', vat: '', openingHours: [], lat: '', long: '', entertainments: [], venues: [],
            })
        }

        handleChangedEntertaimment = (id) => {
            let {entertainments} = this.state;
            console.log(entertainments, "011111111", id)

            entertainments.push(id.toString())
            console.log(entertainments, "I11111111")
            entertainments = entertainments.filter(function (item, index, inputArray) {
                console.log(item, "item", index, "index", inputArray, "arinp", "2211111111")
                console.log(inputArray.indexOf(item), 'ssssssssss11111111')
                return inputArray.indexOf(item) == index;
            });

            console.log(entertainments,"cxxxxxxxxxxxxxxx")
            entertainments = [...new Set(entertainments)]
            console.log(entertainments,"cxxxxxxxxxxxxxxxaaaaaaaaaa")

            this.setState({entertainments: entertainments})
        }
        deSelectEntertaimment = (e) => {

            let {entertainments} = this.state;
            console.log(e, "eeeeeeeeeeeee11111", entertainments)
            // ExamIds = ExamIds.filter(itm => (itm.toString() !==e.toString()))

            entertainments = entertainments.filter(function (item) {

                console.log("eeeeeeeeeeeee11111111", item, "eeeeeeeeeeeee", item !== e, "eeeeeeeeeeeee", item, "eeeeeeeeeeeee", e)
                return item !== e
            })
            this.setState({entertainments: entertainments})
            console.log(e, "eeeeeeeeeeeee2111111111", entertainments)

        }
        /*
            handleChangeOpnHrs = (e, i) => {
                let {openingHours} = this.state;

                console.log(e.target.name, e.target.value, i, openingHours[i], "cxxxxxxxx")
                if (e.target.name == "day") {
                    openingHours[i].day = e.target.value
                }
                if (e.target.name == "startTime") {
                    console.log(e.target.name, openingHours, "dssssssssssssssssssss")

                    openingHours[i].startTime = e.target.value
                }
                if (e.target.name == "endTime") {
                    openingHours[i].endTime = e.target.value
                }
                this.setState({openingHours: openingHours})
                console.log(openingHours[i], "dssssssssssssssssssss")
                // let {venues} = this.state;
                // console.log(venues, "011111111", id)
                //
                // venues.push(id.toString())
                // console.log(venues, "I11111111")
                // venues = venues.filter(function (item, index, inputArray) {
                //     console.log(item, "item", index, "index", inputArray, "arinp", "2211111111")
                //     console.log(inputArray.indexOf(item), 'ssssssssss11111111')
                //     return inputArray.indexOf(item) == index;
                // });
                // venues = [...new Set(venues)]
                // this.setState({venues: venues})
            }
        */
        handleChangedVenues = (id) => {
            let {venues} = this.state;
            console.log(venues, "011111111", id)

            venues.push(id.toString())
            console.log(venues, "I11111111")
            venues = venues.filter(function (item, index, inputArray) {
                console.log(item, "item", index, "index", inputArray, "arinp", "2211111111")
                console.log(inputArray.indexOf(item), 'ssssssssss11111111')
                return inputArray.indexOf(item) == index;
            });
            venues = [...new Set(venues)]
            this.setState({venues: venues})
        }
        deSelectVenues = (e) => {

            let {venues} = this.state;
            console.log(e, "eeeeeeeeeeeee11111", venues)
            // ExamIds = ExamIds.filter(itm => (itm.toString() !==e.toString()))

            venues = venues.filter(function (item) {

                console.log("eeeeeeeeeeeee11111111", item, "eeeeeeeeeeeee", item !== e, "eeeeeeeeeeeee", item, "eeeeeeeeeeeee", e)
                return item !== e
            })

            console.log(venues,"cxxxxxxxxxxxxxxxx")
            this.setState({venues: venues})
            console.log(e, "eeeeeeeeeeeee2111111111", venues)

        }

        handleRadio = (e, i) => {
            console.log(e.target.id, e.target.value, i, e.target.checked, "vcxcvbnm")
            let {openingHours, opn} = this.state;

            if (!e.target.checked) {
                console.log("ddddddddddeeeeeeeeeeeee11111111")
                // opn = opn.filter(function (item) {
                // //
                //     console.log("eeeeeeeeeeeee11111111", item, "eeeeeeeeeeeee", item !== e, "eeeeeeeeeeeee", item, "eeeeeeeeeeeee", e)
                // //     return item !== e
                // opn.splice(item, 1);
                //     })
                console.log(i, "11111111111111111111111111111111111")
                // const index = opn.indexOf(i);
                console.log(i, "11111111111111111111111111111111111ssssssssss")

                // if (i > -1) {
                //     opn.splice(i, 1); // 2nd parameter means remove one item only
                // }


            } else {


                this.state.opn.push({
                    day: i,

                })
            }
            console.log(this.state.opn, "11111111111111111111111111111111111ddddddddddeeeeeeeeeeeee11111111xxxxxxxxxxxx")

            this.setState({
                selectMobileRadio: true,
                socialType: 4,
                selectEmailRadio: false,
                selectPickRadioErrorMsg: ''
            })

        }
        handleChangeOpnHrs = (e, i) => {
            console.log(e.target.name, e.target.id, e.target.value, i, e.target.checked, "111ddddddddddvcxcvbnm")
            let {openingHours, opn} = this.state;
            console.log(i, e.target.checked == true, "111dd11111111111111ss0")
            if (e.target.id == "selectEmailRadio") {

                if (e.target.checked == true) {
                    console.log(i, "111dddd1")
                    opn[i-1].day = i
                    opn[i-1].check = true

                    // if (i > -1) {
                    //     opn.splice(i, 1); // 2nd parameter means remove one item only
                    // }

                }
                if (e.target.checked == false) {
                    opn[i].day = i
                    opn[i].check = false

                    // opn.push({
                    //     day: i,
                    // })
                }

            }
            console.log(e.target.name, opn, "111ddsss2")

            if (e.target.name == "startTime") {
                console.log(e.target.name, openingHours, "111ddsssss3")
                opn[i].startTime = e.target.value
            }
            if (e.target.name == "endTime") {
                opn[i].endTime = e.target.value
            }
            console.log(this.state.opn, "111ddeeeeeee4")


           this.setState({
                openingHours: opn,
                selectMobileRadio: true,
                socialType: 4,
                selectEmailRadio: false,
                selectPickRadioErrorMsg: ''
            })

        }
        handleChangeOpnHrsEdit = (e, i) => {
            console.log(e.target.name, e.target.id, e.target.value, i, e.target.checked, "111ddddddddddvcxcvbnm")
            let {openingHours, opn} = this.state;
            console.log(i, e.target.checked == true, "111dd11111111111111ss0")
            if (e.target.id == "selectEmailRadio") {

                if (e.target.checked == true) {
                    console.log(i, "111dddd1")
                    openingHours[i-1].day = i
                    openingHours[i-1].check = true

                    // if (i > -1) {
                    //     opn.splice(i, 1); // 2nd parameter means remove one item only
                    // }
                }
                if (e.target.checked == false) {
                    openingHours[i-1].day = i
                    openingHours[i-1].check = false

                    // opn.push({
                    //     day: i,
                    // })
                }

            }
            console.log(e.target.name, opn, "111ddsss2")

            if (e.target.name == "startTime") {
                console.log(e.target.name, openingHours, "111ddsssss3")
                openingHours[i  ].startTime = e.target.value
            }
            if (e.target.name == "endTime") {
                openingHours[i].endTime = e.target.value
            }
            console.log(this.state.opn, "111ddeeeeeee4")
            console.log(openingHours, "111ddeeeeeee4openingHours")


           this.setState({
                openingHours: openingHours,
                selectMobileRadio: true,
                socialType: 4,
                selectEmailRadio: false,
                selectPickRadioErrorMsg: ''
            })

        }

        checkVenues = (interest) => {
            console.log(interest, "saasssssssssssssccccccccccccc")
            let c = interest.length - 1
            // let data =        Date(dob)
            let a = ''
            !_.isEmpty(interest) && interest.map((val, i = 0) => {
                console.log(val)
                console.log(val, "saasssssssssssssccccccccccccc")
                return a = a + val + (c > i ? ', ' : '')
            })

            return a
        }
        handleSort = sortField => this.setState({
            loader: false,
            sortField,
            sortType: this.state.sortType === 1 ? -1 : 1
        }, () => this.getVenueInfo())
        handlePageChange = (pageNo) => {

            var self = this;
            this.setState({loader: false, page: pageNo, limit: 15}, () => {
                self.getVenueInfo();
            });
        }
        ConvertDate = (created) => {
            let data = moment(created).format("MM/DD/YYYY")
            return data
        }

        // // set address
        // handleAddressLatLong=(latValue , lngValue,address,area,city,state)=>{
        //     console.log('latlong',latValue , lngValue,address)
        //     this.setState({lat:latValue,long:lngValue,address:address,area:area,city:city,state:state})
        // }

        handleChangeaddress = (addressLine1) => {

            console.log(addressLine1, "aaaaaaaaaaa")
            this.setState({businessName: addressLine1, addressrror: ""});
        };
        handleChangeaddress1 = (addressLine1) => {

            console.log(addressLine1, "aaaaaaaaaaa")
            this.setState({address: addressLine1, addressrror: ""});
        };
        creteBlobFile = async (Url1) => {
            if (Url1) {
                const response = await fetch(Url1);
                const blob = await response.blob();
                console.log(Url1, "cccccccc000000000")
                console.log(blob, "cccccccc")


                const file = new File([blob], "profileImage" + ".jpeg", {
                    type: blob.type,
                });
                console.log(file, "cccccccc1111111")

                this.setState({
                    profileCoverPhotos: file
                })
            }

        }
        /*
                handleSelectAddress = async (addressLine1, placeId) => {
                    console.log("kjhbvcvbbbbbb")
                    let Url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?type=restaurant`;
                    console.log(Url,"kjhbvcvbbbbbb11111111")

                    console.log(addressLine1, placeId, "aaaaaaaaaaaSssssssssssssss")
                    const [place] = await geocodeByPlaceId(placeId);
                    console.log(place, "aaaaaaaaaaaaafffffffffffffffffff")
                    const {long_name: postalCode = ''} =
                    place.address_components.find(c => c.types.includes('postal_code')) || {};
                    let {city, state, country, zipCode, lat, long, openingHours} = this.state;

                    geocodeByAddress(addressLine1).then((results) => getLatLng(results[0]))
                        .then((latLng) => {
                            let newaddress = addressLine1.split(",");

                            console.log(newaddress, "aaaaaaaaaaaaaaaaaaaagggggggg", latLng)
                            this.setState({

                                long: latLng.lng,
                                lat: latLng.lat,
                                address: newaddress[0],
                            });
                            // const proxyurl = "https://cors-anywhere.herokuapp.com/";

                            fetch(Url)
                                .then((response) => response.json())
                                .then((result) => {
                                    console.log(result, "plaIdvvvvvvvvvvvvvvzzzzzzzzz")
                                    let params = `?placeId=${placeId}`
                                    let {dispatch} = this.props;

                                    dispatch(venuesActions.getPlaceById(params)).then(data => {
                                        console.log(data, "hgggggggggggggggg")
                                        data = data.responseData

                                        if (data.result && data.result.opening_hours) {
                                            openingHours=[]
                                            console.log(data.result.opening_hours.periods, "dddddddddvvvvvvvvvvvvvvvvvvvvv")
                                            let opnHrs = data.result.opening_hours.periods
                                            for (let i = 0; i < opnHrs.length; i++) {
                                                console.log(opnHrs[i], "dddddddddvvvvvvvvvvvvvvvvvvvvv")

                                                openingHours.push({
                                                    day: opnHrs[i].open.day,
                                                    startTime: opnHrs[i].open.time,
                                                    endTime: opnHrs[i].close.time
                                                })
                                            }
                                            console.log(openingHours, "dddddddddvvvvvvvvv1111111")

                                            this.setState({openingHours: openingHours ? openingHours : []})

                                        }
                                        let Url1 = data.result.photoUrl;
                                        this.creteBlobFile(Url1)

                                        console.log()
                                        this.setState({
                                            placeDetail: data.result,
                                            profileCoverPhotosPreview1: Url1,
                                            profileCoverPhotos: Url1
                                        })

                                        console.log(openingHours, "ddddddddddddddddddddd")


                                        console.log(result, "Sddddddddddd")
                                        let zip;
                                        let city;
                                        let state;
                                        let street;
                                        for (var i = 0; i < result.results.length; i++) {
                                            for (var j = 0; j < result.results[i].address_components.length; j++) {
                                                for (var k = 0; k < result.results[i].address_components[j].types.length; k++) {
                                                    console.log(result.results[i], "kkkkkkkkkkkk"
                                                    )


                                                    if (result.results[i].address_components[j].types[k] == "country") {
                                                        street = result.results[i].address_components[j].long_name;
                                                        if (street) {
                                                            this.setState({country: street});
                                                        } else {
                                                            this.setState({country: ""});
                                                        }
                                                    }
                                                    if (result.results[i].address_components[j].types[k] == "postal_code") {
                                                        zip = result.results[i].address_components[j].long_name;
                                                        if (postalCode) {
                                                            this.setState({zipCode: postalCode});
                                                        } else {
                                                            this.setState({zipCode: zip});
                                                        }
                                                    }
                                                    if (result.results[i].address_components[j].types[k] == "locality") {
                                                        city = result.results[i].address_components[j].long_name;
                                                        if (city) {
                                                            this.setState({city: city});
                                                        } else {
                                                            this.setState({city: ""});
                                                        }
                                                    }
                                                    if (result.results[i].address_components[j].types[k] == "administrative_area_level_1") {
                                                        state = result.results[i].address_components[j].long_name;

                                                        if (state) {
                                                            this.setState({state: state});
                                                        } else {
                                                            this.setState({state: ""});
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                    });
                                });
                        })
                        .catch((error) => console.error("Error", error));
                };
        */
        handleSelectAddress = async (itm, id) => {
            console.log(itm, id, "mmmmmmmmmmmmmmmmmmmm")
            console.log("kjhbvcvbbbbbb")
            // openingHours
            const [place] = await geocodeByPlaceId(id);
            console.log(place, "aaaaaaaaaaaaafffffffffffffffffff")
            const {long_name: postalCode = ''} =
            place.address_components.find(c => c.types.includes('postal_code')) || {};
            let address_components = place.address_components;

            console.log(place, "aaaaaaaaaaaaafffffffffffffffffff")
            console.log(address_components, "22222aaaaaaaaaaaaafffffffffffffffffff")

            // this.setState({
            //     businessName: itm,
            //     // long: itm.geometry.location.lng,
            //     // lat: itm.geometry.location.lat,
            //     address: address_components.formatted_address,
            //     searchList: []
            // });
            let {city, state, country, zipCode, lat, long, openingHours} = this.state;
            // geocodeByAddress(addressLine1).then((results) => getLatLng(results[0]))
            //     .then((latLng) => {
            //         let newaddress = addressLine1.split(",");
            //
            //         console.log(newaddress, "aaaaaaaaaaaaaaaaaaaagggggggg", latLng)

            //
            //         fetch(Url)
            //             .then((response) => response.json())
            //             .then((result) => {
            //                 console.log(result, "plaIdvvvvvvvvvvvvvvzzzzzzzzz")
            let params = `?placeId=${id}`
            let {dispatch} = this.props;

            dispatch(venuesActions.getPlaceById(params)).then(data => {
                console.log(data, "hgggggggggggggggg")
                data = data.responseData

                if (data.result && data.result.opening_hours) {
                    let openingHours = []

                    this.setState({
                        businessName: data.result.name,
                        long: data.result.geometry.location.lng,
                        lat: data.result.geometry.location.lat,
                        address: data.result.formatted_address,
                        searchList: []
                    });
                    console.log(data.result.opening_hours.periods, "dddddddddvvvvvvvvvvvvvvvvvvvvv")
                    let opnHrs = data.result.opening_hours.periods
                    for (let i = 0; i < opnHrs.length; i++) {
                        console.log(opnHrs[i], "dddddddddvvvvvvvvvvvvvvvvvvvvv")

                        openingHours.push({
                            day: opnHrs[i].open.day +1,
                            startTime: opnHrs[i].open.time,
                            endTime: opnHrs[i].close.time,
                            check:true
                        })
                    }
                    for (let i = 1; i < 8; i++) {
                        let a=openingHours.some(item => item.day === i)
                        console.log(a,"aaaaaaaa")
                        if(!a){
                            openingHours.push({
                                day: i,
                                startTime: '0',
                                endTime: '0',
                                check:false
                            })
                        }

                    }
                    openingHours =openingHours.sort((a,b) => a.day - b.day); // b - a for reverse sort

                    console.log(openingHours, "dddddddddvvvvvvvvv1111111")

                    this.setState({openingHours: openingHours ? openingHours : []})

                }
                let Url1 = data.result.photoUrl;
                this.creteBlobFile(Url1)

                console.log()
                this.setState({
                    placeDetail: data.result,
                    profileCoverPhotosPreview1: Url1,
                    profileCoverPhotos: Url1
                })

                // console.log(openingHours, "ddddddddddddddddddddd")


                // console.log(result, "Sddddddddddd")
                let zip;
                let city;
                let state;
                let street;
                let countryCodeText;
                let stateCodeText;
                for (var j = 0; j < address_components.length; j++) {
                    for (var k = 0; k < address_components[j].types.length; k++) {
                        console.log(address_components, "kkkkkkkkkkkk"
                        )


                        if (address_components[j].types[k] == "country") {
                            street = address_components[j].long_name;
                            countryCodeText = address_components[j].short_name;
                            if (street) {
                                this.setState({country: street,countryCodeText:countryCodeText});
                            } else {
                                this.setState({country: ""});
                            }
                        }
                        if (address_components[j].types[k] == "postal_code") {
                            zip = address_components[j].long_name;
                            if (postalCode) {
                                this.setState({zipCode: postalCode});
                            } else {
                                this.setState({zipCode: zip});
                            }
                        }
                        if (address_components[j].types[k] == "locality") {
                            city = address_components[j].long_name;
                            if (city) {
                                this.setState({city: city});
                            } else {
                                this.setState({city: ""});
                            }
                        }
                        if (address_components[j].types[k] == "administrative_area_level_1") {
                            state = address_components[j].long_name;
                            stateCodeText = address_components[j].short_name;

                            if (state) {
                                this.setState({state: state,stateCodeText: stateCodeText});
                            } else {
                                this.setState({state: ""});
                            }
                        }
                    }

                }

            });
            // });
            // })
            // .catch((error) => console.error("Error", error));
        };
        handleSelectAddress1 = async (itm, id) => {
            console.log(itm, id, "mmmmmmmmmmmmmmmmmmmm")
            console.log("kjhbvcvbbbbbb")

            const [place] = await geocodeByPlaceId(id);
            console.log(place, "aaaaaaaaaaaaafffffffffffffffffff")
            const {long_name: postalCode = ''} =
            place.address_components.find(c => c.types.includes('postal_code')) || {};
            let address_components = place.address_components;

            console.log(place, "111111aaaaaaaaaaaaafffffffffffffffffff")
            console.log(address_components, "22222aaaaaaaaaaaaafffffffffffffffffff")

            let {city, state, country, zipCode, lat, long, openingHours} = this.state;
          let params = `?placeId=${id}`
            let {dispatch} = this.props;

            dispatch(venuesActions.getPlaceById(params)).then(data => {
                console.log(data, "hgggggggggggggggg")
                data = data.responseData

                if (data.result && data.result) {
                    let openingHours = []

                    this.setState({
                        // address: data.result.name,
                        long: data.result.geometry.location.lng,
                        lat: data.result.geometry.location.lat,
                        address: data.result.formatted_address,
                        searchList: []
                    });

                }


                console.log()
                // this.setState({
                //     placeDetail: data.result,
                // })


                // console.log(result, "Sddddddddddd")
                let zip;
                let city;
                let state;
                let street;
                let stateCodeText;
                let countryCodeText;
                for (var j = 0; j < address_components.length; j++) {
                    for (var k = 0; k < address_components[j].types.length; k++) {
                        console.log(address_components, "kkkkkkkkkkkk"
                        )


                        if (address_components[j].types[k] == "country") {
                            street = address_components[j].long_name;
                            countryCodeText = address_components[j].short_name;
                            if (street) {
                                this.setState({country: street,countryCodeText:countryCodeText});
                            } else {
                                this.setState({country: ""});
                            }
                        }
                        if (address_components[j].types[k] == "postal_code") {
                            zip = address_components[j].long_name;
                            if (postalCode) {
                                this.setState({zipCode: postalCode});
                            } else {
                                this.setState({zipCode: zip});
                            }
                        }
                        if (address_components[j].types[k] == "locality") {
                            city = address_components[j].long_name;
                            if (city) {
                                this.setState({city: city});
                            } else {
                                this.setState({city: ""});
                            }
                        }
                        if (address_components[j].types[k] == "administrative_area_level_1") {
                            state = address_components[j].long_name;
                            stateCodeText = address_components[j].short_name;

                            if (state) {
                                this.setState({state: state,stateCodeText: stateCodeText});
                            } else {
                                this.setState({state: ""});
                            }
                        }
                    }

                }

            });

        };
        sendToParent = (e) => {
            console.log(e, "xxxxxxxxxxxxxxxxx3")
            this.setState({loader: true})
            this.getVenueInfo()
        }
        changeName = (event) => {
            const self = this;
            // console.log(event.target.value, "xxxxxxxxxxxxxxxxx")
            if (self.state.typingTimeout) {
                clearTimeout(self.state.typingTimeout);
            }

            self.setState({
                search: event.target.value,
                typing: false,
                pageNo: 1,

                typingTimeout: setTimeout(function () {
                    self.sendToParent(self.state.name);
                }, 1000)
            })


        }
    handleBlock = (e,item,) => {
        let {userId, userStatus} = this.state
        let self = this

        console.log(item,"lkj",e.target.value, "ccccc   ",)
        let obj = {
            'usrId': item.userId,
            'userStatus': e.target.value,
            'userType': 2,
            // 'userId': userId,
        }
        this.setState({miniloader: true,})

        let {dispatch} = this.props;
        dispatch(usersActions.changeUserStatus(obj)).then(data => {

            self.setState({miniloader: false,})
            //
            if (data.statusCode == 1) {
                // this.setState({deletePopup: false})
                this.getVenueInfo()

            } else {
                this.setState({errors: data.error.responseMessage})
            }
        });


    }

        render()
        {

            let {
                selectDaysRadio,
                venueList, placeDetail,
                firstName,
                publicBusinessName,
                address,
                profileCoverPhotosPreview1,
                profileCoverPhotosPreview,
                openingHours,
                entertainments,
                venues,
                businessName,
                description,
                countryCode,
                mobile,
                email,
                lastName,
                lastNameErrorMsg,
                emailErrorMsg,
                mobileErrorMsg, socialTypeErrorMsg,
                profileCoverPhotosErrorMsg,
                firstNameErrorMsg, vatErrorMsg,

                openingHoursErrorMsg,
                entertainmentsErrorMsg,
                addressErrorMsg,
                venuesErrorMsg,
                businessNameErrorMsg,
                countryCodeErrorMsg,
                publicBusinessNameErrorMsg,
                deletePopup,
                selectEmailRadio,
                selectMobileRadio,
                reasonErMsg,
                errors,
                userStatus,
                limit,
                page,
                totalCount,
                dataCount,
                profilePicPreview, vat,
                city, state, country, zipCode, opn
            } = this.state;
            console.log(opn, "111ddhgvcff")
            return (
                <>{console.log(openingHours, "openingHoursopeningHoursopeningHoursopeningHours")}
                    <Sidebar/>
                    <div className="body_container">
                        <div className=" ">
                            <div className="row mb-3">
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="count-list">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <h6 className="mb-2">Total Venues</h6>
                                                        <h6 className="mb-0">{totalCount}</h6>
                                                    </div>
                                                    <div>
                                                        <span className="user-icon icon-blue">
                                                            <UserIcon/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 offset-3 header_search header_search_custom1">
                                    <div className="form-group has-search ">
                                        <span className="fa fa-search form-control-feedback"></span>
                                        {/*<Form.Control type="Search" placeholder="Search..." name="search" value={"search"}></Form.Control>*/}
                                        <Form.Control type="text" placeholder="Search" name={"search"}
                                                      onChange={this.changeName}
                                            // onKey/Down={this.handleKeyDown}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div>
                            <div className="body_inner_wrapper">
                                {this.state.loader ? <div className="loader_wrapper">
                                        <div className="spinner-border text-info" role="status"></div>
                                    </div> :
                                    (<>
                                        <header className="header_content">
                                            <p>Venues</p>
                                            {/*<MapView google={this.props.google} center={{ lat: 40.7128, lng: 74.0060 }} height='200px' handleAddressLatLong={this.handleAddressLatLong} zoom={15} />*/}

                                            <div className="d-flex text-nowrap header_btn">
                                                <Button onClick={this.addPopupModal}
                                                        variant="warning"
                                                        className="changepassword">
                                                    Add Venues
                                                </Button>

                                            </div>

                                        </header>


                                        <div className="main_content">
                                            <div className="table-responsive">
                                                <table className="table cm_bottom_space">
                                                    <thead>
                                                    <tr className="broder_top_0">
                                                        <th scope="col" className="ws-nowrap">S. No.</th>
                                                        <th scope="col"
                                                            onClick={() => this.handleSort('created')}>Created
                                                            On<i
                                                                className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                        </th>
                                                        <th>Business Name</th>
                                                        <th scope="col"
                                                            onClick={() => this.handleSort('businessName')}> Business
                                                            User <i
                                                                className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleSort('email')}> Venue
                                                            Type<i
                                                                className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                        </th>
                                                        <th scope="col" className="ws-nowrap"> Entertainments</th>
                                                        <th scope="col" className="ws-nowrap"> Photos</th>
                                                        <th scope="col" className="ws-nowrap"> Likes</th>
                                                        <th scope="col" className="ws-nowrap"> Crowd</th>
                                                        <th scope="col" className="ws-nowrap"> Address</th>
                                                        <th scope="col" className="ws-nowrap"> Vat</th>
                                                        <th scope="col" className="ws-nowrap"> Status</th>

                                                        <th scope="col" className="ws-nowrap"> Action</th>


                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {!_.isEmpty(venueList) && venueList.map((item, i) => (
                                                        <tr key={item._id}>
                                                            <td>{((limit * (page - 1)) + (i + 1))}</td>
                                                            <td>{this.ConvertDate(item.created)}</td>
                                                            <td>{item.businessName}</td>
                                                            <td>
                                                                {/*{item.businessUser?item.businessUser.firstName:''}*/}
                                                                <Link
                                                                    to={`business-info/${item.businessUser._id}`}>{item.businessUser.firstName} {item.businessUser.lastName}</Link>
                                                            </td>
                                                            <td>{item ? this.checkVenues(item.venues) : ''}</td>
                                                            <td>{item ? this.checkVenues(item.entertainments) : ''}</td>
                                                            {/*<td>{item ? item.entertainments[0] : ''}</td>*/}

                                                            <td>{item.profileCoverPhotos.length ?
                                                                <img height="40" width="40"
                                                                     src={item.profileCoverPhotos[0].thumbUrl}/> : ''}</td>
                                                            <td>{item.likedCount}</td>
                                                            <td>{item.crowd}</td>
                                                            <td className={"tdRow"}>{item.address}</td>
                                                            <td>{item.vat}</td>
                                                            {/*<td>{item.status == 1 ? "Active" : ("Inactive")}</td>*/}
                                                           <td> <select value={item.status}
                                                                    name="userStatus"
                                                                    onChange={(e)=>this.handleBlock(e,item)}
                                                                    className="form-control-sm">
                                                                {/*<option value=''>Select</option>*/}
                                                                <option value='1'>Active</option>
                                                                <option value='0'>Inactive</option>

                                                            </select></td>

                                                            <td>
                                                            <span> <i className="fas fa-pen"
                                                                      onClick={() => this.editPopupModal(item)}
                                                            ></i></span>
                                                                <span> <i className="fas fa-trash text-danger"
                                                                          onClick={() => this.deletePopupModal(item)}></i></span>
                                                            </td>
                                                        </tr>))
                                                    }


                                                    </tbody>


                                                </table>
                                                {console.log(limit, page, totalCount)}
                                                {venueList && venueList.length ?
                                                    <div
                                                        className="d-flex justify-content-end cm-pagination">
                                                        <Pagination
                                                            itemClassPrev={'prevClass'}
                                                            itemClassNext={'nextClass'}
                                                            activePage={page}
                                                            itemsCountPerPage={limit}
                                                            totalItemsCount={totalCount}
                                                            pageRangeDisplayed={3}
                                                            onChange={this.handlePageChange}
                                                        />
                                                    </div> : ''}
                                                {_.isEmpty(this.state.venueList) ?
                                                    <h3 className="text-center">No data found</h3> : ''}
                                                {reasonErMsg ?
                                                    <div className="text-center error ">{reasonErMsg}</div> : ''}
                                            </div>


                                        </div>


                                    </>)}


                            </div>


                        </div>
                        <Modal aria-labelledby="example-modal-sizes-title-sm "
                               show={this.state.addVenuePopup}
                               onHide={this.handleClose}
                               className="cm_order_view order-detailsmodal">
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-sm"><h6>Add Venue</h6></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div onClick={this.clearSearchList}>
                                    <form onSubmit={this.addVenue}>
                                        <Row>

                                            <Col md="2">
                                                <p>Business logo:</p>
                                            </Col>

                                            <Col md="6">
                                                <div
                                                    className="u_img mb-5">{console.log(profileCoverPhotosPreview1, "sjhgcvbhjkjhbvc")}
                                                    {profileCoverPhotosPreview1 ?
                                                        <img className="rounded-circle1"
                                                             src={profileCoverPhotosPreview1}
                                                             className="logoroundimg"/> : <img className=""
                                                                                               src="https://dingstag.s3.amazonaws.com/1648804316387.png"
                                                                                               className="logoroundimg"/>}
                                                    <span className="img_icon"><span className="file_input"><input
                                                        type="file" className="cm_hide_input"
                                                        accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                                        onChange={this.handlePicUpload}/>
                                                                                        </span>
                                                                                        <img

                                                                                            src={require('../../assets/img/imgicon.png')}/>
                                                                                    </span>
                                                </div>
                                                {profileCoverPhotosErrorMsg ?
                                                    <div
                                                        className="error">{profileCoverPhotosErrorMsg}</div> : null}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="2">
                                                <p>First Name:</p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="text"
                                                              onChange={this.handleChanged}
                                                              name="firstName"
                                                              value={firstName}
                                                              placeholder="First Name"/>
                                                {firstNameErrorMsg ?
                                                    <div
                                                        className="error">{firstNameErrorMsg}</div> : null}
                                            </Col>


                                            <Col md="2">
                                                <p>Last Name:</p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="text"
                                                              onChange={this.handleChanged}
                                                              name="lastName"
                                                              value={lastName}
                                                              placeholder="Last Name"/>
                                                {lastNameErrorMsg ?
                                                    <div className="error">{lastNameErrorMsg}</div> : null}
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col md="2">
                                                <p>Business Name:</p>

                                            </Col>
                                            <Col md="4">
                                                {/*<Form.Control type="text"*/}
                                                {/*              onChange={this.nearBySeach}*/}
                                                {/*              name="businessName"*/}
                                                {/*              value={businessName}*/}
                                                {/*              placeholder="Business Name"/>*/}
                                                {/*{this.state.searchList.length ? <div className="custom-search-list">*/}
                                                {/*    {this.state.searchList.length && this.state.searchList.map((itm, i) => (*/}
                                                {/*        <div onClick={() => this.handleSelectAddress(itm)}*/}
                                                {/*             className="list-font">{itm.name}<br/></div>))}*/}
                                                {/*</div> : ''}*/}

                                                <PlacesAutocomplete
                                                    value={businessName}
                                                    placeholder="Business Name"
                                                    onChange={this.handleChangeaddress}
                                                    onSelect={this.handleSelectAddress}
                                                >
                                                    {({
                                                          getInputProps,
                                                          suggestions,
                                                          getSuggestionItemProps,
                                                          loading,
                                                      }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({

                                                                    placeholder: " Address",
                                                                    className: "location-search-input form-control acc-form-input",
                                                                })}
                                                            />

                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map((suggestion) => {
                                                                const className = suggestion.active
                                                                    ? "suggestion-item--active"
                                                                    : "suggestion-item";
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? {backgroundColor: "#fafafa", cursor: "pointer"}
                                                                    : {backgroundColor: "#ffffff", cursor: "pointer"};
                                                                return (
                                                                    <div
                                                                        className={address && suggestions.description !== "" ? "autocomplete-dropdown-container" : ""}>
                                                                        <div
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                                style,
                                                                            })}
                                                                        >
                                                                            <span>{suggestion.description}</span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>

                                                    )}
                                                </PlacesAutocomplete>


                                                {businessNameErrorMsg ?
                                                    <div
                                                        className="error">{businessNameErrorMsg}</div> : null}
                                            </Col>

                                            <Col md="2">
                                                <p>Public Business Name:</p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="text"
                                                              onChange={this.handleChanged}
                                                              name="publicBusinessName"
                                                              value={publicBusinessName}
                                                              placeholder="Public Business Name"/>
                                                {publicBusinessNameErrorMsg ?
                                                    <div
                                                        className="error">{publicBusinessNameErrorMsg}</div> : null}
                                            </Col>

                                        </Row>
                                        <Row>

                                            <Col md="2">
                                                <p>

                                                    Venues</p>

                                            </Col>


                                            <Col md="4">


                                                <Select
                                                    mode={"multiple"}
                                                    name="venues"
                                                    defaultValue={venues}
                                                    onDeselect={this.deSelectVenues}
                                                    onSelect={this.handleChangedVenues}
                                                    className="">

                                                    <Option value="Lounge" key="Food">Lounge</Option>
                                                    <Option value="Club" key="Drinks">Club</Option>
                                                    <Option value="Bar" key="Games">Bar</Option>
                                                    <Option value="Other" key="Music">Other</Option>


                                                </Select>
                                                {venuesErrorMsg ?
                                                    <div
                                                        className="error">{venuesErrorMsg}</div> : null}
                                            </Col>


                                            <Col md="2">
                                                <p>Entertainments
                                                </p>

                                            </Col>
                                            <Col md="4">
                                                {console.log(entertainments,"jhgfcdfghn")}
                                                <Select
                                                    mode={"multiple"}
                                                    // defaultValue={venues}

                                                    defaultValue={entertainments}
                                                    // style={{ width: '100%' }}
                                                    // inputMode={"multiple"}
                                                    name="entertainments"
                                                    onDeselect={this.deSelectEntertaimment}
                                                    // onChange={this.handleChangedEntertaimment}
                                                    onSelect={this.handleChangedEntertaimment}
                                                    className="">
                                                    <Option value="Food" key="Food">Food</Option>
                                                    <Option value="Drinks" key="Drinks">Drinks</Option>
                                                    <Option value="Games" key="Games">Games</Option>
                                                    <Option value="Music" key="Music">Music</Option>
                                                    <Option value="Hookah" key="Hookah">Hookah</Option>
                                                    <Option value="Indoor" key="Indoor">Indoor</Option>
                                                    <Option value="Outdoor" key="Outdoor">Outdoor</Option>
                                                    <Option value="Booths/Sections" key="Booths/Sections">Booths/Sections</Option>

                                                </Select>
                                                {entertainmentsErrorMsg ?
                                                    <div
                                                        className="error">{entertainmentsErrorMsg}</div> : null}
                                            </Col>


                                        </Row>
                                        <Row>


                                            <Col md="2">
                                                <p>Address
                                                </p>

                                            </Col>


                                            {/*{addressErrorMsg ? <div className="error">{addressErrorMsg}</div> : null}*/}

                                            <Col md="6">
                                                {/*<Form.Control type="text"*/}
                                                {/*              onChange={this.handleChanged}*/}
                                                {/*              name="address"*/}
                                                {/*              value={address}*/}
                                                {/*              placeholder="Address"/>*/}

                                                {/*{address}*/}
                                                <PlacesAutocomplete
                                                    value={address}
                                                    placeholder="Address"
                                                    onChange={this.handleChangeaddress1}
                                                    onSelect={this.handleSelectAddress1}
                                                >
                                                    {({
                                                          getInputProps,
                                                          suggestions,
                                                          getSuggestionItemProps,
                                                          loading,
                                                      }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({

                                                                    placeholder: " Address",
                                                                    className: "location-search-input form-control acc-form-input",
                                                                })}
                                                            />

                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map((suggestion) => {
                                                                const className = suggestion.active
                                                                    ? "suggestion-item--active"
                                                                    : "suggestion-item";
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? {backgroundColor: "#fafafa", cursor: "pointer"}
                                                                    : {backgroundColor: "#ffffff", cursor: "pointer"};
                                                                return (
                                                                    <div
                                                                        className={address && suggestions.description !== "" ? "autocomplete-dropdown-container" : ""}>
                                                                        <div
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                                style,
                                                                            })}
                                                                        >
                                                                            <span>{suggestion.description}</span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>

                                                    )}
                                                </PlacesAutocomplete>


                                                {addressErrorMsg ?
                                                    <div className="error">{addressErrorMsg}</div> : null}
                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="string"
                                                              onChange={this.handleChanged}
                                                              name="vat"
                                                              value={vat}

                                                              placeholder="VAT"/>
                                                {vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}
                                            </Col>

                                        </Row>
                                        <Row><Col md="2">
                                            <p>City
                                            </p>

                                        </Col>
                                            <Col md="4">
                                                <Form.Control type="string"
                                                              onChange={this.handleChanged}
                                                              name="city"
                                                              value={city}

                                                              placeholder="City"/>
                                                {vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}
                                            </Col>
                                            <Col md="2">
                                                <p>state
                                                </p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="string"
                                                              onChange={this.handleChanged}
                                                              name="state"
                                                              value={state}

                                                              placeholder="State"/>
                                                {vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <p>Country
                                                </p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="string"
                                                              onChange={this.handleChanged}
                                                              name="country"
                                                              value={country}

                                                              placeholder="Country"/>
                                                {vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}
                                            </Col>
                                            <Col md="2">
                                                <p>Zipcode
                                                </p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="string"
                                                              onChange={this.handleChanged}
                                                              name="zipCode"
                                                              value={zipCode}

                                                              placeholder="Zipcode"/>
                                                {vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}
                                            </Col>
                                        </Row><Row>
                                        <Col md="2">
                                            <p>Description
                                            </p>

                                        </Col>
                                        <Col md="4">
                                            <Form.Control type="string"
                                                          onChange={this.handleChanged}
                                                          name="description"
                                                          value={description}

                                                          placeholder="Description"/>
                                            {/*{vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}*/}
                                        </Col>
                                    </Row>

                                        {/*{!_.isEmpty(openingHours)  ? */}
                                        <Row lg="2" md="3">
                                            <p>Hours of operation:</p>
                                            {console.log(openingHours, opn,"ggggggggggggg")}
                                        </Row>
                                        {/*:''}*/}


                                        {!_.isEmpty(openingHours) ? openingHours.map((val, i = 1) => (
                                                <Row>

                                                    <Col md="2">
                                                        <label className="pt-3">
                                                            <input type="checkbox" id="selectEmailRadio"
                                                                // name={"selectEmailRadio"}
                                                                   checked={val.check ? true : false}
                                                                   onChange={(e) => this.handleChangeOpnHrs(e, i+1)}
                                                                   name="ONE"/></label>
                                                    </Col>
                                                    <Col md="2">{days(val.day)}</Col>
                                                    <Col md="2">

                                                        <select
                                                            value={val.startTime}
                                                            onChange={(e) => this.handleChangeOpnHrs(e, i)}
                                                            onSelect={(e) => this.handleChangeOpnHrs(e, i)}
                                                            name="startTime"
                                                            className="form-control">
                                                            <option name={"0"}
                                                                    value="0">{"12:00 am"}</option>
                                                            <option name={"0100"} value="0100">{"1:00 am"}</option>
                                                            <option name={"0200"} value="0200">{"2:00 am"}</option>
                                                            <option name={"0300"} value="0300">{"3:00 am"}</option>
                                                            <option name={"0400"} value="0400">{"4:00 am"}</option>
                                                            <option name={"0500"} value="0500">{"5:00 am"}</option>
                                                            <option name={"0600"} value="0600">{"6:00 am"}</option>
                                                            <option name={"0700"} value="0700">{"7:00 am"}</option>
                                                            <option name={"0800"} value="0800">{"8:00 am"}</option>
                                                            <option name={"0900"} value="0900">{"9:00 am"}</option>
                                                            <option name={"1000"} value="1000">{"10:00 am"}</option>
                                                            <option name={"1100"} value="1100">{"11:00 am"}</option>
                                                            <option name={"1200"} value="1200">{"12:00 pm"}</option>
                                                            <option name={"1300"} value="1300">{"13:00 pm"}</option>
                                                            <option name={"1400"} value="1400">{"14:00 pm"}</option>
                                                            <option name={"1500"} value="1500">{"15:00 pm"}</option>
                                                            <option name={"1600"} value="1600">{"16:00 pm"}</option>
                                                            <option name={"1700"} value="1700">{"17:00 pm"}</option>
                                                            <option name={"1800"} value="1800">{"18:00 pm"}</option>
                                                            <option name={"1900"} value="1900">{"19:00 pm"}</option>
                                                            <option name={"2000"} value="2000">{"20:00 pm"}</option>
                                                            <option name={"2100"} value="2100">{"21:00 pm"}</option>
                                                            <option name={"2200"} value="2200">{"22:00 pm"}</option>
                                                            <option name={"2300"} value="2300">{"23:00 pm"}</option>

                                                        </select>
                                                    </Col>
                                                    <Col md="2">
                                                        <select
                                                            // inputMode={}
                                                            // mode={"multiple"}
                                                            // name="venues"
                                                            // onDeselect={this.deSelectVenues}
                                                            onChange={(e) => this.handleChangeOpnHrs(e, i)}
                                                            onSelect={(e) => this.handleChangeOpnHrs(e, i)}
                                                            value={val.endTime}
                                                            name="endTime"

                                                            className="form-control">
                                                            <option name={"0"} value="0">{"12:00 am"}</option>
                                                            <option name={"100"} value="100">{"1:00 am"}</option>
                                                            <option name={"200"} value="200">{"2:00 am"}</option>
                                                            <option name={"300"} value="300">{"3:00 am"}</option>
                                                            <option name={"400"} value="400">{"4:00 am"}</option>
                                                            <option name={"500"} value="500">{"5:00 am"}</option>
                                                            <option name={"600"} value="600">{"6:00 am"}</option>
                                                            <option name={"700"} value="700">{"7:00 am"}</option>
                                                            <option name={"800"} value="800">{"8:00 am"}</option>
                                                            <option name={"900"} value="900">{"9:00 am"}</option>
                                                            <option name={"1000"} value="1000">{"10:00 am"}</option>
                                                            <option name={"1100"} value="1100">{"11:00 am"}</option>
                                                            <option name={"1200"} value="1200">{"12:00 pm"}</option>
                                                            <option name={"1300"} value="1300">{"13:00 pm"}</option>
                                                            <option name={"1400"} value="1400">{"14:00 pm"}</option>
                                                            <option name={"1500"} value="1500">{"15:00 pm"}</option>
                                                            <option name={"1600"} value="1600">{"16:00 pm"}</option>
                                                            <option name={"1700"} value="1700">{"17:00 pm"}</option>
                                                            <option name={"1800"} value="1800">{"18:00 pm"}</option>
                                                            <option name={"1900"} value="1900">{"19:00 pm"}</option>
                                                            <option name={"2000"} value="2000">{"20:00 pm"}</option>
                                                            <option name={"2100"} value="2100">{"21:00 pm"}</option>
                                                            <option name={"2200"} value="2200">{"22:00 pm"}</option>
                                                            <option name={"2300"} value="2300">{"23:00 pm"}</option>
                                                        </select>
                                                    </Col>
                                                </Row>)) :
                                            opn && opn.map((val, i = 0) => (
                                                <Row>

                                                    <Col md="2">
                                                        <label className="pt-3">
                                                            <input type="checkbox" id="selectEmailRadio"
                                                                // name={"selectEmailRadio"}
                                                                   checked={val.check ? true : false}
                                                                   onChange={(e) => this.handleChangeOpnHrs(e, i+1)}
                                                                   name="ONE"/></label>
                                                    </Col>
                                                    <Col md="2">{days(val.day)}</Col>
                                                    <Col md="2">

                                                        <select
                                                            value={val.startTime}
                                                            onChange={(e) => this.handleChangeOpnHrs(e, i)}
                                                            name="startTime"
                                                            className="form-control">
                                                            <option name={"0"} value="0">{"12:00 am"}</option>
                                                            <option name={"100"} value="100">{"1:00 am"}</option>
                                                            <option name={"200"} value="200">{"2:00 am"}</option>
                                                            <option name={"300"} value="300">{"3:00 am"}</option>
                                                            <option name={"400"} value="400">{"4:00 am"}</option>
                                                            <option name={"500"} value="500">{"5:00 am"}</option>
                                                            <option name={"600"} value="600">{"6:00 am"}</option>
                                                            <option name={"700"} value="700">{"7:00 am"}</option>
                                                            <option name={"800"} value="800">{"8:00 am"}</option>
                                                            <option name={"900"} value="900">{"9:00 am"}</option>
                                                            <option name={"1000"} value="1000">{"10:00 am"}</option>
                                                            <option name={"1100"} value="1100">{"11:00 am"}</option>
                                                            <option name={"1200"} value="1200">{"12:00 pm"}</option>
                                                            <option name={"1300"} value="1300">{"13:00 pm"}</option>
                                                            <option name={"1400"} value="1400">{"14:00 pm"}</option>
                                                            <option name={"1500"} value="1500">{"15:00 pm"}</option>
                                                            <option name={"1600"} value="1600">{"16:00 pm"}</option>
                                                            <option name={"1700"} value="1700">{"17:00 pm"}</option>
                                                            <option name={"1800"} value="1800">{"18:00 pm"}</option>
                                                            <option name={"1900"} value="1900">{"19:00 pm"}</option>
                                                            <option name={"2000"} value="2000">{"20:00 pm"}</option>
                                                            <option name={"2100"} value="2100">{"21:00 pm"}</option>
                                                            <option name={"2200"} value="2200">{"22:00 pm"}</option>
                                                            <option name={"2300"} value="2300">{"23:00 pm"}</option>

                                                        </select>
                                                    </Col>
                                                    <Col md="2">
                                                        <select
                                                            // inputMode={}
                                                            // mode={"multiple"}
                                                            // name="venues"
                                                            // onDeselect={this.deSelectVenues}
                                                            onChange={(e) => this.handleChangeOpnHrs(e, i)}
                                                            // value={val.endTime}
                                                            name="endTime"

                                                            className="form-control">
                                                            <option name={"0"} value="0">{"12:00 am"}</option>
                                                            <option name={"0100"} value="0100">{"1:00 am"}</option>
                                                            <option name={"0200"} value="0200">{"2:00 am"}</option>
                                                            <option name={"0300"} value="0300">{"3:00 am"}</option>
                                                            <option name={"0400"} value="0400">{"4:00 am"}</option>
                                                            <option name={"0500"} value="0500">{"5:00 am"}</option>
                                                            <option name={"0600"} value="0600">{"6:00 am"}</option>
                                                            {val.startTime=="0700"?<option name={"0700"} value="0700">{"7:00 am"}</option>:
                                                                <option name={"0700"} value="0700">{"7:00 am"}</option>}
                                                            <option name={"0800"} value="0800">{"8:00 am"}</option>
                                                            <option name={"0900"} value="0900">{"9:00 am"}</option>
                                                            <option name={"1000"} value="1000">{"10:00 am"}</option>
                                                            <option name={"1100"} value="1100">{"11:00 am"}</option>
                                                            <option name={"1200"} value="1200">{"12:00 pm"}</option>
                                                            <option name={"1300"} value="1300">{"13:00 pm"}</option>
                                                            <option name={"1400"} value="1400">{"14:00 pm"}</option>
                                                            <option name={"1500"} value="1500">{"15:00 pm"}</option>
                                                            <option name={"1600"} value="1600">{"16:00 pm"}</option>
                                                            <option name={"1700"} value="1700">{"17:00 pm"}</option>
                                                            <option name={"1800"} value="1800">{"18:00 pm"}</option>
                                                            <option name={"1900"} value="1900">{"19:00 pm"}</option>
                                                            <option name={"2000"} value="2000">{"20:00 pm"}</option>
                                                            <option name={"2100"} value="2100">{"21:00 pm"}</option>
                                                            <option name={"2200"} value="2200">{"22:00 pm"}</option>
                                                            <option name={"2300"} value="2300">{"23:00 pm"}</option>
                                                        </select>
                                                    </Col>
                                                </Row>))


                                        }


                                        {errors ? <div className="error">{errors}</div> : null}

                                        <Row>
                                            <Col md="10" className="text-sm-right">
                                                <Button type="submit"
                                                        variant="warning"
                                                        className="mr-2">Save</Button>
                                                &nbsp;

                                                <Button onClick={this.handleClose}
                                                        variant="dark">Cancel</Button>


                                            </Col>
                                        </Row>


                                    </form>

                                </div>
                            </Modal.Body>
                            <Modal.Footer>

                                <div className="footer_btn_wrapper">

                                </div>
                                <div className="print_button orderabs">


                                </div>
                            </Modal.Footer>
                        </Modal>

                        <Modal aria-labelledby="example-modal-sizes-title-sm " show={this.state.editPopup}
                               onHide={this.handleClose} className="cm_order_view order-detailsmodal">
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-sm"><h6>Edit Venue</h6></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div onClick={this.clearSearchList}>
                                    <form onSubmit={this.editVenue}>
                                        <Row>

                                            <Col md="2">
                                                <p>Business logo:</p>

                                            </Col>

                                            <Col md="6">
                                                <div
                                                    className="u_img mb-5">{console.log(profileCoverPhotosPreview1, "sjhgcvbhjkjhbvc")}

                                                    {profileCoverPhotosPreview1 ?
                                                        <img className="rounded-circle1"
                                                             src={profileCoverPhotosPreview1 ? profileCoverPhotosPreview1 : "https://dingstag.s3.amazonaws.com/1648804316387.png"}


                                                             className="logoroundimg"/> :
                                                        <img className="rounded-circle1"


                                                             src="https://dingstag.s3.amazonaws.com/1648804316387.png"

                                                             className="logoroundimg"/>}
                                                    <span className="img_icon"><span className="file_input"><input
                                                        type="file" className="cm_hide_input"
                                                        accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                                        onChange={this.handlePicUpload}/>
                                                                                        </span>
                                                                                        <img
                                                                                            src={require('../../assets/img/imgicon.png')}/>
                                                                                    </span>
                                                </div>
                                                {profileCoverPhotosErrorMsg ?
                                                    <div
                                                        className="error">{profileCoverPhotosErrorMsg}</div> : null}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="2">
                                                <p>First Name:</p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="text"
                                                              onChange={this.handleChanged}
                                                              name="firstName"
                                                              value={firstName}
                                                              placeholder="First Name"/>
                                                {firstNameErrorMsg ?
                                                    <div
                                                        className="error">{firstNameErrorMsg}</div> : null}
                                            </Col>


                                            <Col md="2">
                                                <p>Last Name:</p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="text"
                                                              onChange={this.handleChanged}
                                                              name="lastName"
                                                              value={lastName}
                                                              placeholder="Last Name"/>
                                                {lastNameErrorMsg ?
                                                    <div
                                                        className="error">{lastNameErrorMsg}</div> : null}
                                            </Col>


                                        </Row>


                                        {/*<Row className="mb-sm-3"> <Col lg="2" md="3">
                                        <p>Pick Up / Delivery:</p>
                                        <p> Email/Mobile:</p>

                                    </Col><>
                                        <Col lg="2" md="3">
                                            <label className="pt-3"><input type="radio" id="selectEmailRadio"
                                                                           value={selectEmailRadio}
                                                                           onChange={this.handleRadio}
                                                                           name="ONE"/>


                                                Email:</label>
                                        </Col>

                                        <Col lg="2" md="3">
                                            <label className="pt-3">
                                                <input type="radio" id="selectMobileRadio" name="ONE"
                                                       value={selectMobileRadio} onChange={this.handleRadio}/>

                                                Mobile:</label>
                                        </Col>
                                        {socialTypeErrorMsg ?
                                            <div
                                                className="error errType">{socialTypeErrorMsg}</div> : null}
                                    </>
                                    </Row>
                                    {selectEmailRadio ? <Row>

                                        <Col md="2">
                                            <p>Email:</p>

                                        </Col>
                                        <Col md="4">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="email"
                                                          value={email}
                                                          placeholder="Email "/>
                                            {emailErrorMsg ?
                                                <div
                                                    className="error">{emailErrorMsg}</div> : null}
                                        </Col>
                                    </Row> : ""}
                                    {selectMobileRadio ? <Row>

                                            <Col md="2">
                                                <p>Phone:</p>
                                            </Col>
                                            <Col md="4">
                                                <PhoneInput
                                                    country="us"
                                                    autocomplete="off"
                                                    type={"text"} autoComplete="new-password"
                                                    inputProps={{
                                                        required: false,
                                                        autoFocus: false
                                                    }}
                                                    ref={this.codeInput}
                                                    className="form-control custom-number"
                                                    value={countryCode}
                                                    onChange={(value, data, event, formattedValue) => {
                                                        this.hanlePhone(value, data, event, formattedValue)
                                                    }
                                                    }
                                                />
                                                {mobileErrorMsg ?
                                                    <div
                                                        className="error">{mobileErrorMsg}</div> : null}

                                            </Col>


                                        </Row>
                                        : ""}*/}
                                        <Row>

                                            <Col md="2">
                                                <p>Business Name:</p>

                                            </Col>
                                            <Col md="4">
                                                <PlacesAutocomplete
                                                    value={businessName}
                                                    placeholder="Business Name"
                                                    onChange={this.handleChangeaddress}
                                                    onSelect={this.handleSelectAddress}
                                                >
                                                    {({
                                                          getInputProps,
                                                          suggestions,
                                                          getSuggestionItemProps,
                                                          loading,
                                                      }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({

                                                                    placeholder: " Address",
                                                                    className: "location-search-input form-control acc-form-input",
                                                                })}
                                                            />

                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map((suggestion) => {
                                                                const className = suggestion.active
                                                                    ? "suggestion-item--active"
                                                                    : "suggestion-item";
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? {backgroundColor: "#fafafa", cursor: "pointer"}
                                                                    : {backgroundColor: "#ffffff", cursor: "pointer"};
                                                                return (
                                                                    <div
                                                                        className={address && suggestions.description !== "" ? "autocomplete-dropdown-container" : ""}>
                                                                        <div
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                                style,
                                                                            })}
                                                                        >
                                                                            <span>{suggestion.description}</span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>

                                                    )}
                                                </PlacesAutocomplete>

                                                {businessNameErrorMsg ?
                                                    <div
                                                        className="error">{businessNameErrorMsg}</div> : null}
                                            </Col>

                                            <Col md="2">
                                                <p>Public Business Name:</p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="text"
                                                              onChange={this.handleChanged}
                                                              name="publicBusinessName"
                                                              value={publicBusinessName}
                                                              placeholder="Public Business Name"/>
                                                {publicBusinessNameErrorMsg ?
                                                    <div
                                                        className="error">{publicBusinessNameErrorMsg}</div> : null}
                                            </Col>

                                        </Row>
                                        <Row>

                                            <Col md="2">
                                                <p>

                                                    Venues</p>

                                            </Col>


                                            <Col md="4">

                                                <Select
                                                    mode={"multiple"}
                                                    name="venues"
                                                    defaultValue={venues}

                                                    onDeselect={this.deSelectVenues}
                                                    onSelect={this.handleChangedVenues}
                                                    className="">

                                                    <Option value="Lounge" key="Food">Lounge</Option>
                                                    <Option value="Club" key="Drinks">Club</Option>
                                                    <Option value="Bar" key="Games">Bar</Option>
                                                    <Option value="Other" key="Music">Other</Option>


                                                </Select>
                                                {venuesErrorMsg ?
                                                    <div
                                                        className="error">{venuesErrorMsg}</div> : null}
                                            </Col>


                                            <Col md="2">
                                                <p>Entertainments
                                                </p>

                                            </Col>
                                            <Col md="4">

                                                <Select
                                                    mode={"multiple"}
                                                    defaultValue={entertainments}

                                                    // style={{ width: '100%' }}
                                                    // inputMode={"multiple"}
                                                    name="entertainments"
                                                    onDeselect={this.deSelectEntertaimment}
                                                    onSelect={this.handleChangedEntertaimment}
                                                    className="">


                                                    <Option key="Food">Food</Option>
                                                    <Option key="Drinks">Drinks</Option>
                                                    <Option key="Games">Games</Option>
                                                    <Option key="Music">Music</Option>
                                                    <Option key="Hookah">Hookah</Option>
                                                    <Option key="Indoor">Indoor</Option>
                                                    <Option key="Outdoor">Outdoor</Option>
                                                    <Option key="Booths/Sections">Booths/Sections</Option>


                                                </Select>
                                                {entertainmentsErrorMsg ?
                                                    <div
                                                        className="error">{entertainmentsErrorMsg}</div> : null}
                                            </Col>


                                        </Row>
                                        <Row>


                                            <Col md="2">
                                                <p>Address
                                                </p>

                                            </Col>

                                            <Col md="6">
                                                {/*<PlacesAutocomplete
                                                value={address}
                                                onChange={this.handleChangeaddress}
                                                onSelect={this.handleSelectAddress}
                                            >
                                                {({
                                                      getInputProps,
                                                      suggestions,
                                                      getSuggestionItemProps,
                                                      loading,
                                                  }) => (
                                                    <div>
                                                        <input
                                                            {...getInputProps({

                                                                placeholder: " Address",
                                                                className: "location-search-input form-control acc-form-input",
                                                            })}
                                                        />

                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? "suggestion-item--active"
                                                                : "suggestion-item";
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? {backgroundColor: "#fafafa", cursor: "pointer"}
                                                                : {backgroundColor: "#ffffff", cursor: "pointer"};
                                                            return (
                                                                <div
                                                                    className={address && suggestions.description !== "" ? "autocomplete-dropdown-container" : ""}>
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                )}
                                            </PlacesAutocomplete>*/}
                                              {/*  <Form.Control type="text"
                                                              onChange={this.handleChanged}
                                                              name="address"
                                                              value={address}
                                                              placeholder="Address"/>*/}
                                                <PlacesAutocomplete
                                                    value={address}
                                                    placeholder="Address"
                                                    onChange={this.handleChangeaddress1}
                                                    onSelect={this.handleSelectAddress1}
                                                >
                                                    {({
                                                          getInputProps,
                                                          suggestions,
                                                          getSuggestionItemProps,
                                                          loading,
                                                      }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({

                                                                    placeholder: " Address",
                                                                    className: "location-search-input form-control acc-form-input",
                                                                })}
                                                            />

                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map((suggestion) => {
                                                                const className = suggestion.active
                                                                    ? "suggestion-item--active"
                                                                    : "suggestion-item";
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? {backgroundColor: "#fafafa", cursor: "pointer"}
                                                                    : {backgroundColor: "#ffffff", cursor: "pointer"};
                                                                return (
                                                                    <div
                                                                        className={address && suggestions.description !== "" ? "autocomplete-dropdown-container" : ""}>
                                                                        <div
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                                style,
                                                                            })}
                                                                        >
                                                                            <span>{suggestion.description}</span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>

                                                    )}
                                                </PlacesAutocomplete>


                                                {addressErrorMsg ?
                                                    <div className="error">{addressErrorMsg}</div> : null}
                                            </Col>

                                            <Col md="4">
                                                <Form.Control type="string"
                                                              onChange={this.handleChanged}
                                                              name="vat"
                                                              value={vat}
                                                              placeholder="VAT"/>
                                                {vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}
                                            </Col>


                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <p>City
                                                </p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="string"
                                                              onChange={this.handleChanged}
                                                              name="city"
                                                              value={city}

                                                              placeholder="City"/>
                                                {vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}
                                            </Col>
                                            <Col md="2">
                                                <p>state
                                                </p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="string"
                                                              onChange={this.handleChanged}
                                                              name="state"
                                                              value={state}

                                                              placeholder="State"/>
                                                {vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <p>Country
                                                </p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="string"
                                                              onChange={this.handleChanged}
                                                              name="country"
                                                              value={country}

                                                              placeholder="Country"/>
                                                {vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}
                                            </Col>
                                            <Col md="2">
                                                <p>Zip code
                                                </p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="string"
                                                              onChange={this.handleChanged}
                                                              name="zipCode"
                                                              value={zipCode}

                                                              placeholder="zipCode"/>
                                                {vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <p>Description
                                                </p>

                                            </Col>
                                            <Col md="4">
                                                <Form.Control type="string"
                                                              onChange={this.handleChanged}
                                                              name="description"
                                                              value={description}

                                                              placeholder="Description"/>
                                                {/*{vatErrorMsg ? <div className="error">{vatErrorMsg}</div> : null}*/}
                                            </Col>
                                        </Row>
                                        {!_.isEmpty(openingHours) ? <Row lg="2" md="3">
                                            <p>Hours of operation:</p>
                                            {console.log(openingHours, "ggggggggggggg")}
                                        </Row> : ''}


                                        {!_.isEmpty(openingHours) ? openingHours.map((val, i = 1) => (
                                                <Row>

                                                    <Col md="2">
                                                        <label className="pt-3">
                                                            <input type="checkbox" id="selectEmailRadio"
                                                                // name={"selectEmailRadio"}
                                                                   checked={val.check ? true : false}
                                                                   onChange={(e) => this.handleChangeOpnHrsEdit(e, i+1)}
                                                                   name="ONE"/></label>
                                                    </Col>
                                                    <Col md="2">{days(val.day)}</Col>
                                                    <Col md="2">

                                                        <select
                                                            value={val.startTime}
                                                            onChange={(e) => this.handleChangeOpnHrsEdit(e, i)}
                                                            onSelect={(e) => this.handleChangeOpnHrsEdit(e, i)}
                                                            name="startTime"
                                                            className="form-control">
                                                            <option name={"0"}
                                                                    value="0">{"12:00 am"}</option>
                                                            <option name={"0100"} value="0100">{"1:00 am"}</option>
                                                            <option name={"0200"} value="0200">{"2:00 am"}</option>
                                                            <option name={"0300"} value="0300">{"3:00 am"}</option>
                                                            <option name={"0400"} value="0400">{"4:00 am"}</option>
                                                            <option name={"0500"} value="0500">{"5:00 am"}</option>
                                                            <option name={"0600"} value="0600">{"6:00 am"}</option>
                                                            <option name={"0700"} value="0700">{"7:00 am"}</option>
                                                            <option name={"0800"} value="0800">{"8:00 am"}</option>
                                                            <option name={"0900"} value="0900">{"9:00 am"}</option>
                                                            <option name={"1000"} value="1000">{"10:00 am"}</option>
                                                            <option name={"1100"} value="1100">{"11:00 am"}</option>
                                                            <option name={"1200"} value="1200">{"12:00 pm"}</option>
                                                            <option name={"1300"} value="1300">{"13:00 pm"}</option>
                                                            <option name={"1400"} value="1400">{"14:00 pm"}</option>
                                                            <option name={"1500"} value="1500">{"15:00 pm"}</option>
                                                            <option name={"1600"} value="1600">{"16:00 pm"}</option>
                                                            <option name={"1700"} value="1700">{"17:00 pm"}</option>
                                                            <option name={"1800"} value="1800">{"18:00 pm"}</option>
                                                            <option name={"1900"} value="1900">{"19:00 pm"}</option>
                                                            <option name={"2000"} value="2000">{"20:00 pm"}</option>
                                                            <option name={"2100"} value="2100">{"21:00 pm"}</option>
                                                            <option name={"2200"} value="2200">{"22:00 pm"}</option>
                                                            <option name={"2300"} value="2300">{"23:00 pm"}</option>

                                                        </select>
                                                    </Col>
                                                    <Col md="2">
                                                        <select
                                                            // inputMode={}
                                                            // mode={"multiple"}
                                                            // name="venues"
                                                            // onDeselect={this.deSelectVenues}
                                                            onChange={(e) => this.handleChangeOpnHrsEdit(e, i)}
                                                            onSelect={(e) => this.handleChangeOpnHrsEdit(e, i)}
                                                            value={val.endTime}
                                                            name="endTime"

                                                            className="form-control">
                                                            <option name={"0"} value="0">{"12:00 am"}</option>
                                                            <option name={"100"} value="100">{"1:00 am"}</option>
                                                            <option name={"200"} value="200">{"2:00 am"}</option>
                                                            <option name={"300"} value="300">{"3:00 am"}</option>
                                                            <option name={"400"} value="400">{"4:00 am"}</option>
                                                            <option name={"500"} value="500">{"5:00 am"}</option>
                                                            <option name={"600"} value="600">{"6:00 am"}</option>
                                                            <option name={"700"} value="700">{"7:00 am"}</option>
                                                            <option name={"800"} value="800">{"8:00 am"}</option>
                                                            <option name={"900"} value="900">{"9:00 am"}</option>
                                                            <option name={"1000"} value="1000">{"10:00 am"}</option>
                                                            <option name={"1100"} value="1100">{"11:00 am"}</option>
                                                            <option name={"1200"} value="1200">{"12:00 pm"}</option>
                                                            <option name={"1300"} value="1300">{"13:00 pm"}</option>
                                                            <option name={"1400"} value="1400">{"14:00 pm"}</option>
                                                            <option name={"1500"} value="1500">{"15:00 pm"}</option>
                                                            <option name={"1600"} value="1600">{"16:00 pm"}</option>
                                                            <option name={"1700"} value="1700">{"17:00 pm"}</option>
                                                            <option name={"1800"} value="1800">{"18:00 pm"}</option>
                                                            <option name={"1900"} value="1900">{"19:00 pm"}</option>
                                                            <option name={"2000"} value="2000">{"20:00 pm"}</option>
                                                            <option name={"2100"} value="2100">{"21:00 pm"}</option>
                                                            <option name={"2200"} value="2200">{"22:00 pm"}</option>
                                                            <option name={"2300"} value="2300">{"23:00 pm"}</option>
                                                        </select>
                                                    </Col>
                                                </Row>)) :
                                            opn && opn.map((val, i = 0) => (
                                                <Row>aaaaaaaaa

                                                    <Col md="2">
                                                        <label className="pt-3">
                                                            <input type="checkbox" id="selectEmailRadio"
                                                                // name={"selectEmailRadio"}
                                                                   checked={val.check ? true : false}
                                                                   onChange={(e) => this.handleChangeOpnHrsEdit(e, i+1)}
                                                                   name="ONE"/></label>
                                                    </Col>
                                                    <Col md="2">{days(val.day)}</Col>
                                                    <Col md="2">

                                                        <select
                                                            value={val.startTime}
                                                            onChange={(e) => this.handleChangeOpnHrsEdit(e, i)}
                                                            name="startTime"
                                                            className="form-control">
                                                            <option name={"0"} value="0">{"12:00 am"}</option>
                                                            <option name={"0100"} value="0100">{"1:00 am"}</option>
                                                            <option name={"0200"} value="0200">{"2:00 am"}</option>
                                                            <option name={"0300"} value="0300">{"3:00 am"}</option>
                                                            <option name={"0400"} value="0400">{"4:00 am"}</option>
                                                            <option name={"0500"} value="0500">{"5:00 am"}</option>
                                                            <option name={"0600"} value="0600">{"6:00 am"}</option>
                                                            <option name={"0700"} value="0700">{"7:00 am"}</option>
                                                            <option name={"0800"} value="0800">{"8:00 am"}</option>
                                                            <option name={"0900"} value="0900">{"9:00 am"}</option>
                                                            <option name={"1000"} value="1000">{"10:00 am"}</option>
                                                            <option name={"1100"} value="1100">{"11:00 am"}</option>
                                                            <option name={"1200"} value="1200">{"12:00 pm"}</option>
                                                            <option name={"1300"} value="1300">{"13:00 pm"}</option>
                                                            <option name={"1400"} value="1400">{"14:00 pm"}</option>
                                                            <option name={"1500"} value="1500">{"15:00 pm"}</option>
                                                            <option name={"1600"} value="1600">{"16:00 pm"}</option>
                                                            <option name={"1700"} value="1700">{"17:00 pm"}</option>
                                                            <option name={"1800"} value="1800">{"18:00 pm"}</option>
                                                            <option name={"1900"} value="1900">{"19:00 pm"}</option>
                                                            <option name={"2000"} value="2000">{"20:00 pm"}</option>
                                                            <option name={"2100"} value="2100">{"21:00 pm"}</option>
                                                            <option name={"2200"} value="2200">{"22:00 pm"}</option>
                                                            <option name={"2300"} value="2300">{"23:00 pm"}</option>

                                                        </select>
                                                    </Col>
                                                    <Col md="2">
                                                        <select
                                                            // inputMode={}
                                                            // mode={"multiple"}
                                                            // name="venues"
                                                            // onDeselect={this.deSelectVenues}
                                                            onChange={(e) => this.handleChangeOpnHrs(e, i)}
                                                            // value={val.endTime}
                                                            name="endTime"

                                                            className="form-control">
                                                            <option name={"0"} value="0">{"12:00 am"}</option>
                                                            <option name={"100"} value="100">{"1:00 am"}</option>
                                                            <option name={"200"} value="200">{"2:00 am"}</option>
                                                            <option name={"300"} value="300">{"3:00 am"}</option>
                                                            <option name={"400"} value="400">{"4:00 am"}</option>
                                                            <option name={"500"} value="500">{"5:00 am"}</option>
                                                            <option name={"600"} value="600">{"6:00 am"}</option>
                                                            <option name={"700"} value="700">{"7:00 am"}</option>
                                                            <option name={"800"} value="800">{"8:00 am"}</option>
                                                            <option name={"900"} value="900">{"9:00 am"}</option>
                                                            <option name={"1000"} value="1000">{"10:00 am"}</option>
                                                            <option name={"1100"} value="1100">{"11:00 am"}</option>
                                                            <option name={"1200"} value="1200">{"12:00 pm"}</option>
                                                            <option name={"1300"} value="1300">{"13:00 pm"}</option>
                                                            <option name={"1400"} value="1400">{"14:00 pm"}</option>
                                                            <option name={"1500"} value="1500">{"15:00 pm"}</option>
                                                            <option name={"1600"} value="1600">{"16:00 pm"}</option>
                                                            <option name={"1700"} value="1700">{"17:00 pm"}</option>
                                                            <option name={"1800"} value="1800">{"18:00 pm"}</option>
                                                            <option name={"1900"} value="1900">{"19:00 pm"}</option>
                                                            <option name={"2000"} value="2000">{"20:00 pm"}</option>
                                                            <option name={"2100"} value="2100">{"21:00 pm"}</option>
                                                            <option name={"2200"} value="2200">{"22:00 pm"}</option>
                                                            <option name={"2300"} value="2300">{"23:00 pm"}</option>
                                                        </select>
                                                    </Col>
                                                </Row>))


                                        }

                                        {errors ? <div className="error">{errors}</div> : null}

                                        <Row>
                                            <Col md="10" className="text-sm-right">
                                                <Button type="submit"
                                                        variant="warning"
                                                        className="mr-2">Save</Button>
                                                &nbsp;

                                                <Button onClick={this.handleClose}
                                                        variant="dark">Cancel</Button>


                                            </Col>
                                        </Row>


                                    </form>

                                </div>
                            </Modal.Body>
                            <Modal.Footer>

                                <div className="footer_btn_wrapper">

                                </div>
                                <div className="print_button orderabs">


                                </div>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={deletePopup} onHide={this.handleClose} className="cm_order_view dlt_item">
                            <Modal.Header closeButton>
                                <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <img src={require('../../assets/img/Delete-Email.png')}/>
                                <br/>
                                <br/>
                                <p>Are you sure you want to delete?</p>
                            </Modal.Body>
                            <Modal.Footer>

                                <div className="footer_btn_wrapper">
                                    <Button variant="warning" onClick={(e) => this.handleDelete()}>
                                        Delete
                                    </Button>
                                    {/*<span><i onClick={(e)=>this.handleDelete(e,item)} class="fas fa-trash-alt"></i></span>*/}

                                    <Button variant="dark" onClick={this.handleClose}>
                                        Cancel
                                    </Button>
                                </div>

                            </Modal.Footer>
                        </Modal>

                    </div>

                </>
            )
        }

    }


    function mapStateToProps(state) {
        const {props, login, user, mapReducer} = state;
        return {
            props,
            login,
            user,
            mapReducer
        }
    }

    export
    default

    connect(mapStateToProps)

(
    Venues
)
    ;

// export default Settings
