import {venuesConstants} from '../_actions/venues.actions';

const initialState = {};


export function venue(state = initialState, action) {
    switch (action.type) {
        case venuesConstants.GET_VENUES_SUCCESS:
            let getVenuesSuccessInfo = action.getVenuesSuccess;
            return getVenuesSuccessInfo

        case venuesConstants.GET_VENUE_DETAILS_SUCCESS:
            let getVenueDetailsSuccessInfo = action.getVenueDetailsSuccess;
            return getVenueDetailsSuccessInfo

        case venuesConstants.GET_PLACE_DETAIL_SUCCESS:
            let getPlaceByIdSuccessInfo = action.getPlaceByIdSuccess;
            return getPlaceByIdSuccessInfo
        case venuesConstants.GET_NEARBY_SEARCH_SUCCESS:
            let getNearBySearchSuccessInfo = action.getNearBySearchSuccess;
            return getNearBySearchSuccessInfo

        case venuesConstants.ADD_VENUE_SUCCESS:
            let addVenueSuccessInfo = action.addVenueSuccess;
            return addVenueSuccessInfo



        default:
            return {...state}
    }
}
