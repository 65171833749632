import axios from 'axios';
import API from '../config/API';

export const reportsService = {
    getReasons,
    getReports,
    getSurveys,
    getAddress,
}

const headersApplicationJson = {
    'Content-Type': 'application/json',
}
const headersMultipart = {
    "Content-type": "multipart/form-data",
}

function getReasons(params) {
    return axios.get(`${API.GET_REASONS}/${params}`);
}

function getAddress(params) {
    return axios.get(`${API.GET_ADDRESS}/${params}`);
}

function getReports(params) {
    return axios.get(`${API.GET_REPORTS}/${params}`);
}
function getSurveys(params) {
    return axios.get(`${API.GET_SURVEYS}/${params}`);
}



