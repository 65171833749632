import axios from 'axios';
import API from '../config/API';

export const usersService = {
    login,
    getUser,
    getAllTicketPurchases,
    subscriptionPurchased,
    getUserDetail,
    getAdminDetail,
    editProfile,
    changeUserStatus,
    forgot,
    changePassword,
    reset,
    getExam,
    deleteExam,
    getSetting,
    getBadges,
    addBadges,
    editBadges,
    deleteBadges,
    addSetting,
    editSetting,
}

const headersApplicationJson = {
    'Content-Type': 'application/json',
}
const headersMultipart = {
    "Content-type": "multipart/form-data",
}

function login(params) {
    return axios.post(API.LOGIN, params)
}

function forgot(params) {
    return axios.post(API.FORGOT, params)
}
function reset(params) {
    return axios.post(API.RESET, params)
}

function getExam(params) {
    console.log(params,"params")
    return axios.get(`${API.GET_EXAM}${params?params:''}`);
}

function getUser(params) {
    return axios.get(`${API.GET_USER}/${params}`);
}

function getUserDetail(params) {
    return axios.get(`${API.GET_USER_DETAIL}/${params}`);
}

function getAllTicketPurchases(params) {
    console.log(params,"aaaaaaaaaaaaaa")
    return axios.get(`${API.GET_TICKET_PURCHASES}${params}`);
}
function subscriptionPurchased(params) {
    console.log(params,"aaaaaaaaaaaaaa")
    return axios.get(`${API.GET_SUSCRIPTION_PURCHASES}${params}`);
}

function getAdminDetail(params) {
    return axios.get(`${API.GET_ADMIN_DETAIL}`);
}

function changePassword(params) {
    return axios.post(API.CHANGE_PASSWORD, params);
}

function editProfile(params) {
    return axios.post(API.EDIT_PROFILE, params);
}

function deleteExam(params) {
    return axios.delete(API.DELETE_EXAM, {data:params})
}

function changeUserStatus(params) {
    return axios.post(API.DELETE_USER, params)
}

function getSetting(params) {
    return axios.get(`${API.GET_SETTING}`);
}


function getBadges(params) {
    return axios.get(`${API.GET_BADGES}`);
}

function addSetting(params) {
    return axios.post(API.ADD_SETTING,params);
}

function addBadges(params) {
    return axios.post(API.ADD_BADGES,params);
}

function editBadges(params) {
    return axios.post(API.EDIT_BADGES,params);
}

function deleteBadges(params) {
    return axios.delete(API.DELETE_BADGES+params);
}

function editSetting(params) {
    return axios.post(API.EDIT_SETTING,params);
}