export const usersConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',


    GET_USER_DISHES_REQUEST: 'GET_USER_DISHES_REQUEST',
    GET_USER_DISHES_SUCCESS: 'GET_USER_DISHES_SUCCESS',
    GET_USER_DISHES_ERROR: 'GET_USER_DISHES_ERROR',

    GET_ADMIN_DETAIL_REQUEST: 'GET_ADMIN_DETAIL_REQUEST',
    GET_ADMIN_DETAIL_SUCCESS: 'GET_ADMIN_DETAIL_SUCCESS',
    GET_ADMIN_DETAIL_ERROR: 'GET_ADMIN_DETAIL_ERROR',

    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_ERROR: 'GET_USER_ERROR',

    EDIT_PROFILE_REQUEST: 'EDIT_PROFILE_REQUEST',
    EDIT_PROFILE_SUCCESS: 'EDIT_PROFILE_SUCCESS',
    EDIT_PROFILE_ERROR: 'EDIT_PROFILE_ERROR',

    CHANGE_PASS_REQUEST: 'CHANGE_PASS_REQUEST',
    CHANGE_PASS_SUCCESS: 'CHANGE_PASS_SUCCESS',
    CHANGE_PASS_ERROR: 'CHANGE_PASS_ERROR',

    RESET_REQUEST: 'RESET_REQUEST',
    RESET_SUCCESS: 'RESET_SUCCESS',
    RESET_ERROR: 'RESET_ERROR',

    FORGOT_REQUEST: 'FORGOT_REQUEST',
    FORGOT_SUCCESS: 'FORGOT_SUCCESS',
    FORGOT_ERROR: 'FORGOT_ERROR',

    GET_ADMIN_USER_DETAIL_REQUEST: 'GET_ADMIN_USER_DETAIL_REQUEST',
    GET_ADMIN_USER_DETAIL_SUCCESS: 'GET_ADMIN_USER_DETAIL_SUCCESS',
    GET_ADMIN_USER_DETAIL_ERROR: 'GET_ADMIN_USER_DETAIL_ERROR',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_ERROR: 'DELETE_USER_ERROR',

    ADD_EXAM_REQUEST: 'ADD_EXAM_REQUEST',
    ADD_EXAM_SUCCESS: 'ADD_EXAM_SUCCESS',
    ADD_EXAM_ERROR: 'ADD_EXAM_ERROR',

    ADD_BADGE_REQUEST: 'ADD_BADGE_REQUEST',
    ADD_BADGE_SUCCESS: 'ADD_BADGE_SUCCESS',
    ADD_BADGE_ERROR: 'ADD_BADGE_ERROR',

    EDIT_BADGE_REQUEST: 'EDIT_BADGE_REQUEST',
    EDIT_BADGE_SUCCESS: 'EDIT_BADGE_SUCCESS',
    EDIT_BADGE_ERROR: 'EDIT_BADGE_ERROR',

    DELETE_BADGE_REQUEST: 'DELETE_BADGE_REQUEST',
    DELETE_BADGE_SUCCESS: 'DELETE_BADGE_SUCCESS',
    DELETE_BADGE_ERROR: 'DELETE_BADGE_ERROR',


    EDIT_EXAM_REQUEST: 'EDIT_EXAM_REQUEST',
    EDIT_EXAM_SUCCESS: 'EDIT_EXAM_SUCCESS',
    EDIT_EXAM_ERROR: 'EDIT_EXAM_ERROR',

    GET_EXAM_REQUEST: 'GET_EXAM_REQUEST',
    GET_EXAM_SUCCESS: 'GET_EXAM_SUCCESS',
    GET_EXAM_ERROR: 'GET_EXAM_ERROR',

    EDIT_SETTING_REQUEST: 'EDIT_SETTING_REQUEST',
    EDIT_SETTING_SUCCESS: 'EDIT_SETTING_SUCCESS',
    EDIT_SETTING_ERROR: 'EDIT_SETTING_ERROR',

    ADD_SETTING_REQUEST: 'ADD_SETTING_REQUEST',
    ADD_SETTING_SUCCESS: 'ADD_SETTING_SUCCESS',
    ADD_SETTING_ERROR: 'ADD_SETTING_ERROR',

    DELETE_EXAM_REQUEST: 'DELETE_EXAM_REQUEST',
    DELETE_EXAM_SUCCESS: 'DELETE_EXAM_SUCCESS',
    DELETE_EXAM_ERROR: 'DELETE_EXAM_ERROR',

};
