import React, {Component} from 'react'
import {Button, Col, Form, Modal, Row, Tab, Tabs} from 'react-bootstrap'

import {connect} from "react-redux";
import _ from 'lodash';
import Sidebar from '../common/sidebar'
import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import moment from "moment-timezone"
import {reportsActions} from "../../_actions";
import {UserIcon} from "../Svg";


class ReportList extends Component {

    constructor(props) {
        super(props);
        console.log("pring ")

        this.state = {
            orderInfo: [],
            ItemList: [],
            reId: [],
            excelFile: '',
            examDate: '',
            ItemId: [],
            itemId: [],
            type:1,
            sortField: '',
            sortType: 1,

            reasonErMsg: '',
            show: false,
            farmName: '',
            addExamPopup: false,
            messagePopup: 0, alertPopup: 0, emailPopup: 0,
            lat: 0, long: '',
            count: 30,
            totalCount: 0,
            userListCount: 0,
            dataCount: 0,
            filterHarvest: '',
            pageNo: 1,
            orderInfo1: [],
            userStatus: 0,
            profilePicPreview: '',

            examName: '',
            userId: '',
            deletePopup: false,
            examNameErrorMsg: '',
            examDateErrorMsg: '',
            reportList: [],
            // userId: localStorage.getItem('userId'),
            profilePicErrorMsg: '',
            loader: true
        }

    }

    componentDidMount() {
        this.getReports()

    }


    getReports = () => {
        this.setState({errors: ''});

        let self = this


        let {dispatch} = this.props;
        // dispatch(orderActions.getAllOrderExcel(params)).then(data => {
        let {pageNo, count, sortField, sortType, search,type} = this.state

        // dispatch(orderActions.getAllOrderExcel(params)).then(data => {
        let params = `?page=${pageNo - 1}&limit=${count}&sortField=${sortField ? sortField : ''}&sortType=${sortType ? sortType : ''}&type=${type ? type : ''}&search=${search ? search : ''}`


        dispatch(reportsActions.getReports(params)).then(data => {
            console.log(data, "Aaaaaaaaaaaa")

            this.setState({loader: false})
            let reportList = data.responseData.reportList;

            console.log(this.state.inventoryType,"reportListreportListdata.type==this.state.inventoryTyp")
            reportList.filter(data=>{console.log(data.type===this.state.inventoryType,data.type,this.state.inventoryType,"reportListreportListdataccc")})

            console.log(reportList,"reportListreportList")
            let reportListCounts = data.responseData.reportListCount;

            if (data.statusCode == 1) {
                // let userInfo = data.responseData.userProfile
                // localStorage.setItem('userId', userInfo._id);


                this.setState({
                    reportList: reportList,
                    reportListCounts: reportListCounts,


                })
            } else {
                this.setState({errors: data.error.responseMessage})
            }
        });

    }
    handlePageChange = (pageNo) => {

        var self = this;
        this.setState({loader: false, pageNo: pageNo, count: 30}, () => {
            self.getReports();
        });
    }
    handleChanged = (e) => {
        console.log("onchange namesxxxxxxxxxxxxxxxxxxxxxxxdd", e)
        this.setState(
            {
                [e.target.name]: e.target.value,
                // emailError: ''
            }
        );
    }

    handleFilter = ele => {

        this.setState({
            loader: false, reasonErMsg: '', filterHarvest: ele, count: 10, pageNo: 1
        }, () => this.getOrderList())
    }

    handleClose = () => {
        this.setState({addExamPopup: false, deletePopup: false, showInvoice: false,})
    }

    ConvertDate = (created) => {
        let data = moment(created).format("MM/DD/YYYY")
        return data
    }
    ConvertDob = (dob) => {
        // let data =        Date(dob)
        let data = moment(dob).format("MM/DD/YYYY")
        return data
    }

    handleShowAddExam = (item) => {
        this.setState({addExamPopup: true, ItemList: item})
    }

    handleValidate = () => {
        let validate = true;
        console.log(this.state.examDate, "this.state.examDate")
        if (this.state.examDate === "" || this.state.examDate === undefined) {
            validate = false;
            this.setState({
                examDateErrorMsg: 'Please enter exam date.'
            })
        } else {
            this.setState({
                examDateErrorMsg: ''
            })
        }
        if (this.state.examName === "" || this.state.examName === undefined) {
            validate = false;
            this.setState({
                examNameErrorMsg: 'Please enter exam name.'
            })
        } else {
            this.setState({
                examNameErrorMsg: ''
            })
        }

        return validate


    }


    handleMaltiSelct = (e, val) => {
        let {orderInfo, multiValue} = this.state;
        let self = this;
        this.setState({reasonErMsg: ''})
        multiValue = typeof this.state.multiValue === 'object' ? this.state.multiValue : []
        let reIdval = []

        console.log(this.state.multiValue, "jiluhyguftydrtesdrftgyhkj::::0000    ")
        if (this.state.reId.includes(val)) {

            for (var i = 0; i < this.state.reId.length; i++) {
                if (this.state.reId[i] === val) {
                    this.state.reId.splice(i, 1);
                }
            }
        } else {
            this.state.reId.push(val)
        }


        this.state.orderInfo.map(function (value, index) {

            if (value._id == val) {

                orderInfo[index].status = orderInfo[index].status === 2 ? 3 : 2;
                console.log(orderInfo[index].status, "orderInfo[index].statusorderInfo[index].status1")

                if (orderInfo[index].status == 2) {
                    multiValue.push(value._id)
                } else if (orderInfo[index].status == 3) {
                    multiValue.splice(value._id)
                }
                console.log(multiValue, "orderInfo[index].statusorderInfo[index].status2")


            }
        })

        console.log("jiluhyguftydrtesdrftgyhkj", multiValue)

        this.setState({multiValue}, () => {
            console.log(multiValue, "multiValuemultiValuemultiValue")
        })

    }
    handleShow = (item) => {
        console.log(item, "ddddddddddddddd")
        this.setState({deletePopup: true, userId: item._id, userStatus: 3})
    }
    sendToParent = (e) => {
        console.log(e, "xxxxxxxxxxxxxxxxx3")
        this.setState({type: this.state.type,loader: true})
        this.getReports()
    }

    changeName = (event) => {
        const self = this;
        // console.log(event.target.value, "xxxxxxxxxxxxxxxxx")
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            type: this.state.type,
            search: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.sendToParent(self.state.name);
            }, 1000)
        })


    }

    inventoryType = (e) => {

        console.log(e, "inventoryTypeinventoryTypeinventoryTypeinventoryType", e == '1')

        if (e == '1') {
            this.setState({ type: 1 ,search:''}, () => this.getReports());
        } else if (e == '2')
        {
            this.setState({ type: 2,search:'' }, () => this.getReports());
        }
    }
    handleSort = sortField => this.setState({
        loader: true,
        sortField,
        sortType: this.state.sortType === 1 ? -1 : 1
    }, () => this.getReports()  )


    render() {
        let {  inventoryType,deletePopup, reportList,reportListCounts, errors, examDateErrorMsg, examDate, examName, examNameErrorMsg, profilePicErrorMsg, excelFile, filterHarvest, farmName, reasonErMsg, orderInfo, count, pageNo, totalCount, dataCount, ItemList, profilePicPreview} = this.state;


        return (
            <>
                <Sidebar/>
                <div className="body_container">
                    <div className="header_search header_search_custom ">



                        <div className="row mb-3">
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="count-list">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h6 className="mb-2">Total Reports</h6>
                                                    <h6 className="mb-0">{reportListCounts}</h6>
                                                </div>&nbsp;
                                                <div>
                                                        <span className="user-icon icon-grey">
                                                           {/*<UserIcon/>*/}<i className="fa fa-exclamation-circle sizeCircle"
                                                                               aria-hidden="true"></i>

                                                        </span>
                                                </div>&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </div>
                                    <div className="col-md-4">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </div>
                                    <div className="col-md-4">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 offset-3">
                                <div className="form-group has-search">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    {/*<Form.Control type="Search" placeholder="Search..." name="search" value={"search"}></Form.Control>*/}
                                    <Form.Control type="text" placeholder="Search" name={"search"} onChange={this.changeName}/>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className="body_inner_wrapper">
                            {this.state.loader ? <div className="loader_wrapper">
                                    <div class="spinner-border text-info" role="status"></div>
                                </div> :
                                (<>
                                    <header className="header_content">
                                        <p>Reports</p>
                                    </header>

                                    <div className="main_content">
                                        <div className="cm-inventory">
                                            <Tabs defaultActiveKey='1' onSelect={(e) => this.inventoryType(e)}
                                                  activeKey={this.state.type}
                                                  id="uncontrolled-tab-example">
                                                <Tab eventKey='1' title="Reported Events">

                                                    <div className="cm-inventory_gridview">

                                                    <div class="table-responsive">
                                            <table class="table cm_bottom_space">
                                                <thead>
                                                <tr class="broder_top_0">
                                                    <th scope="col" className="ws-nowrap">S. No.</th>
                                                    <th scope="col" onClick={() => this.handleSort('created')}>Reported
                                                        On<i
                                                            className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                    </th>
                                                    <th scope="col" onClick={() => this.handleSort('eventName')}>Reported Event <i
                                                        className=""></i></th>
                                                    <th scope="col" onClick={() => this.handleSort('reportedUser')}>Reported By<i
                                                        className=""></i></th>
                                                    {/*<th scope="col" >Reason<i*/}
                                                    {/*    className="fas fa-sort text_dark ml-2 d-inline-block"></i></th>*/}
                                                    {/*<th scope="col" className="ws-nowrap">Profic pic</th>   */}
                                                    {/*<th scope="col" className="ws-nowrap">Status</th>*/}
                                                    {/*<th scope="col" className="ws-nowrap">Claimed Status</th>*/}
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {!_.isEmpty(reportList) && reportList.filter(item => item.userStatus !== 3 && item.type===1).map((item, i) => (
                                                    <tr key={item._id}>

                                                        {console.log(item,"Fggggggggggggggggggg")}

                                                        <td>{((count * (pageNo - 1)) + (i + 1))}</td>
                                                        <td>{this.ConvertDate(item.created)}</td>
                                                        <td>{item && item.reportedEventData && item.reportedEventData?item.reportedEventData.eventName:''}</td>
                                                        <td>{item.reportedByUser && item.reportedByUser._id ?
                                                            <Link
                                                                to={`user-info/${item.reportedByUser._id}`}>{item.reportedByUser.firstName} {item.reportedByUser.lastName}</Link> : ''}</td>
                                                        <td>{item?item.reason:''}</td>

                                                        {/*<td>{item.userInfo.profileCover?<img height="40" width="40" src={item.userInfo.profileCover}/>:''}</td>*/}
                                                        {/*<td>{item.reportedByUser && item.reportedByUser.userStatus && item.reportedByUser.userStatus==1?"Active":*/}
                                                        {/*    "Inactive"   }</td>*/}
                                                        {/*<td>{item.reportedByUser && item.reportedByUser.claimedStatus&& item.reportedByUser.claimedStatus==1?"claimed":"unclaimed" }</td>*/}
                                                    </tr>))
                                                }


                                                </tbody>


                                            </table>
                                            {reportList && reportList.length ?
                                                <div
                                                    className="d-flex justify-content-end cm-pagination">
                                                    {/*{this.state.userListCount}*/}
                                                    <Pagination
                                                        itemClassPrev={'prevClass'}
                                                        itemClassNext={'nextClass'}
                                                        activePage={this.state.pageNo}
                                                        itemsCountPerPage={this.state.count}
                                                        totalItemsCount={this.state.reportListCounts}
                                                        pageRangeDisplayed={3}
                                                        onChange={this.handlePageChange}
                                                    />
                                                </div> : ''}
                                            {_.isEmpty(this.state.reportList) ?
                                                <h3 className="text-center">No data found</h3> : ''}
                                            {reasonErMsg ?
                                                <div className="text-center error ">{reasonErMsg}</div> : ''}
                                        </div>
                                                    </div>
                                                </Tab>


                                                <Tab eventKey="2" title="Reported Venues">
                                                    <div className="cm-inventory_gridview">
                                                        <div className="table-responsive">
                                                            <table className="table cm_bottom_space">
                                                                <thead>
                                                                <tr className="broder_top_0">
                                                                    <th scope="col" className="ws-nowrap">S. No.</th>
                                                                    <th scope="col"
                                                                        onClick={() => this.handleSort('created')}>Reported
                                                                        On<i
                                                                            className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                                    </th>
                                                                    {/*<th scope="col"*/}
                                                                    {/*    onClick={() => this.handleSort('firstName')}>Reported*/}
                                                                    {/*    To <i*/}
                                                                    {/*        className="fas fa-sort text_dark ml-2 d-inline-block"></i>*/}
                                                                    {/*</th>*/}
                                                                    <th scope="col"
                                                                        onClick={() => this.handleSort('firstName')}>Reported
                                                                        By<i
                                                                            className=""></i>
                                                                    </th>

                                                                    <th scope="col" className="ws-nowrap">Reported Venue</th>
                                                                    {/*<th scope="col" className="ws-nowrap">Status</th>*/}
                                                                    {/*<th scope="col" className="ws-nowrap">Claimed*/}
                                                                    {/*    Status*/}
                                                                    {/*</th>*/}
                                                                </tr>
                                                                </thead>
                                                                <tbody>

                                                                {!_.isEmpty(reportList) && reportList.filter(item => item.userStatus !== 3&& item.type===2).map((item, i) => (
                                                                    <tr key={item._id}>
                                                                        <td>{((count * (pageNo - 1)) + (i + 1))}</td>
                                                                        <td>{this.ConvertDate(item.created)}</td>
                                                                        {/*<td>{item.reportedToUser && item.reportedToUser._id ?*/}
                                                                        {/*    <Link*/}
                                                                        {/*        to={`user-info/${item.reportedToUser._id}`}>{item.reportedToUser.firstName} {item.reportedToUser.lastName}</Link> : ''}</td>*/}
                                                                        <td>{item.reportedByUser && item.reportedByUser._id ?
                                                                            <Link
                                                                                to={`user-info/${item.reportedByUser._id}`}>{item.reportedByUser.firstName} {item.reportedByUser.lastName}</Link> : ''}</td>
                                                                        {console.log(item,"Fggggggggggggggggggg")}
                                                                        <td>{item.reportedVenueData && item.reportedVenueData._id ?
                                                                            <Link
                                                                                to={`business-info/${item.reportedVenueData.userId}`}>{item.reportedVenueData.businessName}</Link> : ''}</td>
                                                                        {/*<td>{item ? item.reason : ''}</td>*/}

                                                                        {/*<td>{item.userInfo.profileCover?<img height="40" width="40" src={item.userInfo.profileCover}/>:''}</td>*/}
                                                                        {/*<td>{item.reportedByUser && item.reportedByUser.userStatus && item.reportedByUser.userStatus == 1 ? "Active" :*/}
                                                                        {/*    "Inactive"}</td>*/}
                                                                        {/*<td>{item.reportedByUser && item.reportedByUser.claimedStatus && item.reportedByUser.claimedStatus == 1 ? "claimed" : "unclaimed"}</td>*/}
                                                                    </tr>))
                                                                }


                                                                </tbody>


                                                            </table>
                                                            {reportList && reportList.length ?
                                                                <div
                                                                    className="d-flex justify-content-end cm-pagination">
                                                                    {/*{this.state.userListCount}*/}
                                                                    <Pagination
                                                                        itemClassPrev={'prevClass'}
                                                                        itemClassNext={'nextClass'}
                                                                        activePage={this.state.pageNo}
                                                                        itemsCountPerPage={this.state.count}
                                                                        totalItemsCount={this.state.reportListCounts}
                                                                        pageRangeDisplayed={3}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div> : ''}
                                                            {_.isEmpty(this.state.reportList) ?
                                                                <h3 className="text-center">No data found</h3> : ''}
                                                            {reasonErMsg ?
                                                                <div
                                                                    className="text-center error ">{reasonErMsg}</div> : ''}
                                                        </div>
                                                    </div>
                                                </Tab>

                                            </Tabs>

                                    </div>
                                    </div>


                                </>)}


                        </div>



                    </div>
                    <Modal aria-labelledby="example-modal-sizes-title-sm "
                           show={this.state.addExamPopup}
                           onHide={this.handleClose}
                           className="cm_order_view order-detailsmodal">
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm"><h6>Add Exam</h6></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <form onSubmit={this.handlAddExam}>

                                    <Row>

                                        <Col md="3">
                                            <p>Exam Name:</p>

                                        </Col>
                                        <Col md="9">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="examName"
                                                          value={examName}
                                                          placeholder="Exam Name"/>
                                            {examNameErrorMsg ?
                                                <div
                                                    className="error">{examNameErrorMsg}</div> : null}
                                        </Col>


                                    </Row>

                                    <Row>

                                        <Col md="3">
                                            <p>Exam Date:</p>

                                        </Col>
                                        <Col md="9">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="examDate"
                                                          value={examDate}
                                                          placeholder="Exam Date "/>
                                            {examDateErrorMsg ?
                                                <div
                                                    className="error">{examDateErrorMsg}</div> : null}
                                        </Col>


                                    </Row>


                                    {errors ? errors : ''}
                                    <Row>
                                        <Col md="10" className="text-sm-right">
                                            <Button type="submit"
                                                    variant="warning"
                                                    className="mr-2">Save</Button>
                                            &nbsp;

                                            <Button onClick={this.viewMode}
                                                    variant="dark">Cancel</Button>


                                        </Col>
                                    </Row>


                                </form>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="footer_btn_wrapper">
                                {/*<Button variant="warning" className="text-light"*/}
                                {/*        onClick={() => this.handleItemAction(ItemList._id, 2)}*/}
                                {/*>*/}
                                {/*    ExamList Fulfilled*/}
                                {/*</Button>*/}

                            </div>
                            <div className="print_button orderabs">
                                {/*<Button onClick={()=>this.redirect(ItemList)} variant="light"><i*/}
                                {/*    className="fas fa-print"></i> Print </Button>*/}


                                <Link className="btn btn-light "><i
                                    className="fas fa-print" title="Invoicddddde"
                                ></i>Print</Link>

                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={deletePopup} onHide={this.handleClose} className="cm_order_view dlt_item">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src={require('../../assets/img/Delete-Email.png')}/>
                            <br/>
                            <br/>
                            <p>Are you sure you want to delete this user?</p>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="footer_btn_wrapper">
                                <Button variant="warning" onClick={(e) => this.handleDelete()}>
                                    Delete
                                </Button>
                                {/*<span><i onClick={(e)=>this.handleDelete(e,item)} class="fas fa-trash-alt"></i></span>*/}

                                <Button variant="dark" onClick={this.handleClose}>
                                    Cancel
                                </Button>
                            </div>

                        </Modal.Footer>
                    </Modal>

                </div>
            </>
        )
    }
}

// export default


function mapStateToProps(state) {
    const {props, login, user, mapReducer} = state;
    return {
        props,
        login,
        user,
        mapReducer
    }
}

export default connect(mapStateToProps)(ReportList);
// export default Settings
